import m from 'mithril'
import Guac from '../models/guac'
import guacView from './guac'
import search from './search'
import user from './user'

export default {

  controller: function() {
    return {
      guacs: Guac.get()
    }
  },

  view: function(ctrl) {
    const test = window.location.href.includes('beat.dk')
      , includeXXX = window.localStorage.getItem('includeXXX')

    window.includeXXX = includeXXX

    return m('header#header', { style: { 'background-color': (test) ? null : 'yellow' } }, [
      m(search),
      m('span', { style: { margin: '30px 0 0 550px', position: 'absolute' } }, [
        m('input', { type: 'checkbox', checked: includeXXX ? false : 'checked', onchange: () => {
          if (includeXXX)
            window.localStorage.removeItem('includeXXX')
          else
            window.localStorage.setItem('includeXXX', 'true')
        } }),
        m('span', 'Exclude XXX marked devices')
      ]),
      m('#menu', [
        ctrl.guacs().slice().reverse().map(g => {
          ctrl.num += 1
          g.key = g._id
          return m(guacView, g)
        }),
        m(user, { key: 'user' }),
        test ? null : m('div', { style: { color: 'black' } }, 'Testing environment')
      ])
    ])
  }
}
