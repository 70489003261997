import m from 'mithril'

const ms = {
  week : 7 * 24 * 60 * 60 * 1000,
  day : 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
}

const options = {
  months: 'january february march april may june july august september october november december'.split(' '),
  days: 'sun mon tue wed thu fri sat'.split(' '),
  firstDayOfWeek: 1,
  today: new Date(),
  range: false,
  start: m.prop(null),
  end: m.prop(null),
  min: null,
  max: null,
  limit: 0,
  weeks: false,
  time: true
}

const origin = 300000
let scrollTop = origin
let scrolling = false
let scrolled = false
let buffer = 10
const size = { width: 300, height: 300 }
const box = { width: 30, height: 30 }

const picker = {

  controller(args) {
    let scrollTimer

    for (let key in args)
      options[key] = args[key]

    return {
      scrollStart(el, inited) {
        if(!inited)
          el.scrollTop = origin
      },

      config(el, inited) {
        if(!inited) {
          size.width = el.clientWidth
          size.height = el.clientWidth
          box.width = Math.floor(size.width / (options.weeks ? 8 : 7))
          box.height = box.width
          buffer = Math.ceil(size.height / box.height)
        }
      },

      scroll(e) {
        if(Math.abs(e.target.scrollTop - scrollTop) > box.height * 4)
          scrollTop = e.target.scrollTop
        else if(scrolling)
          m.redraw.strategy('none')

        if(scrolled)
          scrolling = true

        scrolled = true

        clearTimeout(scrollTimer)
        scrollTimer = setTimeout(() => {
          scrolling = false
          scrolled = false
          m.redraw()
        }, 100)
      },

      select(date) {
        if(!options.start() || !sameDate(options.start(), options.end())) {
          options.start(date)
          options.end(date)
        } else if (sameDate(options.start(), options.end()) && sameDate(options.start(), date)) {
          options.start(null)
          options.end(null)
        } else if (date < options.start()) {
          options.end(options.start())
          options.start(date)
        } else if (date > options.start()) {
          options.end(date)
        }
      },

      selectWeek(monday, e) {
        e.stopPropagation()
        if(monday < options.start() || !e.shiftKey)
          options.start(monday)

        if(!e.shiftKey || monday > options.start())
          options.end(new Date(monday.getTime() + ms.week - ms.day))
      }
    }
  },

  view(ctrl, args) {
    const focus = getFirstDateOfWeek(dateAt(scrollTop))

    return m('div.pdPicker', [
      m('ul.headings', {
        class: dot('show', scrolling)
      }, options.weeks ? m('li', 'week') : null, options.days.map((day, i) => {
        return m('li', options.days[(options.firstDayOfWeek + i) % 7])
      })),
      m('.scroll', {
        onscroll: ctrl.scroll.bind(ctrl),
        config: ctrl.scrollStart
      }, m('ul', {
        config: ctrl.config,
        class: dot('weeks', options.weeks),
        style: {
          transform: 'translateY(' + datePosition(focus) - buffer * box.height + box.height + 'px)'
        }
      }, datesAround(ctrl, focus))
      ),
      m('.current', {
        class: scrolling ? 'show' : ''
      }, m('span', options.months[focus.getMonth()] + ' ' + focus.getFullYear()))
    ])

  }

}

export default picker

function datesAround(ctrl, focus) {
  const dates = []
      , start = options.start()
      , end = options.end()
      , begin = new Date(focus.getTime() - buffer * ms.week).getTime()

  for (let i = 0; i < 3 * buffer * 7; i++) {
    const date = new Date(begin + ms.day * i)

    if (options.weeks && i % 7 === 0) {
      dates.push(m('li.week', {
        onclick: ctrl.selectWeek.bind(null, date),
        class: dot('even', date.getMonth() % 2 === 0)
      }, m('span', getWeek(date))))
    }

    dates.push(m('li', {
      onclick: ctrl.select.bind(null, date),
      class: dot({
        sunday    : date.getDay() === 0,
        saturday  : date.getDay() === 6,
        first     : date.getDate() === 1,
        even      : date.getMonth() % 2 === 0,
        inView    : sameMonth(date, focus),
        today     : sameDate(date, options.today),
        selected  : start && date >= start && (date <= end || !end),
        disabled  : (options.min && date < options.min) || (options.max && date > options.max)
      })
    }, [
      m('span', date.getDate()),
      date.getDate() === 1 ? m('.month', options.months[date.getMonth()].substr(0, 3)) : null
    ]))
  }
  return dates
}

function dot(obj, toggle) {
  if (typeof toggle !== 'undefined')
    return toggle ? obj : ''

  let string = ''

  for (const key in obj) {
    if (obj[key])
      string += key + ' '
  }
  return string
}

function datePosition(date) {
  const col = dayNumber(date)
      , offset = Math.floor((date - options.today) / ms.day)
      , row = Math.ceil((offset - col) / 7)

  return origin + (row * box.height)
}

function dateAt(pos) {
  const row = Math.floor((origin - pos) / box.height)

  return new Date(options.today.getTime() - row * ms.week)
}

function sameMonth(a, b) {
  return a === b || (
         a && b && a.getFullYear() === b.getFullYear() &&
         a.getMonth() === b.getMonth())
}

function sameDate(a, b) {
  return a === b || (
         a && b && sameMonth(a, b) &&
         a.getDate() === b.getDate())
}

function getFirstDateOfWeek(d) {
  d = new Date(d)
  return new Date(d.setDate(d.getDate() - dayNumber(d)))
}

function dayNumber(date) {
  return (date.getDay() || (6 + options.firstDayOfWeek)) - options.firstDayOfWeek
}

function getWeek(date) {
  date = new Date(date)
  date.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 3)

  const firstThursday = date.valueOf()

  date.setMonth(0, 1)
  if (date.getDay() !== 4)
    date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7)
  return 1 + Math.ceil((firstThursday - date) / ms.week)
}
