import { formatDistance } from 'date-fns'
import m from 'mithril'
import b from 'bss'
import da from 'date-fns/locale/da'
import dialog from '../models/dialog'
import login from '../models/login'
import { request as api } from '../api'

let currentRequest

export default {
  controller: () => {
    const devices = m.prop()
        , countries = m.prop([])
        , stats = m.prop([])
        , restore = m.prop('')
        , where = new URLSearchParams(location.search)

    let sort
      , reverse
      , lastShift

    fetch()
    api({
      background: true,
      url: 'locations',
      data: {
        distinct: 'country.name'
      }
    }).then(c => countries(c.sort((a, b) =>
      a[c] < b[c] ? -1 : a[c] > b[c] ? 1 : 0
    ))).then(m.redraw)

    getStats().then(stats).then(m.redraw)
    getRestore().then(r => restore(r[0].count)).then(m.redraw)

    const username = login.user().username

    return {
      powah: username === 'fredrik' || username === 'rasmus',
      restore,
      stats,
      countries,
      devices,
      where,
      fetch,
      setFilter,
      check,
      installLS,
      reload,
      setSort,
      reboot,
      startApp,
      postReboot,
      runMultiple
    }

    function reboot(d) {
      dialog.prompt({
        title: 'Reboot ' + d.hostname,
        description: 'Are you sure you want to reboot ' + d.hostname + '?',
        confirmText: 'reboot',
        confirm: () => postReboot(d)
      })
    }

    function runMultiple(device, fn) {
      if (!lastShift)
        return lastShift = device

      const [start, end] = [devices().indexOf(device), devices().indexOf(lastShift)].sort()

      devices()
        .slice(start, end + 1)
        .forEach(fn)

      lastShift = null
    }

    function postReboot(d) {
      api({
        background: true,
        method: 'post',
        url: 'devices/' + d._id + '/reboot'
      })
      d.connected = false
    }

    function setSort(c) {
      lastShift = null
      reverse = c === sort && reverse === 1 ? -1 : 1
      sort = c
      devices().sort((a, b) =>
        (a[c] < b[c] ? -1 : a[c] > b[c] ? 1 : 0) * reverse
      )

    }

    function reload(d) {
      api({
        background: true,
        method: 'post',
        url: 'devices/' + d._id + '/execute',
        data: {
          path: 'https://dist.beat.dk/utilities/run.exe',
          arguments: 'taskkill /F /IM node.exe'
        }
      }).then(() => d.reloadLS = true).then(m.redraw)
    }

    function startApp(d) {
      m.request({
        url: 'https://' + login.user().lifesupport + '/stations/' + d.station + '/applications?' + Math.random(),
        config: xhr => xhr.setRequestHeader('Authorization', 'Bearer ' + login.user().token)
      }).then(() => d.reloadLS = true).then(m.redraw)
    }

    function installLS(d) {
      api({
        background: true,
        method: 'POST',
        url: 'devices/' + d._id + '/installLifeSupport'
      })
    }

    function check(d, value, checked) {
      if (!d.applications)
        return

      d[value] = checked
      let app = d.applications.find(a => a.application === '5ac7736fc0401f1ac88baa49')

      if (!app) {
        app = { application: '5ac7736fc0401f1ac88baa49' }
        d.applications.push(app)
      }

      app.config = app.config || {
        active: false, forceDownload: false, restore: false
      }

      app.config[value] = checked

      clearTimeout(d.updateDefer)
      d.updateDefer = setTimeout(() =>
        api({
          background: true,
          method: 'PUT',
          url: 'stations/' + d.station,
          data: {
            applications: d.applications
          }
        }).then(r => startApp(d))
      , 2000)
    }

    function setFilter(field, value) {
      where.delete('hostname')
      value
        ? where.set(field, value)
        : where.delete(field)

      fetch()
    }

    function fetch() {
      lastShift = null
      devices(null)
      history.replaceState(null, null, '/restore?' + where.toString())

      ;(where.has('state') && where.get('state') !== 'virgin'
        ? getWithState(where, devices)
        : get(where)
      ).then(d => d.map(device => Object.assign(device, {
        forceDownload: device.applications && device.applications.some(a => a.config && a.config.forceDownload),
        restore: device.applications && device.applications.some(a => a.config && a.config.restore),
        active: device.applications && device.applications.some(a => a.config && a.config.active)
      }))).then(d =>
        api({
          background: true,
          url: 'devices',
          data: {
            select: 'connected lastConnected volumes',
            limit: 500,
            where: JSON.stringify({
              _id: { $in: d.map(d => d._id) }
            })
          }
        }).then(online => {
          d.forEach(device => {
            Object.assign(device, online.find(o => o._id === device._id) || {}, {
              info: device.progress && (device.progress.info || device.progress.error || '').replace(/'/, ''),
              state: device.progress && device.progress.state,
              error: device.progress && device.progress.error
            })
          })
          devices(d)
        })
      )
      .then(m.redraw)
    }
  },
  view: (ctrl) =>
    m('main' + b.p(20),
      m('header', {
        onmouseenter: () => getStats().then(ctrl.stats).then(m.redraw)
      },
        'set for restore: ' + ctrl.restore(),
        ctrl.stats().reduce((a, s) =>
          a + ' - ' + s._id + ': ' + s.count
        , '')
      ),
      m('.filters' + b.p(10),
        m('strong', 'Filters:'),
        filter('model', ['Mini PC', 'MIB15'], value => ctrl.setFilter('model', value), ctrl.where.get('model')),
        filter('os', ['Windows 8.1', '10586', '14393', '15063', '16299', '17134', '17763', '18362', '18363'], value => ctrl.setFilter('os', value), ctrl.where.get('os')),
        filter('country', ctrl.countries(), value => ctrl.setFilter('country', value), ctrl.where.get('country')),
        filter('state', ['virgin', 'inactive', 'preparing', 'downloading', 'ready', 'restoring', 'done'], value => ctrl.setFilter('state', value), ctrl.where.get('state')),
        filter('type', ['mediabutler', 'beatplayer', 'beamsign', 'dfds'], value => ctrl.setFilter('type', value), ctrl.where.get('type')),
        filter('restore', ['set', 'not set'], value => ctrl.setFilter('restore', value), ctrl.where.get('restore'))
      ),
      m('.toggles' + b.p(10),
        m('label', 'hostname: ', m('input', {
          value: ctrl.where.get('hostname') || '',
          oninput: e => ctrl.where.set('hostname', e.target.value),
          onkeyup: e => e.keyCode === 13 && ctrl.fetch()
        })),
        m('label' + b.ml(30), 'include old: ', m('input', {
          type: 'checkbox',
          onclick: e => ctrl.setFilter('old', e.target.checked),
          checked: ctrl.where.get('old')
        })),
        m('label' + b.ml(30), 'only errors: ', m('input', {
          type: 'checkbox',
          onclick: e => ctrl.setFilter('errors', e.target.checked),
          checked: ctrl.where.get('errors')
        })),
        m('label' + b.ml(30), 'only office: ', m('input', {
          type: 'checkbox',
          onclick: e => ctrl.setFilter('office', e.target.checked),
          checked: ctrl.where.get('office')
        }))
      ),
      m('table' + b
          .w('100%')
          .$nest('td', b.p(2).pr(10).overflow('hidden').textOverflow('ellipsis').maxWidth(200).whiteSpace('nowrap'))
          .$nest('tr:nth-child(odd)', b.bc('#ddd'))
          .$nest('tr:hover', b.bc('#aaccee')),
        {
          cellpadding: 0,
          cellspacing: 0
        }, m('tr' + b.tt('capitalize').fw('bold'), {
          onclick: (e) => ctrl.setSort(e.target.textContent)
        },
          m('td', '#'),
          m('td', 'type'),
          m('td', 'hostname'),
          m('td', 'os'),
          m('td', 'lastConnected'),
          m('td', 'model'),
          m('td', 'country'),
          m('td', 'location'),
          m('td', 'state'),
          m('td', 'info'),
          m('td', 'collected'),
          m('td', 'active'),
          m('td', 'forceDownload'),
          m('td', 'restore')
        ),
        !ctrl.devices()
          ? m('div' + b.mt(50).w('100%').ta('center'), m('h1', 'loading'))
          : ctrl.devices().length === 0
          ? m('div' + b.mt(50).w('100%').ta('center'), m('h1', 'Nothing to see here'))
          : ctrl.devices().map((d, i) =>
          m('tr',
            m('td', i + 1),
            m('td', d.progress && d.progress.beatPlayer ? 'beatplayer' : d.type),
            m('td',
              m('i.icon-cw', {
                onmousedown: (e) => {
                  if (e.shiftKey && ctrl.powah) {
                    e.preventDefault()
                    return ctrl.runMultiple(d, ctrl.postReboot)
                  }

                  ctrl.powah ? ctrl.postReboot(d) : ctrl.reboot(d)
                }
              }),
              m('a', {
                href: '/devices/' + d._id,
                config: m.route
              }, d.hostname)
            ),
            m('td', d.os && d.os.replace(/Windows ([0-9.]*) .*\(([0-9]{4,5})..*/, '$1 ($2)')),
            m('td' + b.c(d.connected ? 'black' : 'white').bc(d.connected ? '#00ff99' : '#ff4444'), {
              title: d.lastConnected
            },
              d.connected ? 'online' : d.lastConnected ? formatDistance(new Date(d.lastConnected), new Date(), { locale: da }) : 'so long ago'
            ),
            m('td', d.hardwareModel),
            m('td', d.country),
            m('td', d.location),
            m('td', {
              style: b.bc(
                (d.error && '#ff4444') ||
                (d.state === 'preparing' && 'bisque') ||
                (d.state === 'restoring' && 'orange') ||
                (d.state === 'done' && '#00ff99') ||
                (d.state === 'ready' && '#0099ff')
              ).style
            }, d.state || 'virgin'),
            m('td', {
              title: d.state && (formatDistance(d.collected, new Date(), { locale: da }) + ' siden\n' + (d.info || d.error))
            }, d.info || d.error),
            m('td' + b.c(d.station ? 'black' : 'white').bc(!d.station ? '#ff4444' : (d.reloadLS && '#ff44ff'))
              , d.station
                ? m('i.icon-cw', { onmousedown: (e) => {
                  if (e.shiftKey && ctrl.powah) {
                    e.preventDefault()
                    return ctrl.runMultiple(d, ctrl.reload)
                  }

                  ctrl.reload(d)
                } })
                : m('a' + b.o(!d.connected && 0.5), { onclick: d.connected && (() => ctrl.installLS(d)) }, 'install')
              , formatDistance(new Date(d.collected), new Date())
            ),
            m('td', checkbox(d, ctrl, 'active')),
            m('td', checkbox(d, ctrl, 'forceDownload')),
            m('td', checkbox(d, ctrl, 'restore'))
          )
        )
      )
    )
}

function checkbox(d, ctrl, field) {
  return m('input', {
    disabled: disabled(d.progress),
    type: 'checkbox',
    checked: d[field],
    onclick: e => {
      if (e.shiftKey && ctrl.powah)
        return ctrl.runMultiple(d, (d) => ctrl.check(d, field, e.target.checked))

      ctrl.check(d, field, e.target.checked)
    }
  })
}

function disabled(p) {
  return p && p.state === 'restoring'
}

function filter(name, options, onchange, selected) {
  return m('label' + b.ml(10), name + ':',
    m('select' + b.fs(16).ml(5),
      {
        onchange: e => onchange(e.target.value)
      },
      m('option', { value: '' }, 'all'),
      options.map(o =>
        m('option', { value: o, selected: o === selected }, o)
      )
    ),
    m('span' + b.mr(20).ml(5), { onclick: () => onchange(null) }, 'x')
  )
}

function getStats() {
  return api({
    background: true,
    method: 'POST',
    url: 'query/transaction',
    data: {
      type: 'aggregate',
      command: JSON.stringify([{
        $match: {
          application: 'WindowsRestore'
        }
      }, {
        $sort: { _id: -1 }
      }, {
        $group: {
          _id: '$station',
          state: { $first: '$data.state' }
        }
      }, {
        $group: {
          _id: '$state',
          count: { $sum: 1 }
        }
      }])
    }
  })
}

function getRestore() {
  return api({
    background: true,
    method: 'POST',
    url: 'query/transaction',
    data: {
      type: 'aggregate',
      command: JSON.stringify([{
        $match: {
          application: 'WindowsRestore'
        }
      }, {
        $sort: { _id: -1 }
      }, {
        $group: {
          _id: '$station',
          state: { $first: '$data.state' }
        }
      }, {
        $lookup: {
          from: 'stations',
          localField: '_id',
          foreignField: '_id',
          as: 'station'
        }
      }, {
        $unwind: '$station'
      }, {
        $match: {
          'station.applications.config.restore': true,
          state: { $ne: 'done' }
        }
      }, {
        $group: {
          _id: null,
          count: { $sum: 1 }
        }
      }])
    }
  })
}

function get(where) {
  if (currentRequest)
    currentRequest.abort()

  return api({
    background: true,
    method: 'POST',
    config: xhr => currentRequest = xhr,
    url: 'query/device',
    data: {
      type: 'aggregate',
      command: JSON.stringify([{
        $match: where.get('hostname') ? {
          hostname: { $regex: where.get('hostname'), $options: 'i' }
        } : {
          ip: { [where.get('office') ? '$eq' : '$ne'] : '85.204.133.170' },
          hardwareModel: where.get('model') ? where.get('model') : { $in: ['Mini PC', 'MIB15'] },
          os: where.get('os') ? { $regex: where.get('os') } : undefined,
          type: where.get('type') ? where.get('type') : undefined,
          modified: where.get('old') ? undefined : { $gt: 'ISODate(' + new Date(Date.now() - 1000 * 60 * 60 * 24 * 30) + ')' }
        }
      }, {
        $sort: {
          modified: -1
        }
      }, {
        $lookup: {
          from: 'stations',
          localField: 'hostname',
          foreignField: 'systemName',
          as: 'station'
        }
      }, {
        $unwind: { path: '$station', preserveNullAndEmptyArrays: true }
      }, {
        $match: where.get('hostname') || !where.get('state') ? {} : {
          'station.applications.config.active': { $exists: false }
        }
      }, {
        $match: where.has('restore') ? {
          'station.applications.config.restore': where.get('restore') === 'set'
        } : {}
      }, {
        $lookup: {
          from: 'locations',
          localField: 'location',
          foreignField: '_id',
          as: 'location'
        }
      }, {
        $unwind: { path: '$location', preserveNullAndEmptyArrays: true }
      }, {
        $match: where.get('country') ? {
          'location.country.name': where.get('country')
        } : {}
      }, {
        $lookup: {
          from: 'transactions',
          localField: 'station._id',
          foreignField: 'station',
          as: 'transactions'
        }
      }, {
        $project: {
          progress: {
            $let: {
              vars: {
                t: {
                  $arrayElemAt: [{
                    $filter: {
                      input: '$transactions',
                      as: 't',
                      cond: { $eq: ['$$t.application', 'WindowsRestore'] }
                    }
                  }, -1]
                }
              },
              in: '$$t.data'
            }
          },
          collected: {
            $let: {
              vars: {
                t: {
                  $arrayElemAt: [{
                    $filter: {
                      input: '$transactions',
                      as: 't',
                      cond: { $eq: ['$$t.application', 'WindowsRestore'] }
                    }
                  }, -1]
                }
              },
              in: '$$t.collected'
            }
          },
          location: '$location.reference',
          country: '$location.country.name',
          station: '$station._id',
          applications: '$station.applications',
          lastConnected: 1,
          os: 1,
          hostname: 1,
          type: 1,
          _id: 1
        }
      }, {
        $match: {
          'progress.beatPlayer': where.get('hostname') ? undefined : (where.get('type') === 'beatplayer' || null)
        }
      }, {
        $limit: 300
      }])
    }
  })
}

function getWithState(where) {
  if (currentRequest)
    currentRequest.abort()

  return api({
    background: true,
    method: 'POST',
    config: xhr => currentRequest = xhr,
    url: 'query/transaction',
    data: {
      type: 'aggregate',
      command: JSON.stringify([{
        $match: {
          application: 'WindowsRestore'
        }
      }, {
        $sort: { _id: -1 }
      }, {
        $group: {
          _id: '$station',
          data: { $first: '$data' },
          collected: { $first: '$collected' }
        }
      }, {
        $match: {
          'data.state': where.get('state'),
          'data.error': where.get('errors') ? { $exists: true } : undefined
        }
      }, {
        $lookup: {
          from: 'stations',
          localField: '_id',
          foreignField: '_id',
          as: 'station'
        }
      }, {
        $unwind: '$station'
      }, {
        $match: where.has('restore') ? {
          'station.applications.config.restore': where.get('restore') === 'set'
        } : {}
      }, {
        $lookup: {
          from: 'devices',
          localField: 'station.systemName',
          foreignField: 'hostname',
          as: 'device'
        }
      }, {
        $unwind: '$device'
      }, {
        $lookup: {
          from: 'locations',
          localField: 'device.location',
          foreignField: '_id',
          as: 'location'
        }
      }, {
        $unwind: {
          path: '$location',
          preserveNullAndEmptyArrays: true
        }
      }, {
        $match: where.get('country') ? {
          'location.country.name': where.get('country')
        } : {}
      }, {
        $project: {
          _id: '$device._id',
          station: '$station._id',
          hostname: '$device.hostname',
          location: '$location.reference',
          country: '$location.country.name',
          hardwareModel: '$device.hardwareModel',
          os: '$device.os',
          applications: '$station.applications',
          type: '$device.type',
          progress: '$data',
          collected: '$collected'
        }
      }, {
        $match: {
          os: where.get('os') ? { $regex: where.get('os') } : undefined,
          type: where.get('type') ? where.get('type') : undefined,
          hostname: where.get('hostname') ? { $regex: where.get('hostname'), $options: 'i' } : undefined
        }
      }, {
        $limit: 300
      }])
    }
  })
}
