import m from 'mithril'
import b from 'bss'
import Device from '../models/device'
import utils from '../utils'
import location from '../components/location'

export default {

  controller(attr) {

    function getArea(origArea) {
      if (attr) {
        if (attr.search)
          return attr.search
      }

      let area = m.route().split('/')[1]
      const start = area.indexOf('?')

      if (start > -1)
        area = area.substring(0, start)

      if (area === 'shippings')
        area = 'locations'
      else if (area === 'updater' || area === 'query')
        area = 'devices'

      return area
    }


    let focused = false
    const defaultSearchPath = 'devices'
    const ctrl = {
      focused(value) {
        if (arguments.length > 0) {
          focused = value
          if (focused) {
            const area = getArea()

            if (area !== ctrl.areaSelected)
              ctrl.results.length = 0

            ctrl.area(area)
          } else {
            ctrl.areaSelected = getArea()
          }
        }

        return focused
      },
      area: m.prop(getArea()),
      areaSelected: '',
      selected: m.prop(''),
      value: null,

      searchValue: attr => {
        return attr ? attr.defaultValue ? ctrl.focused() ? ctrl.value : attr.defaultValue
          : ctrl.value : ctrl.value
      },

      switchArea: (area) => {
        ctrl.results.length = 0
        ctrl.area(area)
      },

      input(e) {
        focused = true

        if (e.ctrlKey && [68, 76, 79, 85].includes(e.which)) {
          e.preventDefault()
          switch (e.which) {
          case 68:
            return ctrl.switchArea('devices')
          case 76:
            return ctrl.switchArea('locations')
          case 79:
            return ctrl.switchArea('organizations')
          case 85:
            return ctrl.switchArea('users')
          default:
            break
          }
        } else if (!e.target.value && e.which === 8) {
          if (ctrl.attr)
            return null

          ctrl.area('')
        } else if (e.target.value.length === 1 && e.which === 8) {
          ctrl.value = null
        } else if (e.which === 46 && ctrl.value === e.target.value) {
          ctrl.value = null
        } else if (e.which === 27) { // escape
          if (ctrl.attr) {
            if (ctrl.attr.visible)
              ctrl.attr.visible(false)

            return null
          }
        } else if (e.which === 40) { // down
          e.preventDefault()
          return selectNext()
        } else if (e.which === 38) { // up
          e.preventDefault()
          return selectPrev()
        } else if (e.which === 13) {
          const selected = ctrl.selected()

          if (ctrl.attr)
            ctrl.onClick(ctrl.searchValue(ctrl.attr) === null ? '' : selected, ctrl.attr)

          if (selected && !ctrl.attr) {
            ['locations', 'organizations'].includes(ctrl.area()) ? m.route('/' + ctrl.area() + '/' + selected._id)
              : m.route('/devices/' + selected._id)

            ctrl.focused(false)
          }
        }
      },
      results: [],
      attr: attr,
      timer: null,
      query(value) {
        if (!value)
          return null

        ctrl.value = value

        value = value.trim()

        const searchLimit = ctrl.area() === 'c5accounts' ? 1 : 4

        ctrl._query = value

        ctrl.results = []

        clearTimeout(ctrl.timer)

        if (ctrl.xhr)
          ctrl.xhr.abort()

        if (!value || value.length < searchLimit)
          return m.redraw()

        ctrl.timer = setTimeout(() => {
          Device.request({
            url: `${ctrl.area() ? ctrl.area() : defaultSearchPath}/search`,
            method: 'POST',
            background: true,
            config: xhr => ctrl.xhr = xhr,
            data: { query: value }
          }).then(data => ctrl.results = data).then(m.redraw)
        }, 300)
        ctrl.selected(null)
      },
      onClick(item, attr) {
        if (ctrl.attr) {
          attr.onclick ?
            attr.search === 'devices/lonely' ? attr.onclick(item) :
              [attr.onclick(item), focused = false, attr.defaultValue ?
                attr.defaultValue = item.name || item : ctrl.query(item.name)] :
            attr.search === 'locations' ? location.attachLocation(item._id) :
              [attr.data.push(item._id),
                attr.search === 'organizations' ?
                  location.populate(({ organizations: attr.data })) :
                  location.populate(({ accounts: attr.data }))]
        }
      }
    }

    function flat() {
      return ctrl.results
    }

    function selectNext() {
      const f = flat()
        , index = f.indexOf(ctrl.selected())

      ctrl.selected(index < f.length - 1 ? f[index + 1] : f[f.length - 1])
    }

    function selectPrev() {
      const f = flat()
        , index = f.indexOf(ctrl.selected())

      ctrl.selected(index > 0 ? f[index - 1] : f[0])
    }

    return ctrl
  },

  view(ctrl, attr) {
    const searchId = attr == null ? '' : attr.search
      , value = ctrl.searchValue(attr)

    if (attr) {
      if (attr.search)
        ctrl.area(attr.search)
    }

    return m('#search', {
      style: ctrl.attr ? ctrl.attr.styleSearch || { position: 'relative' } : '',
      class: 'focus'
    }, [
      m('.input', ctrl.attr ? ctrl.attr.styleInput ? { style: ctrl.attr.styleInput } : null : null, [
        ctrl.area() ? ctrl.attr ? ctrl.attr.hideArea ? null :
          m('.area.raised', m('span', attr.label || ctrl.area())) :
          m('.area.raised', m('span', ctrl.area())) : null,
        m('input#searchInput' + searchId, {
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
          spellcheck: false,
          onkeydown: ctrl.input,
          onblur: ctrl.focused.bind(null, false),
          onfocus: ctrl.focused.bind(null, true),
          oninput: m.withAttr('value', ctrl.query),
          value: value
        })
      ]),
      ctrl.attr ? null : m('label.button.icon-search.round.raisedlight', {
        for: 'searchInput',
        onclick: () => ctrl.query(ctrl._query)
      }),
      m('.results', {
        style: { maxHeight: (ctrl.focused() ? ctrl.attr ? 500 : window.document.body.clientHeight - (100) : 0) + 'px',
          left: ctrl.attr ? ctrl.attr.resultsLeft || '108px' : '', top: ctrl.attr ? ctrl.attr.resultsTop || '4px' : '' }
      }, [
        m('.area', ctrl.results.map(item => m('a',
          ctrl.attr ?
            { style: { cursor: 'pointer' }, class: ctrl.selected() === item ? 'selected' : '',
              onclick: () => ctrl.onClick(item, attr)
            } : {
              key: ['locations', 'organizations', 'partners'].includes(ctrl.area()) ? item._id : item._id + item.hostname,
              class: ctrl.selected() === item ? 'selected' : '',
              config: m.route,
              href: ['locations', 'organizations', 'partners'].includes(ctrl.area()) ? (ctrl.area() + '/' + item._id)
                : '/devices/' + item._id
            }, [
            m('div',
              ctrl.area() === 'locations' ? item.reference :
                ctrl.area() === 'organizations' ? item.name :
                  ctrl.area() === 'c5accounts' ? item.accountingId :
                    ctrl.area() === 'partners' ? item.name :
                      ctrl.area() === 'countries' ? item.name :
                        ctrl.area() === 'lists/folders' ? item : [m('span' + b.bc('#333').c('white').br(2).p(4, 4, 1, 4).mr(4), Device.types[item.type] || '?'), item.hostname],
              ['', 'devices'].includes(ctrl.area()) ?
                [m('small', { style: { borderRadius: '3px', margin: '5px', padding: '3px 5px', color: 'white',
                  background: item.connected ? item.ip === '85.204.133.170' ? 'purple' : 'green' : 'red' } }, 'Lifeline'),
                m('small', { style: { borderRadius: '3px', margin: '5px', padding: '3px 5px', color: 'white',
                  background: item.connectedMediabutler ? item.ip === '85.204.133.170' ? 'purple' : 'green' : 'red' } }, 'Mediabutler'),
                item.lastConnected && !item.connected && !item.connectedMediabutler ? m('small', utils.prettyDate(item.lastConnected)) : '']
                : null
            ),
            ['organizations', 'partners', 'countries', 'lists/folders', 'devices/lonely'].includes(ctrl.area()) ? null :
              m('small', ctrl.area() === 'locations' ? item.name :
                ctrl.area() === 'c5accounts' ?
                  `${item.name ? item.name : ''} ${item.searchName ? `(${item.searchName})` : ''}` :
                  item.amuseiclocation || 'unknown location'),

            ctrl.area() === 'locations' ?
              [item.contact ? m('h5', `Att: ${item.contact}`) : null,
                item.street ? m('h5', item.street) : null,
                m('h5', `${item.zip || ''} ${item.city || ''}${item.state || '' }`),
                item.country ? m('h5', item.country) : null] :

              ctrl.area() === 'c5accounts' ? item.address ? m('h5', item.address) : null : null
          ])))
      ])
    ])
  }
}
