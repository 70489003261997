import m from 'mithril'
import Media from '../models/media'
import itemView from './media.single'
import list from '../components/list'

export default {

  controller() {
    const id = m.route.param('id')
    const ctrl = {
      list : Media.get()
    }

    if (id === 'new') {
      ctrl.item = Media.create()
      ctrl.item.on('created', u => m.route('/media/' + u.key))
    } else if (id) {
      ctrl.item = Media.get(id)
      if (ctrl.item)
        ctrl.item.on('deleted', () => m.route('/media'))
    }

    return ctrl
  },

  view(ctrl) {
    return m('#media.columns', { class: ctrl.item ? 'three' : '' }, [
      m(list, {
        title        : 'Media',
        createUrl    : 'new',
        route        : '/playlists/',
        items        : ctrl.list(),
        itemId       : (p) => p._id,
        itemTitle    : (p) => p.title,
        itemSubtitle : (p) => p.version
      }),
      m('', [
        /*m('h1', [
          m('a', {
            href: '/media',
            config: m.route
          }, 'Media'),
          m('a.round.button.raisedLight', {
            href: '/media/new', config : m.route
          }, '+')
        ]),*/
        m('.list', {
          class: 'cards'
        }, ctrl.list().map(item => {
          return m('a', {
            key: item._id,
            class: item._id === m.route.param('id') ? 'selected' : '',
            href: '/media/' + item._id,
            config: m.route,
            style: {
              backgroundImage: 'url(' + (item.thumb || '/images/encoding.png') + ')'
            }
          }, m('div', [
            m('h3', item.title),
            m('p', item.file && item.file.meta ?
                   (item.file.meta.width + ' x ' + item.file.meta.height) :
                   Media.statuses[item.status] || item.status.replace(/_/g, ''))
          ]))
        }))
      ]),
      m('.raised.detail', {
        class: m.route.param('id') ? 'show' : ''
      }, m.route.param('id') ? itemView(ctrl.item) : '')
    ])
  }

}


