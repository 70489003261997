import m from 'mithril'
import textfield from './components/input'
import login from './models/login'

export default {

  controller: function() {
    this.hideError = () => {
      login.error('')
    }
    this.submit = e => {
      e.preventDefault()
      login.loading(true)
      setTimeout(() => {
        login.getToken()
      }, 0)
    }
  },

  view: function(ctrl) {
    return m('div#login',
      m('form', [
        m('.error', { onclick: ctrl.hideError, class: login.error() ? 'show' : '' }, login.error()),
        m('i.icon-logo'),
        m(textfield, { name: 'username', value: login.credentials.username }),
        m(textfield, { name: 'password', value: login.credentials.password, type: 'password' }),
        m('div',
          m('button', { class: (login.loading() ? 'loading' : ''), type: 'submit', onclick: ctrl.submit }, 'Log in')
        ),
        m('label[for=rememberMe]', [
          m('input#rememberMe', { type: 'checkbox', onclick: m.withAttr('checked', login.credentials.rememberMe), checked: login.credentials.rememberMe() }),
          m('span', 'remember')
        ]),
        m('a', 'help us')
      ]),
      m('#xhrProgressBar')
    )
  }

}
