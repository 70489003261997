import m from 'mithril'
import scheduleEditor from './schedules'
import repeatbuilder from './repeatbuilder'
import math from '../helper/math'
import login from '../models/login'

let auth

const DataUrl = {
  const: {
    device: null,
    base: null,
    end: null,
    config: function(xhr) {
      xhr.setRequestHeader('Authorization', auth)
    }
  },
  areas: {
    monitorschedules: 'monitorschedules',
    monitorevents: 'monitorevents',
    monitors: 'monitors'
  },
  limit: 0,
  resetLimit: () => {
    DataUrl.limit = 25
  },
  localUTC: new Date().getTimezoneOffset() / 60
}

function getDeviceData(device) {
  DataUrl.resetLimit()
  DataUrl.const.device = device
  auth = `Bearer ${login.user().token}`

  DataUrl.const.base = window.apiUrl
  DataUrl.const.end = `?conditions={"deviceId":"${device}"}&sort=-eventAt&limit=100`
  Object.keys(DataUrl.areas).forEach(area => {
    getData(area)
  })

  function getData(area) {
    m.request({
      method: 'GET', url: DataUrl.const.base + area + DataUrl.const.end, config: DataUrl.const.config
    }).then(data => {
      loaded(data, device, area)
    })
  }
}

function postDeviceData(data) {
  delete data.__v

  let method = 'PUT'
  let url = `${DataUrl.const.base}monitorschedules/${DataUrl.areas.monitorschedules._id}`

  if (!data.deviceId) {
    method = 'POST'
    data.deviceId = DataUrl.const.device
    url = `${DataUrl.const.base}monitorschedules`
  }

  m.request({ method, url,
    config: DataUrl.const.config,
    data: data
  }).then(data => {
    if (data) scheduleEditor.newModal('Success: Schedules are updated', [], 'alert')
  }, () => { scheduleEditor.newModal('FAILED! Something went wrong, check all entries', [], 'alert') })
}

function loaded(data, device, updateArea) {
  Object.keys(DataUrl.areas).forEach(area => {
    if (area === updateArea) {
      if (updateArea === 'monitorevents') {
        DataUrl.areas[area] = { monitor: data }
      } else {
        DataUrl.areas[area] = data.find((item, i) => {
          if (item.deviceId === device)
            return i - 1
        })
      }
    }
  })
}

function monitorStatus(status, name) {
  let style = '.online'

  if (status.value === 'off')
    style = '.offline'

  return m(`button.primeTime${style}`, `${name}: ${status.value}`)

}

function afterValue(from, afterValue, title) {
  if (!afterValue)
    return null

  return m('tr', [
    m('td.force', 'Then →'),
    m('td', [
      m('span.force', title + math.capitalize(from)),
      m('span.force', ' to '),
      m('span.force', math.capitalize(afterValue))
    ])
  ])
}

function scheduleForCommand(scheduledPeriod, key, val, edit, editClass) {
  if (!DataUrl.areas.monitorschedules.events[key])
    return null

  return m('tr.period', [
    scheduleEditor.addSCHT(DataUrl.areas.monitorschedules.events[key], val, edit),
    seperator(key),
    m('tr', [
      m('td', 'Period: '),
      m('td', { onclick: () => {
        if (edit)
          scheduleEditor.editPeriod(DataUrl.areas.monitorschedules.events[key].periods[val])
      } }, m.trust(scheduledPeriod))
    ]),
    m('tr', [
      m('td', 'At: '),
      getPeriodTime(DataUrl.areas.monitorschedules.events[key].periods[val], edit)
    ]),
    getPeriodDuration(DataUrl.areas.monitorschedules.events[key].periods[val], edit, editClass,
      DataUrl.areas.monitorschedules.events[key]),
    getDays(DataUrl.areas.monitorschedules.events[key].periods[val], edit, editClass),
    m('tr', [
      m('td', 'Repeat: '),
      getPeriodFrequency(DataUrl.areas.monitorschedules.events[key].periods[val], edit)
    ])
  ])

  function seperator(key) {
    if (key % 2) {
      return m('tr', [
        m('th')
      ])
    }
    return null
  }
}

function getPeriodTime(obj, edit) {

  if (obj.hours.length + obj.minutes.length + obj.seconds.length > 3) {
    return m('td', { onclick: () => {
      if (edit)
        scheduleEditor.editAtTime(obj)
    } }, [
      m('tr', [
        m('td', 'Hour: '),
        m('td', obj.hours.join(', '))
      ]),
      m('tr', [
        m('td', 'Minute: '),
        m('td', obj.minutes.join(', '))
      ]),
      m('tr', [
        m('td', 'Second: '),
        m('td', obj.seconds.join(', '))
      ])
    ])
  }

  return m('td', { onclick: () => {
    if (edit)
      scheduleEditor.editAtTime(obj)
  } }, `${math.twoDigit(obj.hours)}:${math.twoDigit(obj.minutes)}:${math.twoDigit(obj.seconds)}`)
}

function getPeriodDuration(period, edit, editClass, ev) {
  if (!period.duration) {
    if (edit) {
      return m('tr', [
        m('td', 'Duration: '),
        m('td', [
          m('button.primeTime.online.xtra.single', { onclick: () => {
            if (edit)
              scheduleEditor.editPeriodDuration(period)
          } }, 'Add duration')
        ])
      ])
    }
    return null
  }

  const time = math.convertSeconds(period.duration)
  let timeDisplay = ''

  if (time[0] !== 0)
    timeDisplay = `${time[0]} hours`
  if (time[1] !== 0) {
    if (timeDisplay)
      timeDisplay = `${timeDisplay} `
    timeDisplay = `${timeDisplay + time[1]} minutes`
  }
  if (time[2] !== 0) {
    if (timeDisplay)
      timeDisplay = `${timeDisplay} `
    timeDisplay = `${timeDisplay + time[2]} seconds`
  }

  return m('tr', [
    m('td', 'Duration: '),
    m(`td${editClass}`, { onclick: () => {
      if (edit)
        scheduleEditor.editPeriodDuration(period)
    } }, timeDisplay),
    scheduleEditor.removeDuration(edit, period, ev)
  ])
}

function getDays(days, edit, editClass) {
  if (!days.days || days.days.length === 0) {
    if (edit) {
      return m('tr', [
        m('td', 'Days: '),
        m('td', [
          m('button.primeTime.online.xtra.single', { onclick: () => {
            if (edit)
              scheduleEditor.editScheduledDays(days)
          } }, 'Add days')
        ])
      ])
    }
    return null
  }

  return m('tr', [
    m('td', 'Days: '),
    m(`td${editClass}`, { onclick: () => {
      if (edit)
        scheduleEditor.editScheduledDays(days)
    } }, math.capitalize(days.days.join(', '))),
    scheduleEditor.removeDays(edit, days)
  ])
}

function getPeriodFrequency(obj, edit) {
  return m('td', { onclick: () => {
    if (edit)
      scheduleEditor.editRepeat(obj)
  } }, repeatbuilder.resolveTitle(obj))
}

function monitorSchedulesView(edit) {
  if (!DataUrl.areas.monitorschedules.events)
    return null

  const monitors = []
  let editClass = ''

  if (edit)
    editClass = '.edit'

  return m('table.monitors', [
    Object.keys(DataUrl.areas.monitorschedules.events).map(key => {
      if (monitors.indexOf(DataUrl.areas.monitorschedules.events[key].unit) === -1)
        monitors.push(DataUrl.areas.monitorschedules.events[key].unit)

      return m(`table.schedule${editClass}`, [
        m('td', { colspan: 2 }, [
          m('td', [
            m('h1.edit', { onclick: () => {
              if (edit)
                scheduleEditor.editTitle(DataUrl.areas.monitorschedules.events[key])
            } }, DataUrl.areas.monitorschedules.events[key].title)]),
          m('td', [
            m('button.primeTime', { onclick: () => {
              if (edit)
                scheduleEditor.editMonitor(DataUrl.areas.monitorschedules.events[key].unit, monitors)
            } }, DataUrl.areas.monitorschedules.events[key].unit),
            scheduleEditor.removeSchedule(edit, key, DataUrl.areas.monitorschedules.events)])
        ]), scheduleEditor.addCMD(edit, DataUrl.areas.monitorschedules.events[key]),

        Object.keys(DataUrl.areas.monitorschedules.events[key].sets).map(val => {
          let title = scheduleEditor.Template.cmd.title[0]
          let restore = ''

          if (DataUrl.areas.monitorschedules.events[key].sets[val].forcedValue === 'true')
            title = scheduleEditor.Template.cmd.title[1]

          if (DataUrl.areas.monitorschedules.events[key].sets[val].restoreValue === 'true')
            restore = ' (Restore)'

          return m('tr', [
            m('td', { onclick: () => {
              if (edit)
                scheduleEditor.editCMD(DataUrl.areas.monitorschedules.events[key].sets[val])
            } }, [
              m('td.force', title),
              m('td', [
                m('span.force', math.capitalize(DataUrl.areas.monitorschedules.events[key].sets[val].key)),
                m('span.force', ' to '),
                m('span.force', math.capitalize(DataUrl.areas.monitorschedules.events[key].sets[val].value)),
                m('span', restore)
              ]),
              afterValue(DataUrl.areas.monitorschedules.events[key].sets[val].key,
                DataUrl.areas.monitorschedules.events[key].sets[val].afterValue, scheduleEditor.Template.cmd.title[0])
            ])
          ],
          scheduleEditor.removeCommand(edit, val, DataUrl.areas.monitorschedules.events[key].sets))
        }),

        Object.keys(DataUrl.areas.monitorschedules.events[key].periods).map(val => {
          const period = { start: DataUrl.areas.monitorschedules.events[key].periods[val].start,
            end: DataUrl.areas.monitorschedules.events[key].periods[val].end }

          let scheduledPeriod

          if (period.start && period.end) {
            scheduledPeriod = `${math.convertDate(period.start, false)},
                               ${math.convertTime(period.start, false, false)} - <br>
                               ${math.convertDate(period.end, false)},
                               ${math.convertTime(period.end, false, false)}`
          } else if (period.start) {
            scheduledPeriod = `${math.convertDate(period.start, false)},
                               ${math.convertTime(period.start, false, false)} -`
          } else {
            scheduledPeriod = `- ${math.convertDate(period.end, false)},
                                 ${math.convertTime(period.end, false, false)}`
          }

          return scheduleForCommand(scheduledPeriod, key, val, edit, editClass)
        })
      ])
    })
  ])
}

function monitorCondition() {
  const sortedMonitor = {}

  return m('table.monitors.conditions', [
    m('tr', [
      m('td', { colspan: '2' }, [
        m('h1', math.capitalize(DataUrl.areas.monitors.unit)),
        monitorStatus(DataUrl.areas.monitors.inputSource, 'input source'),
        monitorStatus(DataUrl.areas.monitors.power, 'power')
      ])
    ]),
    Object.keys(DataUrl.areas.monitors).sort().forEach((key, i) => {
      sortedMonitor[key] = DataUrl.areas.monitors[key]
    }),
    DataUrl.areas.monitors = sortedMonitor,
    Object.keys(DataUrl.areas.monitors).map((key, index) => {

      let status = ''

      if (DataUrl.areas.monitors[key].value &&
        !['_id', 'hardwareId', 'unit', '__v', 'deviceId', 'inputSource', 'power'].includes(key)) {
        if (DataUrl.areas.monitors[key].value.toLowerCase() === 'on')
          status = '.online'
        else if (DataUrl.areas.monitors[key].value.toLowerCase() === 'off')
          status = '.offline'

        return m(`tr${status}`, [
          m('td', math.capitalize(key)),
          m('td', math.capitalize(DataUrl.areas.monitors[key].value))
        ])
      }
    }),
    Object.keys(DataUrl.areas.monitorevents).map(() => {
      return m('table.events', [
        m('tr', [
          m('td', { colspan: '2' }, [
            m('h1', 'Events')
          ])
        ]),
        m('th', 'Time'),
        m('th', 'Event'),
        Object.keys(DataUrl.areas.monitorevents.monitor).map((key, idx) => {
          if (idx > DataUrl.limit)
            return null

          const event = DataUrl.areas.monitorevents.monitor[key]

          let force = ''
          let action
          let restore = ''

          const time = math.convertTime(event.eventAt, DataUrl.localUTC, false)
          const date = math.convertDate(event.eventAt, false)

          if ('forcedValue' in event) {
            force = '.force'
            action = `Force: ${math.capitalize(event.key)} from ${event.value} to ${event.requestValue}`
          } else if ('requestValue' in event) {
            action = `Set: ${math.capitalize(event.key)} from ${event.value} to ${event.requestValue}`
          } else {
            action = `${math.capitalize(event.key)} changed to ${event.value}`
          }

          if ('restoreValue' in event)
            restore = '(Restore)'

          return m('tr', [
            m('td', [date, m('br'), time]),
            m(`td${force}`, `${action} ${restore}`)
          ])
        }),
        loadMore()
      ])
    })
  ])

  function loadMore() {
    if (DataUrl.areas.monitorevents.monitor.length > DataUrl.limit) {
      return m('tr', [
        m('td', { colspan: '2' }, [
          m('button', { onclick: () => {
            DataUrl.limit += 25
          } }, 'load more')
        ])
      ])
    }
  }
}

function deviceData() {
  return m('.content', [
    m('.monitors', [
      DataUrl.areas.monitors && DataUrl.areas.monitors !== 'monitors' ? monitorCondition()
        : m('h2', 'No monitorConditions'),

      DataUrl.areas.monitorschedules && DataUrl.areas.monitorschedules !== 'monitorschedules' ?
        monitorSchedulesView(false) : m('h2', 'No schedule')
    ])
  ])
}

export default {
  DataUrl,
  getDeviceData,
  postDeviceData,
  scheduleForCommand,
  deviceData,
  monitorSchedulesView
}
