import m from 'mithril'
import Guacamole from 'guacamole-common-js'
import keyboardLayout from 'guacamole-common-js/layouts/en-us-qwerty.json'
import Notification from '../models/notification'
import Guac from '../models/guac'
import Device from '../models/device'
import imageSupportTest from '../components/imageSupport'
import dialog from '../models/dialog'
import login from '../models/login'

const states = {
  idle          : 0,
  connecting    : 1,
  waiting       : 2,
  connected     : 3,
  disconnecting : 4,
  disconnected  : 5
}

const keycode = {
  c: 99,
  v: 118,
  x: 120,
  r: 114,
  ctrl: 65507,
  win: 65515,
  alt: 65513,
  tab: 65289,
  esc: 65307,
  shift: 65505,
  f1: 65470,
  f4: 65473,
  super: 65515
}

const stateNames = {
  0: 'Connecting',
  1: 'Connecting',
  2: 'Connecting',
  3: 'Connected',
  4: 'Disconnected',
  5: 'Disconnected'
}

const errorMessages = {
  512: 'Internal server error',
  513: 'Server is busy',
  514: 'MIB Not Responding',
  515: 'MIB Disconnected',
  516: 'Resource not found',
  517: 'Resource is already in use or locked',
  768: 'Bad request',
  769: 'Unauthorized',
  771: 'Not allowed access',
  776: 'Your browser is not responding - Try to refresh',
  781: 'Too much data sent',
  783: 'Unexpected or illegal data'
}

export default {
  controller: function(guac) {
    const ctrl = {
      clipboard: [],
      showKeyboard: false,
      connected: false,
      error: null,
      resize: true,
      status: 'starting',
      device: Device.get(guac.device),
      configGuacamole: function(el, initialized, ctx) {
        if (initialized)
          return setTimeout(ctx.adjustSize, 210)

        ctrl.guac = new Guacamole.Client(new Guacamole.WebSocketTunnel('wss://' + guac.server))

        let finished = false
        const display = ctrl.guac.getDisplay()
        const $display = display.getElement()

        $display.classList.add('display')

        el.appendChild($display)

        ctrl.guac.onerror = error => {
          if (error.code === 517) {
            ctrl.guac.disconnect()
            return setTimeout(() => ctrl.guac.connect('jwt=' + guac.jwt), 50)
          }

          ctrl.error = errorMessages[error.code]
          ctrl.connected = false

          m.redraw()
        }

        ctrl.guac.onstatechange = state => {
          ctrl.status = state
          ctrl.error = null
          ctrl.connected = state === states.connected

          if (state === states.connected && ctrl.device.type && ctrl.device.type() === 'beatplayer')
            ctrl.unlock()

          if (!finished && state === states.disconnected) {
            ctrl.disconnected = Date.now()
            const timer = setInterval(m.redraw, 1000)

            setTimeout(() => {
              clearInterval(timer)
              if (!finished && ctrl.status === states.disconnected)
                ctrl.guac.connect('jwt=' + guac.jwt)
            }, 10000)
          }

          m.redraw()
        }

        ctrl.guac.onclipboard = (stream, type) => {
          if (type !== 'text/plain')
            return stream.sendAck('Only text/plain supported', Guacamole.Status.Code.UNSUPPORTED)

          const reader = new Guacamole.StringReader(stream)
          let data = ''

          // Append any received data to buffer
          reader.ontext = (text) => {
            data += text
            stream.sendAck('Received', Guacamole.Status.Code.SUCCESS)
          }

          reader.onend = () => {
            window.prompt('Here\'s your clipboard data', data)
          }
        }

        ctx.adjustSize = () => {
          ctx.width = display.getWidth()
          ctx.height = display.getHeight()

          if (!ctrl.resize || (ctx.width < el.offsetWidth && ctx.height < el.offsetHeight))
            display.scale(1)
          else if (ctx.width / ctx.height > el.offsetWidth / el.offsetHeight)
            display.scale(el.offsetWidth / ctx.width)
          else
            display.scale(el.offsetHeight / ctx.height)

          if (ctrl.osk)
            ctrl.osk.resize(Math.min(el.offsetWidth, 1200))
        }

        $display.addEventListener('mousemove', e => {
          if (ctrl.resize)
            return $display.style.margin = ''

          const rect = el.getBoundingClientRect()
            , x = (rect.width - ctx.width) * ( ((e.pageX - rect.left) / rect.width) * 2.5 - 1.1)
            , y = (rect.height - ctx.height) * ( ((e.pageY - rect.top) / rect.height) * 2.5 - 1.1)

          $display.style.marginLeft = (ctx.height > ctx.width ? -1 : 1) * x + 'px'
          $display.style.marginTop = y + 'px'
        }, false)

        display.onresize = ctx.adjustSize

        const mouse = new Guacamole.Mouse($display)

        mouse.onmousedown = mouse.onmouseup = mouse.onmousemove = e => {
          ctrl.guac.sendMouseState(new Guacamole.Mouse.State(
            e.x / display.getScale(),
            e.y / display.getScale(),
            e.left, e.middle, e.right, e.up, e.down))
        }

        const keyboard = new Guacamole.Keyboard(el.parentNode)

        function globalKeyEvent(state, key) {
          if (!ctrl.connected || !ctrl.show)
            return true

          if (keyboard.modifiers.meta && key === keycode.c)
            return ctrl.sendKeys(keycode.ctrl, keycode.c) && true

          if ((keyboard.modifiers.ctrl || keyboard.modifiers.meta) && key === keycode.v)
            return true

          ctrl.guac.sendKeyEvent(state, key)
        }

        keyboard.onkeydown = globalKeyEvent.bind(guac, 1)
        keyboard.onkeyup = globalKeyEvent.bind(guac, 0)

        window.addEventListener('resize', ctx.adjustSize, false)

        ctx.onunload = () => {
          finished = true
          document.onpaste = null
          ctrl.guac.disconnect()
          delete ctrl.guac
          window.removeEventListener('resize', ctx.adjustSize, false)
        }

        imageSupportTest().then(result => {
          ctrl.guac.connect(
            'jwt=' + guac.jwt +
            Guacamole.VideoPlayer.getSupportedTypes().map(type => '&video=' + type).join('') +
            result.map(type => '&image=' + type).join('') +
            Guacamole.AudioPlayer.getSupportedTypes().map(type => '&audio=' + type).join('')
          )
        })
      },

      configOsk: function(el, initialized) {
        if (!initialized) {
          ctrl.osk = new Guacamole.OnScreenKeyboard(keyboardLayout)
          ctrl.osk.onkeydown = ctrl.guac.sendKeyEvent.bind(ctrl.guac, 1)
          ctrl.osk.onkeyup = ctrl.guac.sendKeyEvent.bind(ctrl.guac, 0)

          el.appendChild(ctrl.osk.getElement())
        }
      },

      promtAction: (btn, action) => {
        if (btn.classList.contains('disabled')) {
          btn.classList.remove('disabled')
          setTimeout(disable, 3000)
        } else {
          ctrl.sendKeys.apply(this, action)
          disable()
        }

        function disable() {
          btn.classList.add('disabled')
        }
      },

      sendKeys: function() {
        for (let i = 0; i < arguments.length; i++)
          ctrl.guac.sendKeyEvent(1, arguments[i])

        for (let i = 0; i < arguments.length; i++)
          ctrl.guac.sendKeyEvent(0, arguments[i])
      },

      close: function(e) {
        e.stopPropagation()
        Guac.remove(guac._id)
      },

      show: function() {
        const show = !guac.show

        if (show)
          Guac.get()().forEach(g => { g.show = false })

        guac.show = show
      },

      unlock: function() {
        m.request({
          method: 'GET', url: `${window.apiUrl}info`,
          config: function(xhr) {
            xhr.setRequestHeader('Authorization', `Bearer ${login.user().token}`)
          }
        }).then(data => {
          if (!data.lock)
            return

          ctrl.sendKeys.apply(this, [keycode.ctrl].concat(data.lock.split('').map(num => 57 - (9 - num))))

          Notification.info('BeatPlayer unlocked')
        })
      }
    }

    return ctrl
  },

  view: function(ctrl, guac) {
    const title = ctrl.device.hostname ? ctrl.device.hostname() : null
      , item = ctrl.device
      , nolocation = 'No location is attached to this device'

    let location
      , color

    const referenceTrim = item.location() && item.location().reference ? item.location().reference.trim() : null

    if (item.amuseicLocation) {
      const amuseicLoc = item.amuseicLocation().trim()

      if (amuseicLoc.startsWith(referenceTrim)) {
        location = amuseicLoc
      } else {
        location = `${referenceTrim || nolocation} / ${amuseicLoc}`
        color = '#ff8282'
      }
    } else if (referenceTrim) {
      location = referenceTrim

      if (item.type() !== 'beatplayer')
        color = 'yellow'
    }

    return m('div', [
      m('i.icon-desktop', {
        class: guac.show ? 'selected' : '',
        style: { display: 'inline-block', 'margin-left': '8px' },
        onclick: function(e) {
          if (e.target === this)
            ctrl.show()
        }
      }, [
        m('.triangle'),
        m('.popdown', [
          m('p', title),
          m('p.location', color ? { style: { color: color } } : '', location || nolocation),
          m('i.icon-cancel', {
            onclick: ctrl.close
          }),
          m('a.button', {
            href: '/devices/' + guac.device,
            onmouseup: () => guac.show = false,
            config: m.route
          }, 'show device'),
          m('a.button', {
            onclick: () => dialog.prompt({
              title: 'Reboot ' + title,
              description: 'Are you sure you want to reboot ' + title + '?',
              confirmText: 'reboot',
              confirm: function() {
                Device.request({
                  method: 'post',
                  background: true,
                  url: Device.urlRoot + guac.device + '/reboot'
                })
              }
            })
          }, 'reboot')
        ])
      ]),
      m('.guacamole', {
        class: (guac.show ? 'show' : '') + (ctrl.connected ? ' connected' : ''),
        onmouseenter: e => {
          e.target.tabIndex = '100'
          e.target.focus()
          document.onpaste = e => {
            ctrl.guac.setClipboard(e.clipboardData.getData('text/plain'))
            setTimeout(() => ctrl.sendKeys(keycode.ctrl, keycode.v), 100)
          }
        },
        onmouseleave: e => {
          document.onpaste = null
          e.target.tabIndex = '-1'
          setTimeout(() => e.target.blur(), 10)
        }
      }, [
        m('.status', [
          m('h1', ctrl.error || stateNames[ctrl.status]),
          ctrl.status === states.disconnected
            ? m('h2', 'Retrying in ' + (10 - Math.round((Date.now() - ctrl.disconnected) / 1000)))
            : null
        ]),
        m('.displayContainer', {
          config: ctrl.configGuacamole
        }),
        m('.keyboard', {
          class: ctrl.showKeyboard ? 'show' : null,
          config: ctrl.configOsk
        }, [
          m('.menu', [
            m('.button', {
              onclick: () => ctrl.sendKeys(keycode.ctrl, keycode.shift, keycode.esc)
            }, 'taskmgr'),
            m('.button', {
              onclick: () => ctrl.sendKeys(keycode.win, keycode.x)
            }, 'menu'),
            m('.button', {
              onclick: () => ctrl.sendKeys(keycode.win, keycode.r)
            }, 'run'),
            m('.button', {
              style: { backgroundImage: 'url("../../../images/win.svg")' },
              onclick: () => ctrl.sendKeys(keycode.win)
            }),
            m('.button', {
              style: { backgroundImage: 'url("../../../images/unlock.svg")' },
              onclick: ctrl.unlock
            }),
            m('span.toggle', title),
            m('span.toggleKeyboard', { onclick: () => ctrl.showKeyboard = !ctrl.showKeyboard }),
            m('span.toggle', color ? { style: { color: color } } : '', location || nolocation),
            m('.button.fr', {
              onclick: () => ctrl.sendKeys(keycode.super, keycode.tab)
            }, m('img', { style: { width: '20px' }, src: '../../../images/win.svg' }), ' +tab'),
            m('.button.fr', {
              onclick: () => ctrl.sendKeys(keycode.alt, keycode.tab)
            }, 'alt+tab'),
            m('.button.fr.disabled', {
              onclick: function() { ctrl.promtAction(this, [keycode.alt, keycode.f4]) }
            }, 'alt+f4'),
            m('.button.fr', {
              onclick: e => ctrl.sendKeys(keycode.f1)
            }, 'F1'),
            m('.button.icon-target.fr', {
              onclick: () => ctrl.resize = !ctrl.resize
            })
          ])
        ])
      ])
    ])
  }
}
