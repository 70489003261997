import m from 'mithril'
import math from '../helper/math'
import jsonTree from '../helper/jsonTree'
import Applications from '../models/applications'
import Notification from '../models/notification'
import login from '../models/login'

let auth

const DataUrl = {
  config: xhr => {
    xhr.setRequestHeader('Authorization', `Bearer ${auth}`)
  }
}

function post(id, type) {
  return m.request({
    method: 'POST',
    url: `https://${login.user().lifesupport}/updater/${type}`,
    config: DataUrl.config,
    data: { id: id }
  })
}

function getList() {
  return m.request({
    method: 'GET',
    url: `https://${login.user().lifesupport}/updater/list`,
    config: DataUrl.config
  })
}

export default {
  controller() {
    auth = login.user().token

    const ctrl = {
      devices: getList(),

      app: Applications.query({ conditions: { name: 'BeatUpdater' } }),

      sortProperty: m.prop(['hostname', 1]),

      header: sort => {
        return m('th', { onclick: () => {
          ctrl.sortProperty([sort[0], ctrl.sortProperty()[1] === 1 ? 0 : 1])
          ctrl.devices(ctrl.devices().sort(ctrl.sort))
        } }, sort[1] + (ctrl.sortProperty()[0] === sort[0] ? ctrl.sortProperty()[1] === 1 ? ' ↓' : ' ↑' : ''))
      },
      sort: (a, b) => {
        return ctrl.sortProperty()[1] === 1 ?
          String(math.deepValue(a, ctrl.sortProperty()[0])).localeCompare(math.deepValue(b, ctrl.sortProperty()[0])) :
          String(math.deepValue(b, ctrl.sortProperty()[0])).localeCompare(math.deepValue(a, ctrl.sortProperty()[0]))
      },

      renderInfo: (info, id) => {
        return m('td', m('a', { href: '/devices/' + id + '/updater', config: m.route }, info))
      },

      selectedLast: 0,

      select: (e, i) => {
        if (e.shiftKey)
          ctrl.devices().filter((v, y) => y < (i + 1) && y >= ctrl.selectedLast).map(v => v.selected = true)
        else
          ctrl.devices()[i].selected = !ctrl.devices()[i].selected

        ctrl.selectedLast = i
      },

      start: id =>
        post(id, 'add')
          .then(reponse.bind(null, id, 'start', 1)),

      pause: id =>
        post(id, 'remove')
          .then(reponse.bind(null, id, 'pause', 0)),

      abort: () => {
        const ids = ctrl.devices().filter(x => x.station).map(d => d.station._id)

        post(ids, 'remove')
          .then(data => {
            if (!data.ok)
              return Notification.error('Failed! Try again...', data)

            Notification.info('Removed updater from: ' + data.nModified + ' devices')
            ctrl.devices = getList()
          })
      },

      installLifeSupport: id => {
        m.request({
          method: 'POST',
          url: `${window.apiUrl}devices/${id}/installLifeSupport`,
          config: DataUrl.config
        }).then((data) => {
          Notification.info('Install started for device: ' + ctrl.devices().find(x => x._id === id).hostname +
            ' ...Please wait for install to finish.', JSON.stringify(data))
        }, reject => {
          Notification.error('Failed!', JSON.stringify(reject))
        })
      }
    }

    function reponse(id, type, switchValue, data) {
      if (!data.ok)
        return Notification.error('Failed! Try again...', data)

      const devices = ctrl.devices().filter(d => d.station && id.includes(d.station._id))

      devices.map(d => d.updaterExist = switchValue)

      Notification.info('BeatUpdater ' + (switchValue ? 'added' : 'removed'),
        'Devices: ' + devices.map(d => d.hostname))
    }

    return ctrl
  },

  view(ctrl) {
    const selected = ctrl.devices() ? ctrl.devices().filter(v => v.selected) : []

    return m('.content', [
      m('', [
        m('h1', 'BeatUpdater configuration'),
        ctrl.app()[0] ? jsonTree(ctrl.app()[0].config) : null,
        m('br'),
        selected.length > 0 ?
          m('div', [
            m('h2', 'Batch commands'),
            m('div', [
              m('span', { style: { fontWeight: 'bold' } }, 'Selected: ' + selected.length),
              m('button', { style: {
                float: 'initial',
                marginLeft: '20px'
              }, onclick: () => ctrl.start(selected.filter(x => x.station && x.updaterExist === 0).map(d => d.station._id))
              }, 'Play'),
              m('button', { style: {
                float: 'initial',
                marginLeft: '20px'
              }, onclick: () => ctrl.pause(selected.filter(x => x.station && x.updaterExist === 1).map(d => d.station._id))
              }, 'Pause')
            ]),
            m('br')
          ]) : null,
        m('button', {
          onclick: () => {
            ctrl.abort()
          } }, 'ABORT'),
        m('table.locations', { style: { userSelect: 'none' } }, [
          m('tr', [
            m('th', '#'),
            [['hostname', 'Hostname'],
              ['location.reference', 'Location'],
              ['progress', 'Progress'],
              ['os', 'OS'],
              ['station.lastConnected', 'Last Seen'],
              ['updaterExist', 'Play/Pause'],
              ['targetHardwareImage', 'Version'],
              ['type', 'Type']].map(ctrl.header)
          ]),
          ctrl.devices() ? [
            ctrl.devices()
              .map((item, i) => {
                return m('tr', {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: item.selected ? '#286e82' : null,
                    color: item.selected ? 'white' : null
                  }
                }, [
                  m('td', { onclick: e => ctrl.select(e, i) },
                    i + 1),
                  [[item.hostname], [item.location, 'reference'], [item.progress], [item.os]]
                    .map(x => ctrl.renderInfo(x[0] ? x[1] ? x[0][x[1]] : x[0] : '', item._id)),
                  item.station ?
                    m('td', item.station.status === 'connected' ?
                      m('div', { style: { borderRadius: '3px', margin: '5px', padding: '3px 5px', color: 'white', background: 'green' } },
                        'LS') :
                      math.convertDateTime(item.station.lastConnected)) : null,
                  m('td', m('div', {
                    onclick: () => {
                      if (!item.station)
                        ctrl.installLifeSupport(item._id)
                      else if (item.updaterExist)
                        ctrl.pause(item.station._id)
                      else
                        ctrl.start(item.station._id)
                    }
                  }, item.updaterExist ? 'Pause' : item.station ? 'Play' : 'Install LS')),
                  ctrl.renderInfo(item.targetHardwareImage, item._id),
                  ctrl.renderInfo(item.type, item._id)
                ])
              })
          ] : m('h4', 'Wait... Getting data...')
        ])
      ])
    ])
  }
}
