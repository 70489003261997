import { formatDistance } from 'date-fns'
import da from 'date-fns/locale/da'
import m from 'mithril'
import b from 'bss'
import { request } from '../api'

export default {
  controller: () => {
    const devices = m.prop()

    let sort = 'lastConnected'
      , reverse = 1

    fetch()

    return {
      devices,
      setSort
    }

    function setSort(c) {
      reverse = c === sort && reverse === 1 ? -1 : 1
      sort = c
      devices().sort((a, b) =>
        (a[c] < b[c] ? -1 : a[c] > b[c] ? 1 : 0) * reverse
      )
    }

    function fetch() {
      return request({
        background: true,
        method: 'POST',
        url: 'query/device',
        data: {
          type: 'aggregate',
          command: JSON.stringify([{
            $match: {
              modified: { $gt: 'ISODate(' + new Date(Date.now() - 1000 * 60 * 60 * 24 * 60) + ')' },
              ip: { $nin: ['85.204.133.170', '213.150.45.210'] }
            }
          }, {
            $lookup: {
              from: 'locations',
              localField: 'location',
              foreignField: '_id',
              as: 'location'
            }
          }, {
            $unwind: { path: '$location', preserveNullAndEmptyArrays: true }
          }, {
            $match: {
              'location.accounts.0': { $exists: false }
            }
          }, {
            $project: {
              _id: 1,
              hostname: 1,
              ip: 1,
              reference: '$location.reference',
              name: '$location.name',
              country: '$location.country.name',
              city: '$location.city'
            }
          }])
        }
      }).then(xs =>
        request({
          url: 'devices',
          background: true,
          data: {
            select: 'connected lastConnected',
            sort: '-modified',
            where: JSON.stringify({
              _id: { $in: xs.map(x => x._id) }
            })
          }
        }).then(online => {
          xs.forEach(x => Object.assign(x, online.find(o => o._id === x._id)))
          devices(xs)
          setSort('lastConnected')
        })
        .then(m.redraw)
      )
    }
  },

  view: (ctrl) => m('main' + b.p(20),
    m('h1', 'MIBs not bound to accounts'),
    m('table' + b
      .w('100%')
      .$nest(' td', b.lh('2em').p(2).pr(10).overflow('hidden').textOverflow('ellipsis').maxWidth(200).whiteSpace('nowrap'))
      .$nest(' tr:nth-child(odd)', b.bc('#ddd'))
      .$nest(' tr:hover', b.bc('#aaccee')),
    {
      cellpadding: 0,
      cellspacing: 0
    },
      m('tr' + b.tt('capitalize').fw('bold'), {
        onclick: (e) => ctrl.setSort(e.target.textContent)
      },
        m('td', '#'),
        m('td', 'hostname'),
        m('td', 'reference'),
        m('td', 'name'),
        m('td', 'country'),
        m('td', 'city'),
        m('td', 'ipCountry'),
        m('td', 'ipCity'),
        m('td', 'ipOrg'),
        m('td', 'lastConnected')
      ),
      !ctrl.devices()
        ? m('div' + b.mt(50).w('100%').ta('center'), 'loading')
        : ctrl.devices().map((d, i) => m('tr',
          m('td', i + 1),
          m('td', m('a' + b.td('underline !important'), { href: '/devices/' + d._id, config: m.route }, d.hostname)),
          m('td', d.reference),
          m('td', d.name),
          m('td', d.country),
          m('td', d.city),
          m('td', {
            onclick: () => {
              m.request({
                background: true,
                dataType: 'jsonp',
                url: 'https://www.iplocate.io/api/lookup/' + d.ip
              }).then(r => {
                d.ipCountry = r.country
                d.ipCity = r.city
                d.ipOrg = r.org
              }).then(m.redraw)
            }
          }, d.ipCountry),
          m('td', d.ipCity),
          m('td', d.ipOrg),
          m('td' + b.c(d.connected ? 'black' : 'white').bc(d.connected ? '#00ff99' : '#ff4444'), {
            title: d.lastConnected
          },
            d.connected ? 'online' : d.lastConnected ? formatDistance(new Date(d.lastConnected), new Date(), { locale: da }) : 'so long ago'
          )
        )
      )
    )
  )
}
