function convertSeconds(duration) {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - (hours * 3600)) / 60)
  const seconds = duration - (hours * 3600) - (minutes * 60)

  return [hours, minutes, seconds]
}

function convertToSeconds(duration) {
  const hours = Number(duration[0]) * 3600
  const minutes = Number(duration[1]) * 60
  const seconds = Number(duration[2])

  return [hours + minutes + seconds]
}

function capitalize(string) {
  return string.substring(0, 1).toUpperCase() + string.substring(1)
}

function convertTime(time, utcOffset, toArray) {
  time = time.split('T')

  if (!time[1])
    return time[0]

  time = time[1].split(':')

  let hourConverted = time[0]

  if (utcOffset) {
    if (time[0].charAt(0) === '0')
      hourConverted = Number(time[0].charAt(1)) - Number(utcOffset)
    else
      hourConverted = Number(time[0]) - Number(utcOffset)

    if (hourConverted > 23)
      hourConverted = '00'
  }

  if (toArray)
    return [hourConverted, time[1], time[2].split('.')[0]]

  return `${hourConverted}:${time[1]}:${time[2].split('.')[0]}`
}

function convertDate(date, toArray) {
  date = date.split('T')

  if (!date[1])
    return date[0]

  if (toArray) {
    date = date[0].split('-')
    return [date[1], date[2], date[0]]
  }

  return new Date(date).toLocaleDateString('da-DK', { year:'numeric', month:'2-digit', day:'2-digit' })
}

function convertDateTime(val) {
  return new Date(val).toLocaleString('da-DK', {
    year:'numeric', month:'2-digit', day:'2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'
  }).replace(/\./g, ':')
}

function convertDateTimeUTC(val, utc) {
  return `${convertDate(val)} - ${convertTime(val, utc)}`
}

function convertDateBack(date) {
  return `${date[2]}-${date[0]}-${date[1]}`
}

function convertTimeBack(time) {
  return `T${time[0]}:${time[1]}:${time[2]}.000Z`
}

function twoDigit(val) {
  if (val.toString().length === 1)
    return `0${val}`

  return val
}

function sort(arr) {
  arr.sort((a, b) => {
    return a - b
  })

  return arr
}

function push(arr, limit) {
  for (let i = 0; i < limit + 1; i++)
    arr.push(i)

  return arr
}

function pushEmpty(arr) {
  if (arr[0] !== '')
    arr.unshift('')

  return arr
}

function covertToByteArray(str) {
  const arrayBuffer = []

  for (let i = 0; i < str.length; ++i)
    arrayBuffer.push(str.charCodeAt(i))

  return arrayBuffer
}

function addWorkDays(startingDate, daysToAdjust) {
  const newDate = new Date(startingDate.valueOf())

  let businessDaysLeft = null
    , isWeekend = null
    , direction = null

  direction = daysToAdjust > 0 ? 1 : -1

  businessDaysLeft = Math.abs(daysToAdjust)
  while (businessDaysLeft) {
    newDate.setDate(newDate.getDate() + direction)
    isWeekend = newDate.getDay() in { 0: 'Sunday', 6: 'Saturday' }

    if (!isWeekend)
      businessDaysLeft--
  }

  return newDate
}

function deepValue(obj, is, value) {
  if (typeof is === 'string')
    return deepValue(obj, is.split('.'), value)
  else if (is.length === 1 && value !== undefined)
    return obj[is[0]] = value
  else if (is.length === 0)
    return obj
  return obj[is[0]] ? deepValue(obj[is[0]], is.slice(1), value) : ''
}

function getLastArrayValue(val) {
  return Array.isArray(val) ? val[val.length - 1] : val
}

export default {
  convertTime,
  convertTimeBack,
  convertDate,
  convertDateBack,
  convertDateTime,
  convertDateTimeUTC,
  convertSeconds,
  convertToSeconds,
  twoDigit,
  capitalize,
  sort,
  push,
  pushEmpty,
  covertToByteArray,
  addWorkDays,
  deepValue,
  getLastArrayValue
}
