import m from 'mithril'

import Device from '../models/device'
import navigate from '../models/ajaxNavigate'
import math from '../helper/math'
import filter from '../components/filter'

const sortBy = m.prop('lastConnected')

export default {

  controller(args) {
    const ctrl = navigate({
      lists: [{ model: Device,
        opts: {
          select: 'id lifelineId hardwareId hostname type lifelineVersion connected lastConnected connectedMediabutler ip os windowsUpdates location',
          sort: 'hostname', populate: JSON.stringify({ path: 'location', select: 'reference country reminders openings' }), limit: 50 } }],
      filters: ['organizations', 'partner', 'country.name', 'folder', 'os', 'city', 'outlookInfo.categories',
        { name: 'ip', prefix: 'local', type: 'button',
          text: ['Any place', 'Beat HQ'], value: ['', '85.204.133.170'], urlValue: ['false', 'true'], title: 'Last seen at:' },
        { name: 'location', prefix: 'location', type: 'button', urlValue: ['true', 'false'],
          text: ['All', 'No location attached'], value: ['', { $exists: false }], title: 'Location:' },
        'lsApp'
      ],
      root: '/devices',
      downloadConditions: {
        select: 'id lifelineId hardwareId hardwareModel hostname lifelineVersion connected lastConnected connectedMediabutler amuseicLocation ip os windowsUpdates location country LSApps Multicast volumes',
        default: 'hostname connected lastConnected amuseicLocation location country',
        populate: JSON.stringify({ path: 'location', select: 'reference country' }),
        limit: 10000 },
      pageControls: { create: false, download: true }
    })

    return ctrl
  },

  view(ctrl, args) {
    return [filter.render(ctrl),
      m('.content', [
        m('', [
          ctrl.controls(),
          m('table.locations', [
            m('tr', [
              [{ title: 'Hostname', path: 'hostname' },
                { title: 'Location', path: 'location.reference' },
                '',
                '',
                { title: 'Last Connected', path: 'lastConnected' },
                { title: 'LL version', path: 'lifelineVersion' },
                { title: 'Opening date', path: 'location.openings' }]
                .map(x =>
                  m('th',
                    {
                      onclick: () => {
                        if (typeof x === 'object')
                          sortBy(x.path)

                      }
                    }, x.title || x, sortBy() === x.path ? ' ↓' : '')
                )
            ]),
            ctrl.returnList().data().sort((a, b) => {
              const inverseSort = ['hostname', 'location.reference'].includes(sortBy())
                , sort1 = math.getLastArrayValue(math.deepValue(inverseSort ? a : b, sortBy()))
                , sort2 = math.getLastArrayValue(math.deepValue(inverseSort ? b : a, sortBy()))

              return String(sort1).localeCompare(sort2)
            }).map((item, i) => {
              if (i === ctrl.limit)
                return null

              const connected = Device.types[item.type] === 'BP' ? '#585858' :
                  item.connectedMediabutler ? item.ip === '85.204.133.170' ? 'purple' : 'green' : 'red'

                , openingDate = item.location && item.location.openings[item.location.openings.length - 1]

              return m('tr', {
                style: { cursor: 'pointer' },
                onclick: () => { ctrl.route(item._id) }
              }, [
                m('td', m('h3', item.hostname ? item.hostname : '')),
                m('td', m('h3', item.location ? item.location.reference : '')),
                m('td', m('small', { style: { borderRadius: '3px', margin: '5px', padding: '3px 5px', color: 'white',
                  background: item.connected ? item.ip === '85.204.133.170' ? 'purple' : 'green' : 'red' }
                }, 'Lifeline')),
                m('td', m('small', { style: { borderRadius: '3px', margin: '5px', padding: '3px 5px', color: 'white',
                  background: connected } }, math.capitalize(item.type))),
                m('td', {
                  style: {
                    color: 'black' }
                }, item.lastConnected && !item.connected && !item.connectedMediabutler ?
                  math.convertDateTime(item.lastConnected) : ''),
                m('td', m('h4', item.lifelineVersion ? item.lifelineVersion : '')),
                m('td', m('h4', math.convertDateTime(openingDate)))
              ])
            })
          ])
        ]),
        ctrl.returnList().data().length > 0 ? ctrl.controls() : null
      ])]
  }
}
