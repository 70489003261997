import m from 'mithril'
import Partner from '../models/partner'
import NewPartner from '../models/newPartner'
import navigate from '../models/ajaxNavigate'
import dialog from '../models/dialog'
import itemView from './partner'

export default {

  controller() {
    const id = m.route.param('id') || ''

    const ctrl = navigate({
      id: id,

      lists: [{
        model: Partner,
        opts: { limit: 30 }
      }],

      root: '/partners',
      changed: m.prop(null)
    })

    if (id) {
      if (id === 'new') {
        ctrl.item = NewPartner.create()

        ctrl.item.on('created', i => {
          ctrl.route(i._id())
        })
      } else {
        ctrl.item = Partner.get(id)
      }

      ctrl.item.on('deleted', () => ctrl.route())

      ctrl.createUI = () => {
        ctrl.item.syntax =
        [0, 1, 2].map(i => {
          return (ctrl.item.includes &&
              ctrl.item.includes().syntax &&
              ctrl.item.includes().syntax[i] &&
              ctrl.item.includes().syntax[i]
          ) || {}
        })

        if (!ctrl.item.incl)
          ctrl.item.incl = {}

        ctrl.item.incl.group = (ctrl.item.includes && ctrl.item.includes().group) || []
      }

      ctrl.item.update = () => {
        Partner.push(id, {
          name: ctrl.item.name(),
          includes: ctrl.includes()
        })
          .then(res =>
            res.name && res.name === ctrl.item.name() && location.reload()
          )
      }

      ctrl.item.create = () => {
        ctrl.item.includes(ctrl.includes())

        ctrl.item.save()
      }

      ctrl.includes = () => {
        return {
          syntax:
              ctrl.item.syntax
                .filter(x =>
                  Object.keys(x).length > 0
                )
                .filter(x =>
                  Object.entries(x).every(x =>
                    x[1] !== ''
                  )
                )
                .map(x =>
                  x
                ),

          group: ctrl.item.incl.group
        }
      }

      ctrl.remove = item => {
        item.key = item._id()

        dialog.prompt({
          title: `Delete ${item.name()}?`,
          description: 'Are you sure you wish to delete ' + item.name() +
                       '. \nYou will not be able to restore after having deleted it.',
          confirmText: 'delete',
          confirm: item.remove
        })
      }
    }

    return ctrl
  },

  view(ctrl) {
    return m(ctrl.item ? '#organizations.columns' : '.content', { class: ctrl.item ? 'three' : '' }, [
      m.route.param('id') ?
        m('.raised.detail', {
          class: ctrl.item ? 'show' : ''
        }, ctrl.item ? itemView(ctrl, { Model: Partner }) : '') :

        m('', [
          m('h1', [
            m('span', 'Partners'),
            ctrl.controls()
          ]),
          m('table.locations', [
            m('tr', [
              m('th', 'Name'),
              m('th', 'Address'),
              m('th', 'AccountingId'),
              m('th', 'Country'),
              m('th', 'Group')
            ]),
            ctrl.returnList().data().map((item, i) => {
              if (i === ctrl.limit)
                return null

              return m('tr', {
                style: { cursor: 'pointer' },

                onclick: () => {
                  ctrl.route(item._id)
                }
              }, [
                m('td', m('h3', item.name || '')),
                m('td', m('h3', item.address || '')),
                m('td', m('h3', item.accountingId || '')),
                m('td', m('h3', item.country || '')),
                m('td', m('h3', item.group || ''))
              ])
            })
          ])
        ])
    ])
  }
}
