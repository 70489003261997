import api from '../api'

export default api.resource({
  path    : 'c5accounts',
  autosave : false,
  schema  : {
    subscription   : String,
    accountingId   : String,
    accountingRow  : Number,
    name           : String,
    searchName     : String,
    address        : String,
    group          : String,
    invoiceAccount : String
  }
})
