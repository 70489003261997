import api from '../api'

const Media = api.resource({
  path    : 'media',
  schema  : {
    title             : String,
    filename          : String,
    type              : String,
    file              : Object,
    thumb             : String,
    localModifiedDate : String,
    tags              : Array,
    assemblyId        : String,
    status            : String,
    uploader          : String
  }
})

Media.statuses = {
  REQUEST_ABORTED: 'Upload was interrupted',
  ASSEMBLY_UPLOADING: 'Currently uploading',
  ASSEMBLY_EXECUTING: 'Processing',
  ASSEMBLY_CANCELED: 'Cancelled',
  ASSEMBLY_COMPLETED: 'Ready',
  MAX_SIZE_EXCEEDED: 'Uploaded file too large'
}

export default Media
