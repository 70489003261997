import m from 'mithril'
import Location from '../models/location'
import location from '../components/location'
import navigate from '../models/ajaxNavigate'
import filter from '../components/filter'

export default {

  controller() {
    const id = m.route.param('id') || ''

    const ctrl = navigate({
      id: id,
      lists: [{ model: Location, opts: { sort: 'name', limit: 15 } }],
      filters: ['organizations', 'partner', 'country.name', 'folder', 'city', 'outlookInfo.categories', 'notesText'],
      root: '/locations',
      downloadConditions: {
        select: '_id name reference country city zip folder notesText',
        limit: 7000 },
      pageControls: { create: false, download: true }
    })

    if (id === 'new') {
      location.loadLocation()
      ctrl.item = true
    } else if (id) {
      location.loadLocation(id)
      ctrl.item = true
    }

    return ctrl
  },

  view(ctrl) {
    return ctrl.item ? m('.content', location.deviceData()) : [
      filter.render(ctrl),
      m('.content', [
        m('', [
          m('h1', [
            m('span', 'Locations'),
            ctrl.controls()
          ]),
          m('table.locations', [
            m('tr', [
              m('th', 'Name'),
              m('th', 'Contact'),
              m('th', 'Adress'),
              m('th', 'Reference')
            ]),
            ctrl.lists[0].data.data().map((item, i) => {
              return m('tr', {
                style: { cursor: 'pointer' },
                onclick: () => { ctrl.route(item._id) }
              }, [
                m('td', m('h3', item.name || '')),
                m('td', item.contact ? m('h3', item.contact) : ''),
                m('td', m('tr', [
                  item.street ? m('h3', item.street) : null,
                  m('h3', `${item.zip || ''} ${item.city || ''}${item.state || ''}`),
                  item.country ? m('h3', item.country) : null
                ])),
                m('td', m('h3', item.reference || ''))
              ])
            })
          ]),
          ctrl.lists[0].data.data().length > 0 ? ctrl.controls() : null
        ])]
      )]
  }
}
