import m from 'mithril'
import Location from '../models/location'
import Reminders from '../models/reminders'
import login from '../models/login'
import dialog from '../models/dialog'
import navigate from '../models/ajaxNavigate'
import math from '../helper/math'
import location from '../components/location'
import filter from '../components/filter'

export default {

  controller() {
    const id = m.route.param('id') || ''

    const ctrl = navigate({
      id: id,
      lists: [{ model: Reminders, opts: { limit: 20 } }],
      filters: ['folder', 'outlookInfo.categories', 'notesText',
        { name: 'status', prefix: 'closed', useDefault: true,
          type: 'button', text: ['open', 'closed'], value: ['open', 'closed'], title: 'Status:' }],
      root: '/shippings',
      itemRoot: '/locations',
      pageControls: { create: false, download: false }
    })

    return ctrl
  },

  view(ctrl) {
    const openStatus = filter.returnFilter('status', ctrl.filters).getValue()
      , openStatusBool = openStatus !== 'open'

    return ctrl.item ? m('.content', location.deviceData()) : [
      filter.render(ctrl),
      m('.content', [
        m('', [
          m('h1', [
            m('span', 'Shippings'),
            ctrl.controls()
          ]),
          m('table.locations', { style: { width: '100%' } }, [
            m('tr', [
              m('th', 'Status'),
              m('th', 'Reference'),
              m('th', 'Adress'),
              m('th', 'Name'),
              m('th', 'Delivery date'),
              m('th', openStatusBool ? 'Closed date' : 'Reminder date'),
              m('th', 'Shipping note'),
              m('th', openStatusBool ? 'Closed by' : 'Assigned to')
            ]),
            ctrl.lists[0].data.data().map((item, i) => {
              const reminder = item.reminders
                , index = reminder.index
                , date = openStatusBool ? reminder.closed && math.convertDate(reminder.closed) : reminder.reminder && math.convertDate(reminder.reminder)
                , overdue = new Date(new Date(openStatusBool ? reminder.closed : reminder.reminder)) < new Date()
                , user = JSON.parse(atob(login.user().token.split('.')[1])).user
                , assignedToYou = reminder.assignedTo ? reminder.assignedTo._id === user : false

              return m('tr', {
                style: { cursor: 'pointer' },
                onclick: e => {
                  if (e.target.getAttribute('data') === 'assignedTo') {
                    dialog.prompt({
                      title: assignedToYou ? 'Remove assignment' : 'ASSIGN',
                      description: `Are you sure you want to ${assignedToYou ?
                        'remove' : 'assign'} <b>${item.name}</b> shipping <b>${date}</b> ${
                        assignedToYou ? 'assignment from yourself?' : 'to yourself?'}`,
                      confirmText: assignedToYou ? 'remove assignment' : 'assign',
                      confirm: () => {
                        const setfield = `reminders.${index}.assignedTo`

                        if (assignedToYou) {
                          Location.push(item._id, { $unset: setfield }).then((res) => {
                            ctrl.getList('locations')
                          })
                        } else {
                          Location.push(item._id, { [setfield]: user }).then(() => ctrl.getList('locations'))
                        }

                      } })
                  } else if (e.target.getAttribute('data') === 'status') {
                    return
                  } else {
                    ctrl.route(item._id)
                  }
                }
              }, [
                m('td', { data: 'status', style: { backgroundColor: overdue ? '#fb3b3b' :
                  reminder.status === 'active' ? '#effb3b' : '',
                color: overdue ? '#eaeaea' : '', textAlign: 'center', width: '100px' } },

                m('a.round.button.raised', { data: 'status', style: { width: 'initial', float: 'inherit',
                  background: 'white', color: 'black' }, onclick: () => {
                  const value = openStatusBool ? 'open' : 'closed'
                    , title = openStatusBool ? 'Open' : 'Close'
                    , action = openStatusBool ? 're-open' : 'close'
                    , action2 = openStatusBool ? 'which was closed ' : ''

                  dialog.prompt({
                    title: title + ' reminder',
                    description: 'Are you sure you want to ' + action + ' <b>' + item.reference +
                        '</b> reminder ' + action2 + '<b>' + date + '?',
                    confirmText: openStatusBool ? 're-open' : 'close',
                    confirm: () => {
                      const setfieldStatus = `reminders.${index}.status`
                        , setfieldClosed = `reminders.${index}.closed`
                        , setfieldclosedBy = `reminders.${index}.closedBy`

                      Location.push(item._id, { [setfieldStatus]: value,
                        [setfieldClosed]: openStatusBool ? undefined : new Date(),
                        [setfieldclosedBy]: openStatusBool ? undefined : user })
                        .then(() => ctrl.getList('locations'))
                    } })
                } }, openStatus)
                ),
                m('td',
                  m('a', { href: '/locations/' + item._id, config: m.route },
                    item.reference
                  )),
                m('td',
                  m('tr', [
                    item.street && m('div', item.street),
                    m('div', `${item.zip || ''} ${item.city || ''} ${item.state || ''}`),
                    item.country && m('div', item.country)
                  ])),

                m('td', item.name),
                m('td', reminder.date && math.convertDate(reminder.date)),
                m('td', date),
                m('td', reminder.text),
                openStatusBool ?
                  m('td', reminder.closedBy && reminder.closedBy.name) :
                  m('td', { data: 'assignedTo' }, reminder.assignedTo ?
                    m('h3', { data: 'assignedTo', onmouseover: function() {
                      this.style.textDecoration = 'line-through'
                    }, onmouseout: function() {
                      this.style.textDecoration = ''
                    } }, reminder.assignedTo.name) :
                    m('img', { data: 'assignedTo', style: { cursor: 'pointer' }, width: '30px', src: '../../../images/assign.svg' }))
              ])
            })
          ]),
          ctrl.lists[0].data.data().length > 0 ? ctrl.controls() : null
        ])]
      )]
  }
}
