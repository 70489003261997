import m from 'mithril'
import login from '../models/login'
import Notification from '../models/notification'
import math from '../helper/math'
import select from '../components/select'

import dynamicLoad from '../helper/dynamicLoad'

const collections = ['station', 'transaction', 'device', 'c5account', 'location']
  , queryTypes = ['find', 'aggregate']

const head = document.getElementsByTagName('head')[0]
  , includeCode = '/js/code/lib/codemirror.js'

const jsss = [
  'addon/lint/lint.js',
  'addon/lint/json-lint.js',
  'addon/lint/jsonlint.js',
  'mode/javascript/javascript.js'
]

if (head.innerHTML.indexOf(includeCode) === -1) {
  const tag = document.createElement('script')

  tag.src = includeCode
  head.appendChild(tag)

  tag.onload = () => dynamicLoad.loadJS(jsss)
} else {
  dynamicLoad.loadJS(jsss)
}

dynamicLoad.loadCSS(['lib/codemirror.css', 'addon/lint/lint.css'])

function getParams() {
  const url = decodeURIComponent(window.location.hash)
    , firstParam = url.split('#')[1]

  if (!firstParam)
    return null

  return firstParam.split('&').reduce((acc, cur, i) => {
    const params = cur.split('=')

    acc[params[0]] = params.slice(1, params.length).join('=')
    return acc
  }, {})
}

function getMatch(params, type, alternate, match) {
  return m.prop(params ?
    match ?
      match.includes(params[type]) ?
        params[type] : alternate
      : (params[type] || alternate)
    : alternate)
}

function makeURL(col, t, s, com) {
  return ['collection', 'type', 'select', 'command', 'format', 'title'].reduce((acc, cur, i) => {
    if (!arguments[i])
      return acc

    const next = '&' + cur + '=' + arguments[i]

    if (i === 1)
      return acc += '=' + arguments[i - 1] + next

    return acc += next
  })
}

export default {
  controller() {
    const params = getParams()

    const ctrl = {
      collection: getMatch(params, 'collection', 'station', collections),
      type: getMatch(params, 'type', 'find', queryTypes),

      select: getMatch(params, 'select', null),
      selectArray: () => ctrl.select().replace(/\n/g, ' ').split(' '),

      command: getMatch(params, 'command', null),
      format: getMatch(params, 'format'),
      title: getMatch(params, 'title', 'queryResults'),

      formating: m.prop(false),
      results: m.prop(),
      makeHash: () =>
        window.location.hash =
          encodeURIComponent(
            makeURL(
              ctrl.collection(),
              ctrl.type(),
              ctrl.select(),
              ctrl.command(),
              ctrl.format(),
              ctrl.title()
            )
          ),

      query: download => {
        if (!ctrl.command())
          return Notification.error('Error', 'No command written!')

        if (!ctrl.select())
          return Notification.error('Error', 'Must select at least one field')

        ctrl.makeHash()

        m.request({
          method: 'POST', url: `${window.apiUrl}query/${ctrl.collection()}`,
          config: xhr => xhr.setRequestHeader('Authorization', `Bearer ${login.user().token}`),
          data: {
            type: ctrl.type(),
            select: JSON.stringify(ctrl.selectArray().reduce((acc, cur, i) => {
              acc[cur] = 1
              return acc
            }, {})),
            command: ctrl.command()
          }
        }).then(results => {
          const A = [[ctrl.selectArray()]]

          Object.keys(results).forEach((val, i) => {
            const arrayPos = i + 1

            A[arrayPos] = [new Array(A[0][0].length)]

            Object.keys(results[val]).forEach((res, pos) => {
              A[0][0].forEach((header, location) => {
                if (header.indexOf('.') > 0)
                  return A[arrayPos][0][location] = math.deepValue(results[val], header)

                if (header === res) {
                  A[arrayPos][0][location] =
                    typeof results[val][res] === 'object' ? JSON.stringify(results[val][res]) : results[val][res]
                }
              })
            })
          })
          return A
        }).then(A => {
          if (!download)
            return ctrl.results(A)

          const csvRows = []

          A.forEach((row, i) => {
            let rowValues

            row.forEach(value => {
              if (i === 0) {
                rowValues = value.map(val => {
                  return ctrl.getFormattedTitle(val) || val
                }).join('\t')
              } else {
                rowValues = value.join('\t')
              }
            })

            csvRows.push(rowValues)
          })

          const text = new TextEncoder('windows-1252', { NONSTANDARD_allowLegacyEncoding: true })
            .encode(csvRows.join('\n'))

          const link = document.createElement('a')
            , blob = new Blob([text], { type: 'attachment/xls' })

            , URL = window.URL || window.webkitURL
            , downloadUrl = URL.createObjectURL(blob)

          link.setAttribute('href', downloadUrl)
          document.body.append(link)

          link.download = ctrl.title() + '.xls'
          link.click()
          document.body.removeChild(link)
        }).catch(e => Notification.error('Error', e.message))
      }
    }

    ctrl.saveFormat = () => {
      let changed

      ctrl.selectArray().forEach(f => {
        const value = document.querySelector(['input[name="' + f + '"]']).value.trim()

        if (value !== '') {
          if (!ctrl.format())
            ctrl.format(JSON.stringify({}))

          const newVal = JSON.parse(ctrl.format())

          newVal[f] = value
          changed = true

          ctrl.format(JSON.stringify(newVal))
        } else if (ctrl.format()) {
          const newVal = JSON.parse(ctrl.format())

          if (newVal[f])
            delete newVal[f]

          changed = true
          ctrl.format(JSON.stringify(newVal))
        }
      })

      if (changed)
        ctrl.makeHash()
    }

    ctrl.getFormattedTitle = name => {
      if (!ctrl.format())
        return

      const value = Object.entries(JSON.parse(ctrl.format())).find(([key, value]) => key === name)

      return value ? value[1] : null
    }

    if (params && params.execute)
      params.execute === 'execute' ? ctrl.query(false) : ctrl.query(true)

    return ctrl
  },

  view(ctrl) {
    return m('.content', [
      m('div', [
        m('span', 'Title:'),
        m('input', {
          value: ctrl.title(),
          style: {
            width: '300px',
            marginLeft: '10px',
            marginBottom: '30px'
          },

          oninput: m.withAttr('value', value =>
            ctrl.title(value.replace(/&/g, ''))
          )
        })
      ]),
      m('div', [
        m('span', 'Collection:'),
        m(select, {
          map: collections,
          selected: ctrl.collection(),
          onchange: m.withAttr('value', ctrl.collection)
        })
      ]),
      m('br'),
      m('div', [
        m('span', 'Command Type:'),
        m(select, {
          map: queryTypes,
          selected: ctrl.type(),
          onchange: m.withAttr('value', ctrl.type)
        })
      ]),
      m('br'),
      m('div', [
        m('span', 'Select:'),
        m('textarea', {
          value: ctrl.select(),
          style: { width: '74vw', height: '35px', marginLeft: '10px' },
          onchange: m.withAttr('value', ctrl.select)
        })
      ]),
      m('br'),
      m('div', [
        m('div', 'Command:'),
        m('textarea', {
          config: (element, init) => {
            function create() {
              if (typeof CodeMirror === 'undefined' || !window.jsonlint)
                return setTimeout(create, 100)

              CodeMirror
                .fromTextArea(element, {
                  mode: 'application/json',
                  lineNumbers: true,
                  gutters: ['CodeMirror-lint-markers'],
                  lint: true
                })
                .on('change', e =>
                  ctrl.command(e.getValue())
                )
            }

            if (!init)
              create()
          },

          value: ctrl.command(),
          style: { width: '80vw', height: '30vh' }
        })
      ]),
      m('button', { style: { float: 'left' }, onclick: ctrl.query.bind(null, false) }, 'Execute'),
      m('button', { style: { marginLeft: '10px', float: 'left' }, onclick: ctrl.query }, 'Execute & Download'),
      ctrl.results() ? [
        m('button', {
          style: { float: 'left', marginLeft: '10px' },
          onclick: () => {
            if (ctrl.formating())
              ctrl.saveFormat()

            ctrl.formating(!ctrl.formating())
          }
        }, ctrl.formating() ? 'Save Format' : 'Format'),
        m('br'),
        m('br'),
        m('br'),
        m('table.locations', [
          m('tr', ctrl.results()[0][0].map((v, i) => {
            const name = ctrl.selectArray()[i]

            return m('th',
              ctrl.formating() ?
                m('input', {
                  name,
                  value: ctrl.getFormattedTitle(name) || ''
                }) : null, ctrl.getFormattedTitle(name) || v)
          })),
          ctrl.results().map((v, i) => {
            if (i > 0) {
              for (let i = 0; i < v[0].length; i++)
                v[0][i] ? null : v[0][i] = ''

              return m('tr', [
                v[0].map((x, i) => {
                  return m('td', x)
                })
              ])
            }
          })
        ])] : null
    ])
  }
}
