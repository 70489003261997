import m from 'mithril'
import textfield from '../components/input'

export default (ctrl, args) => {
  const item = ctrl.item

  return item._id || m.route.param('id') === 'new' ? [
    m('h1', item._id ? '' : 'New organization'),

    m('h1',
    item._id ? m('button.raised.danger', { onclick: ctrl.remove.bind(null, item) }, 'delete')
             : m('button.raised', { onclick: item.save }, 'create'),

    m('button.raised', { style: { 'margin-right': '10px', 'margin-left': '5px' }, onclick: ctrl.route }, 'cancel'),

    item._id ? m('button.raised', { onclick: item.save }, 'save') : null),

    m('fieldset', [
      m(textfield, { name: 'name', value: item.name }),
      m(textfield, { name: 'from account', value: item.fromAccount }),
      m(textfield, { name: 'phone', value: item.phone }),
      m(textfield, { name: 'email', value: item.email }),

      [m('div.input.tags.filled', { style: { 'border-bottom': 'none' } },
        ctrl.locations().length === 0 ? 'No locations are part of this organization ' :
          'Locations: ',

          m('ul', [
            ctrl.locations().map(location => {
              return m('div', m('li.raised', { style: { cursor: 'pointer', 'max-width': '500px' }, onclick: () => {
                m.route(`/locations/${location._id}`)
              } }, location.name + ', ' + location.reference))
            })
          ])
      )],
      m('div', { style: { 'padding-top': '15px' } }, ctrl.changed())
    ])

  ] : m('h1', 'Location ' + m.route.param('id') + ' not found')
}
