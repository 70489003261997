export default {
  loadJS: jsss => {
    const head = document.getElementsByTagName('head')[0]

    jsss.forEach(js => {
      const path = '/js/code/' + js

      if (head.innerHTML.indexOf(path) === -1) {
        const tag = document.createElement('script')

        tag.src = path
        head.appendChild(tag)
      }
    })
  },

  loadCSS: csss => {
    const head = document.getElementsByTagName('head')[0]

    csss.forEach(css => {
      const path = '/js/code/' + css

      if (document.getElementsByTagName('head')[0].innerHTML.indexOf(css) === -1) {
        const style = document.createElement('link')

        style.href = window.location.origin + path
        style.rel = 'stylesheet'
        style.type = 'text/css'

        head.appendChild(style)
      }
    })
  }
}
