import m from 'mithril'
import login from '../models/login'
import List from '../models/list'
import Notification from '../models/notification'

export default {

  controller() {
    const ctrl = {
      token: m.request({
        method: 'GET', url: `${window.apiUrl}info`,
        config: function(xhr) {
          xhr.setRequestHeader('Authorization', `Bearer ${login.user().token}`)
        }
      }).then((data) => { ctrl.token = data.lock }),
      folder: List.query({ conditions: { type: 'folders' } }),
      addFolder: m.prop(),
      newFolder: m.prop(),
      removeFolder: m.prop(),
      removeOldFolder: m.prop(),
      createFolder: () => {
        List.request({
          url: 'lists/folders',
          method: 'PUT',
          background: true,
          config: xhr => ctrl.xhr = xhr,
          data: { data: ctrl.newFolder() }
        }).then(data => Notification.info(data.nModified ? 'Folder is added' : 'error')).then(m.redraw)
      },
      deleteFolder: () => {
        List.request({
          url: 'lists/folders',
          method: 'DELETE',
          background: true,
          config: xhr => ctrl.xhr = xhr,
          data: { data: ctrl.removeOldFolder() }
        }).then(data => Notification.info(data.nModified ? 'Folder has been removed' : 'No folder with this name found')).then(m.redraw)
      }
    }

    return ctrl
  },

  view(ctrl) {
    return m('.content', [
      m('h3', 'Unlock token: ' + ctrl.token),
      ctrl.addFolder() ? null :
      m('div',
        m('button', { style: { float: 'initial' }, onclick: () => {
          ctrl.addFolder(true)
        } }, 'Add new folder')
      ),
      ctrl.removeFolder() ? null :
      m('div',
        m('button', { style: { float: 'initial', marginTop: '20px' }, onclick: () => {
          ctrl.removeFolder(true)
        } }, 'Remove old folder')
      ),
      ctrl.addFolder() ? [
        m('div',
          m('input', { onchange: m.withAttr('value', ctrl.newFolder) }),
          m('button', { style: { float: 'initial', marginLeft: '10px' }, onclick: () => {
            ctrl.createFolder()
          } }, 'Add folder')
        )]
      : null,
      ctrl.removeFolder() ? [
        m('div',
          m('input', { onchange: m.withAttr('value', ctrl.removeOldFolder) }),
          m('button', { style: { float: 'initial', marginLeft: '10px' }, onclick: () => {
            ctrl.deleteFolder()
          } }, 'Remove folder')
        )]
      : null
    ])
  }
}
