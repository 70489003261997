import m from 'mithril'
import routes from './js/routes/index'

// Expose the m object (this is mainly for amok live-reloading)
window.m = m
window.apiUrl = 'https://internalapi.beat.dk/'
window.beamSignAPIUrl = 'https://beatdatabridgeservice2.herokuapp.com/'

window.onload = () => {
  m.route(window.document.body, '/', routes)

  window.document.body.ondragstart = e => {
    if (e.target.href) {
      e.preventDefault()
      return false
    }
  }
}
