import m from 'mithril'
import List from '../models/list'
import search from '../header/search'
import select from '../components/select'
import textInput from '../components/textInput'
import toggleButton from '../components/toggleButton'
import tagfield from '../components/tagfield'

function add(filter) {
  const filters = []

  filter.map(name => {
    let props

    if (typeof name === 'object') {
      props = name
      name = name.name
    } else {
      props = getFilterProperties(name)
    }

    const defaultValue = props.useDefault ? props.value[0] : ''
      , filter = props.urlValue ? props.value[props.urlValue.indexOf(decodeURIComponent(m.route.param(props.prefix)))] :
        m.route.param(props.prefix) === undefined ? defaultValue : encodeURIComponent(m.route.param(props.prefix))

    if (props.type === 'search') {
      if (props.objName !== props.objPick && filter)
        List.request({ url: props.searchUrl + '/' + filter }).then(data => props.valueName = data[props.objName])
      else if (filter)
        props.valueName = filter
      else
        props.valueName = ' '
    }

    return filters.push({ name: name, value: props.filterValue && filter ? props.filterValue(filter) : filter, props,
      getValue: () => props.value })
  })

  return filters
}

function change(val, type, filters) {
  for (const filter in filters) {
    if (filters[filter].name === type) {
      if (typeof val === 'object')
        return filters[filter].value = val

      return filters[filter].value = val === undefined ? '' : encodeURIComponent(val)
    }
  }
}

function getFilterProperties(name) {
  switch (name) {
  case 'organizations':
    return { prefix: 'org', type: 'search', searchUrl: 'organizations', title: 'Organization',
      objPick: '_id', objName: 'name' }
  case 'partner':
    return { prefix: 'partner', type: 'search', searchUrl: 'partners', title: 'Partner',
      objPick: '_id', objName: 'name' }
  case 'folder':
    return { prefix: 'folder', type: 'search', searchUrl: 'lists/folders', title: 'Folder' }
  case 'outlookInfo.categories':
    return { prefix: 'cat', type: 'select', title: 'Category:',
      model: List, opts: { conditions: { type: 'categories' }, distinct: 'data' } }
  case 'notesText':
    return { prefix: 'notes', type: 'input', title: 'Notes text' }
  case 'city':
    return { prefix: 'city', type: 'input', title: 'City:' }
  case 'country.name':
    return { prefix: 'country', type: 'search', searchUrl: 'countries', title: 'Country',
      objPick: 'name', objName: 'name' }
  case 'os':
    return { prefix: 'os', type: 'input', title: 'OS:' }
  case 'lsApp':
    return { prefix: 'lsApp', type: 'tagfield', title: 'LifeSupport App:', filterValue: v => decodeURIComponent(v).split(',') }
  default:
    break
  }
}

function returnFilter(name, filters) {
  for (const filter in filters) {
    if (filters[filter].name === name)
      return filters[filter]
  }
}

function getConditions(filters) {
  const conditions = {}

  filters.map(filter => {
    if (filter.value || conditions[filter.name]) {
      if (filter.name === 'notesText' || filter.name === 'city' || filter.name === 'os') {
        return filter.value ? conditions[filter.name] = { $regex: decodeURIComponent(filter.value).replace('(', '\\('), $options: 'i' } :
          delete conditions.notesText
      }

      const value = filter.value || undefined

      if (typeof value === 'object')
        return conditions[filter.name] = value

      return conditions[filter.name] = decodeURIComponent(value)
    }
  })

  return conditions
}

function getURL(filters) {
  let url = ''

  filters.map(filter => {
    if (filter.value || filter.value === false) {
      const value = filter.props.urlValue ?
        filter.props.urlValue[filter.props.value.indexOf(filter.value)] : filter.value

      url = url + '&' + filter.props.prefix + '=' + value
    }
  })

  return url
}

function render(ctrl) {
  return m('.pageBar.raised', [
    m('h3', 'Filters'),
    ctrl.filters.map((filter, i) => {
      return renderFilter(filter, ctrl, i, ctrl.filters.length)
    })
  ])
}

function renderFilter(filter, ctrl, i, length) {
  const props = filter.props
    , type = props.type
    , args = { onchange: m.withAttr('value', value => { ctrl.changeFilter(value, filter.name) }),
      value: props.value, text: props.text, selected: decodeURIComponent(filter.value) }
  let render

  switch (type) {
  case 'select':
    render = m(select, args)
    args.map = filter.data()
    break
  case 'input':
    render = m(textInput, args)
    break
  case 'button':
    render = m(toggleButton, args)
    args.getValue = filter.getValue()

    filter.getValue = () => {
      return filter.value ? filter.value : props.value[0]
    }
    args.onclick = () => {
      ctrl.changeFilter(filter.value ? filter.value === props.value[0] ? props.value[1] : props.value[0]
        : props.value[1], filter.name)
    }
    break
  case 'search':
    return m(search, { search: props.searchUrl, label: props.title,
      styleInput: { margin: 0, position: 'relative', left: 0, backgroundColor: '#eee' },
      styleSearch: { zIndex: length - i, position: 'relative', color: 'black' },
      resultsTop: '40px', resultsLeft: '0px',
      defaultValue: props.valueName === undefined ? '' : decodeURIComponent(props.valueName),
      onclick: item => {
        props.valueName = props.objName ? item[props.objName] : item
        ctrl.changeFilter(props.objPick ? item[props.objPick] : item, filter.name)
      } })
  case 'tagfield':
    return m(tagfield, { name: filter.name, label: props.title, suggest: 'lsApps', disableScroll: true,
      labelStyle: { opacity: 1, fontSize: 'initial', top: '-12px' },
      onchange: value => { ctrl.changeFilter(value.length > 0 ? value : null, filter.name) },
      value: m.prop(filter.value ? filter.value : []) })
  default:
    break
  }

  return m('h4', props.title, render)
}

export default {
  add,
  change,
  getConditions,
  returnFilter,
  getURL,
  render
}
