import m from 'mithril'
import Organization from '../models/organization'
import Location from '../models/location'
import dialog from '../models/dialog'
import navigate from '../models/ajaxNavigate'
import math from '../helper/math'
import itemView from './organization'

export default {

  controller() {
    const id = m.route.param('id') || ''

    const ctrl = navigate({
      id: id,
      lists: [{ model: Organization, opts: { sort: 'name', limit: 30 } }],
      root: '/organizations',
      changed: m.prop(null),
      locations: m.prop([]),
      getLocations(id) {
        ctrl.locations = Location.query({ conditions: { organizations: id }, sort: 'name reference' })
      },
      remove(item) {
        item.key = item._id()

        dialog.prompt({
          title: `Delete ${item.name()}?`,
          description: 'Are you sure you wish to delete ' + item.name() +
                       '. \nYou will not be able to restore after having deleted it.',
          confirmText: 'delete',
          confirm: item.remove
        })
      }
    })

    if (id === 'new') {
      ctrl.item = Organization.create()
      ctrl.item.on('created', i => {
        ctrl.route(i._id())
      })
    } else if (id) {
      ctrl.item = Organization.get(id)
      ctrl.getLocations(id)

      if (ctrl.item) {
        ctrl.item.on('deleted', () => ctrl.route())
        ctrl.item.on('changed', i => ctrl.changed(`Update at: ${math.convertDateTimeUTC(i.modified(), new Date().getTimezoneOffset() / 60)}`))
      }
    }
    return ctrl
  },

  view(ctrl) {
    return m(ctrl.item ? '#organizations.columns' : '.content', { class: ctrl.item ? 'three' : '' }, [
      m.route.param('id') ?
        m('.raised.detail', {
          class: ctrl.item ? 'show' : ''
        }, ctrl.item ? itemView(ctrl, { Model: Organization }) : '') :

        m('', [
          m('h1', [
            m('span', 'Organizations'),
            ctrl.controls()
          ]),
          m('table.locations', [
            m('tr', [
              m('th', 'Name'),
              m('th', 'Phone'),
              m('th', 'Email')
            ]),
            ctrl.returnList().data().map((item, i) => {
              if (i === ctrl.limit)
                return null

              return m('tr', {
                style: { cursor: 'pointer' },
                onclick: () => {
                  ctrl.route(item._id)
                  ctrl.getLocations(item._id)
                }
              }, [
                m('td', m('h3', item.name || '')),
                m('td', m('h3', item.phone || '')),
                m('td', m('h3', item.email || ''))
              ])
            })
          ])
        ])
    ])
  }
}
