import m from 'mithril'
import Transloadit from '../models/transloadit'
import location from '../components/location'
import filemanager from '../components/filemanager'

function fileDrop(el, options) {
  options = options || {}
  el.addEventListener('dragover', activate)
  el.addEventListener('dragenter', activate)
  el.addEventListener('dragleave', deactivate)
  el.addEventListener('dragend', deactivate)
  el.addEventListener('drop', deactivate)
  el.addEventListener('drop', update)

  function activate(e) {
    e.preventDefault()
    e.stopPropagation()
    if(options.onenter)
      options.onenter()
  }

  function deactivate() {
    if(options.onleave)
      options.onleave()
  }

  function update(e) {
    e.preventDefault()
    if (typeof options.onchange === 'function') {
      options.onchange((e.dataTransfer || e.target).files)
    }
  }
}

function config(onchange) {
  return (el, ready) => {
    if (!ready) {
      fileDrop(window.document.body, {
        onchange: onchange,
        onenter() {
          el.classList.add('show')
        },
        onleave() {
          el.classList.remove('show')
        }
      })
    }
  }
}

export default {

  controller(options) {
    this.onchange = options => {
      window.location.href.indexOf('location') > -1 ? location.uploadFiles(options) : 
      window.location.href.indexOf('filemanager') > -1 ? filemanager.uploadFiles(options) : Transloadit.upload
    }
  },

  view(ctrl) {
    return m('.dropZone', { config: config(ctrl.onchange) },
      m('div', m('span', 'drop files here to start upload'))
    )
  }

}
