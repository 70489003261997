import m from 'mithril'

export default {

  view(ctrl, attrs) {
    return m('select', { style: { marginLeft: '10px' }, onchange: attrs.onchange }, [
      m('option', ''),
      attrs.map.map(item => {
        const value = item[attrs.value] || item
          , text = item[attrs.text] || item
          , selected = value === attrs.selected ? 'selected' : ''

        return m('option', { selected: selected, value: value }, text)
      })
    ])
  }
}
