import m from 'mithril'

const tabs = [

  /* { href:'/', label:'Home', icon: 'home' },
  { href:'/media', label:'Media', icon: 'picture' },
  { href:'/schedules', label:'Schedules', icon: 'calendar' },*/
  { href:'/organizations', label:'Organizations', icon: 'organization' },
  { href:'/locations', label:'Locations', icon: 'location' },
  { href:'/devices', label:'Devices', icon: 'eye' },
  { href:'/info', label:'Info', icon: 'info' },
  // { href:'/apps', label:'Apps', icon: 'buffer' },
  // { href:'/users', label:'Users', icon: 'user' },
  { href:'/shippings', label:'Shippings', icon: 'calendar' },
  { href:'/partners', label:'partners', icon: 'road' }
  // { href:'/settings', label:'Settings', icon: 'cog' }
]

function selected(r) {
  return m.route().split('/')[1] === r.split('/')[1] ? 'selected' : ''
}

const sidebar = {

  view: function() {
    return m('nav#sidebar', [
      m('.menu', tabs.map(tab => m('a', {
        href: tab.href, class: selected(tab.href),
        config: m.route
      }, [
        m('i.icon-' + tab.icon),
        m('span', tab.label)
      ]))),
      m('a.about', { href: '/about', class: selected('/about'), config: m.route }, [
        m('i.icon-logo'),
        m('span', 'Contact')
      ])
    ])
  }
}

export default sidebar
