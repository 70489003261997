import m from 'mithril'
import Media from '../models/media'

window.onbeforeunload = () => {
  if (Uploader.activeFiles().length > 0)
    return 'Files are currently being uploaded. If closing now they will not be finished!'
}

var Uploader = {
  files     : []
}

Uploader.cancel = file => {
  Uploader.files.some(f => {
    if(f === file) {
      file.xhr.abort()
      file.loaded = 0
      file.canceled = true
      return true
    }
  })
}

Uploader.activeFiles = () => Uploader.files.filter(file => !file.canceled && !file.finished)

Uploader.count = () => Uploader.activeFiles().length

Uploader.add = file => {
  const index = Uploader.files.indexOf(file)
  if(index > -1) {
    Uploader.files[index] = file
  } else {
    Uploader.files.unshift(file)
  }
}

Uploader.loaded = () => Uploader.activeFiles().reduce((r, f) => {
  return r + f.loaded
}, 0)

Uploader.progress = () => 1 / Uploader.total() * Uploader.loaded()

Uploader.total = () => Uploader.activeFiles().reduce((r, f) => {
  return r + f.size
}, 0)

function createMediaAndStartUpload(file) {
  Media.create({
    filename : file.name,
    localModifiedDate: file.lastModifiedDate,
    size: file.size,
    type: file.type
  }).save().then(media => {
    Uploader.uploadFile(media._id, file)
  })
}

Uploader.upload = files => {
  for(let i = 0; i < files.length; i++) {
    createMediaAndStartUpload(files[i])
  }
}

Uploader.uploadFile = (id, file) => {
  const formData = new FormData()
  formData.append('params', JSON.stringify({
    auth: { key: '948d7a8cf34f42f39029ce07b7444d1a' },
    'template_id': '7dfb6830754c11e4b95247292db8abe2',
    'notify_url': 'https://puchkcninl.localtunnel.me/transloadit',
    fields: {
      id: id || file.id
    }
  }))

  file.loaded = 0
  Uploader.add(file)
  formData.append('file', file)

  m.request({
    method: 'POST',
    url: '//api2.transloadit.com/assemblies',
    data: formData,
    serialize(value) { return value },
    config(xhr) {
      file.xhr = xhr
      xhr.upload.onprogress = e => {
        file.loaded = e.loaded
        m.redraw()
      }
    }
  }).then(success => {
    file.finished = true
  }, err => {
    file.error = err
    console.log(err)
  }).then(m.redraw)
}

export default Uploader
