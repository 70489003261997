import m from 'mithril'
import textfield from '../components/input'

export default (ctrl, args) => {
  const item = ctrl.item

  return item.key || m.route.param('id') === 'new' ? [
    m('h1', item.key ? ('Edit ' + (item.name() || '')) : 'Create new user'),
    m('fieldset', [
      m(textfield, { name: 'name', value: item.name }),
      m(textfield, { name: 'username', value: item.username }),
      m(textfield, { name: 'email', value: item.email }),
      m(textfield, { name: 'password', value: item.password, type: 'password' })
    ]),
    item.key ? m('button.raised.danger', { onclick: item.remove }, 'delete')
              : m('button.raised', { onclick: item.save }, 'create')
  ] : m('h1', m.route.param('id') + ' not found')
}
