import m from 'mithril'
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

const utils = {

  prettyBytes: function(bytes) {
    if (bytes === 0)
      return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  },

  setValue: function(value, e) {
    e.target.value = value
  },

  series: function() {
    const args = Array.prototype.slice.call(arguments)

    return e => {
      args.forEach(fn => fn && fn(e))
    }
  },

  ifkey: function(key, fn) {
    return e => {
      m.redraw.strategy('none')
      if (e.keyCode === key)
        fn(e)
    }
  },

  blur: function(e) {
    e.target.blur()
  },

  debounce: function(fn, delay) {
    let timer = null

    return function() {
      clearTimeout(timer)

      timer = setTimeout(() => {
        fn.apply(this, arguments)
      }, delay)
    }
  },

  focusIf: function(prop) {
    return (el, isInit) => {
      if (prop()) {
        el.focus()
        prop(false)
      }
    }
  },

  getTime: function(date) {
    return (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' +
           (date.getMinutes() < 10 ? '0' : '') +
            date.getMinutes()
  },

  removeTime: function(date) {
    return new Date(date.getYear(), date.getMonth(), date.getDate())
  },

  prettyDate: function(date) {
    if (!date)
      return ''

    date = new Date(date)

    const now = utils.removeTime(new Date())
      , onlyDate = utils.removeTime(date)
      , year = date.getFullYear() === new Date().getFullYear()
      , yearTitle = year ? '' : '/' + date.getFullYear()

    if (onlyDate.getTime() < (now.getTime() - 1000 * 60 * 60 * 24))
      return date.getDate() + '/' + (date.getMonth() + 1) + yearTitle + ' - ' + utils.getTime(date)
    else if (onlyDate < now)
      return 'Yesterday - ' + utils.getTime(date)

    return utils.getTime(date)
  }

}

export default utils
