import m from 'mithril'
import Schedule from '../models/schedule'
import datepicker from '../components/datepicker'

export default {

  controller() {
    const id = m.route.param('id') || ''

    const ctrl = {
      list : Schedule.get()
    }

    if (id === 'new') {
      ctrl.schedule = Schedule.create()
      ctrl.schedule.on('deleted', m.route.bind(null, '/schedules'))
    } else if (id) {
      ctrl.schedule = Schedule.get(id)
      if (ctrl.schedule)
        ctrl.schedule.on('created', schedule => m.route('/schedules/' + ctrl.schedule.key))
    }

    return ctrl
  },

  view(ctrl) {
    return m('#schedules.column', [
      m('.toolbar', [
        m('h1', 'Schedules'),
        m('.zoom', {

        }, 'zoom'),
        m('.viewType')
      ]),
      m(datepicker, {
        weeks: true
      })
    ])
  }
}
