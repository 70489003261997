import m from 'mithril'
import login from '../models/login'

export default {

  controller() {
    return {
      logout : login.clearToken,
      user   : login.user()
    }
  },

  view(ctrl) {
    const organizations = []

    if (ctrl.user.organizations.length > 1) {
      ctrl.user.organizations.map(org => {
        return m('li', [
          m('a', { href: 'https://' + org.subdomain + '.beamsign.com' }, [
            m('i.icon-organization'),
            m('span', org.name)
          ])
        ])
      })
    }

    return m('i#user.icon-user', [
      m('.triangle'),
      m('ul.popdown', [
        m('li', {
          onclick: ctrl.logout
        }, [
          m('i.icon-logout'),
          m('span', 'Log out')
        ])
      ].concat(organizations))
    ])
  }

}
