import api from '../api'

export default api.resource({
  path    : 'users',
  keyName : 'username',
  schema  : {
    name      : String,
    username  : String,
    email     : String,
    password  : String,
    roles     : Array
  }
})
