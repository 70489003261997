import m from 'mithril'
import dialog from '../models/dialog'

export default {

  controller() {

    const ctrl = {
      cancel() {
        if (dialog.state.cancel)
          dialog.state.cancel()

        dialog.show(false)
      },

      confirm() {
        if (dialog.state.confirm)
          dialog.state.confirm()

        dialog.show(false)
      }
    }

    return ctrl
  },

  view(ctrl) {

    return m('#dialog', {
      class: dialog.show() ? 'show' : ''
    }, m('.raised', { style: dialog.state.dialogStyle ? dialog.state.dialogStyle : '' }, [
      m('h1', dialog.state.title || 'Title'),
      m('p', m.trust(dialog.state.description), dialog.state.m || '' ),
      m('.menu', [
        m('button', { onclick: ctrl.cancel }, dialog.state.cancelText || 'cancel'),
        m('button', { onclick: ctrl.confirm }, dialog.state.confirmText || 'confirm')
      ])
    ]))
  }

}
