import m from 'mithril'
import b from 'bss'

// import textfield from '../components/input'
import monitors from '../components/monitors'
import location from '../components/location'
import schedules from '../components/schedules'
import apps from '../components/apps'
import filemanager from '../components/filemanager'
import cmd from '../components/cmd'
import dialog from '../models/dialog'
// import tagfield from '../components/tagfield'
import Device from '../models/device'
import Station from '../models/stations'
import Guac from '../models/guac'
import utils from '../utils'
import Notification from '../models/notification'

const sections = {
  // edit: {
  //   view: function(ctrl, item) {
  //     return m('fieldset.content', [
  //       m(textfield, { name: 'Serial number', value: item.id }),
  //       m(textfield, { name: 'Location', value: item.location }),


  //       m(suggestor, {
  //         name: 'organizations',
  //         suggestions: ctrl.suggestions,
  //         display: item.organizations
  //       }),


  //       m(tagfield, { name: 'tags', value: item.tags }),
  //       m.route.param('id') === 'new'
  //         ? m('button.raised', { onclick: item.save }, 'create')
  //         : m('button.raised.danger', { onclick: item.remove }, 'delete')
  //     ])
  //   }
  // },
  all: {
    view: function(ctrl, item) {
      return m('.content', m('pre', JSON.stringify(item.raw(), null, 4)))
    }
  },
  monitors: {
    controller: function(item) {
      this.update = true
      this.getData = function() {
        this.update = false
        monitors.getDeviceData(item._id())
      }
    },
    view: function(ctrl, item) {
      if (ctrl.update && item._id)
        ctrl.getData()

      return m('.content', [
        monitors.deviceData()
      ])
    }
  },
  schedules: {
    controller: function(item) {
      this.update = true
      this.getData = function() {
        this.update = false
        monitors.getDeviceData(item._id())
      }
    },
    view: function(ctrl, item) {
      if (ctrl.update && item._id)
        ctrl.getData()

      return m('.content', [
        schedules.create()
      ])
    }
  },
  location: {
    controller: function(item) {
      this.update = true
      this.getData = function() {
        this.update = false

        if (item.location())
          location.loadLocation(item.location()._id, item._id())
        else location.loadLocation(null, item._id())
      }
    },
    view: function(ctrl, item) {
      if (ctrl.update && item._id)
        ctrl.getData()

      return m('.content', [
        location.deviceData()
      ])
    }
  },
  applications: {
    controller: function(item) {
      this.update = true
      this.getData = function() {
        this.update = false
        apps.getDeviceData(item.lifelineId())
      }
    },
    view: function(ctrl, item) {
      if (ctrl.update && item._id)
        ctrl.getData()

      return m('.content', [
        apps.deviceData()
      ])
    }
  },
  filemanager: {
    controller: function(item) {
      this.update = true
      this.getData = function() {
        this.update = false
        filemanager.getDeviceData(item.lifelineId())
      }
    },
    view: function(ctrl, item) {
      if (ctrl.update && item._id)
        ctrl.getData()

      return m('.content', [
        filemanager.deviceData()
      ])
    }
  },
  cmd: {
    controller: function(item) {
      this.update = true
      this.getData = function() {
        this.update = false
        cmd.getSession(item.lifelineId())
      }
    },
    view: function(ctrl, item) {
      if (ctrl.update && item._id)
        ctrl.getData()

      return m('.content', [
        cmd.deviceData()
      ])
    }
  }
}

export default {

  controller: function() {
    const id = m.route.param('id')
      , section = m.route.param('section')
      , ctrl = {}
      , guacs = Guac.get()

    if (id === 'new') {
      ctrl.item = Device.create()
      ctrl.item.on('created', u => m.route('/devices/' + u.key))
    } else if (id) {
      ctrl.item = Device.get(id)
      if (ctrl.item)
        ctrl.item.on('deleted', () => m.route('/devices'))
    }

    ctrl.defaultTab = localStorage.getItem('defaultTab')

    ctrl.setDefaultTab = () => {
      localStorage.setItem('defaultTab', section)
      ctrl.defaultTab = section
    }

    ctrl.rememberTab = e => {
      if (e.target.checked) {
        ctrl.setDefaultTab()
      } else {
        localStorage.removeItem('defaultTab')
        ctrl.defaultTab = null
      }
    }

    getShutdowns()

    function getShutdowns() {
      if (!ctrl.item.lifelineId)
        return setTimeout(getShutdowns, 100)

      Station.query({ conditions: { lifelineId: ctrl.item.lifelineId() }, select: 'eventLog' })
        .then(res => {
          if (res[0] && res[0].eventLog && res[0].eventLog.boot) {
            const normal = res[0].eventLog.boot.filter(x => x.type === 'normal')
              , abnormal = res[0].eventLog.boot.filter(x => x.type === 'abnormal')

            ctrl.shutdowns = m('div', 'Shutdowns: ' + abnormal.length + ' / ' + (normal.length + abnormal.length))

            m.redraw()
          }
        })
    }

    if (ctrl.item && !sections[section])
      m.route('/devices/' + id + '/' + (sections[ctrl.defaultTab] ? ctrl.defaultTab : 'location'))
    else if (ctrl.defaultTab)
      ctrl.setDefaultTab()

    ctrl.reboot = () => {
      dialog.prompt({
        title: 'Reboot ' + ctrl.item.serialNumber() || ctrl.item.hostname(),
        description: 'Are you sure you want to reboot ' + ctrl.item.serialNumber() || ctrl.item.hostname() + '?',
        confirmText: 'reboot',
        confirm: function() {
          Device.request({
            method: 'post',
            background: true,
            url: Device.urlRoot + id + '/reboot'
          })
        }
      })
    }

    getCards()

    function getCards() {
      ctrl.cards = window.localStorage.getItem('cards') ?
        JSON.parse(window.localStorage.getItem('cards').split(',')) : { }
    }

    ctrl.addCard = () => {
      let push = true

      getCards()

      Object.keys(ctrl.cards).map(key => {
        if (key === ctrl.item._id())
          push = false
      })

      if (push) {
        ctrl.cards[ctrl.item._id()] = { label: ctrl.item.serialNumber() || ctrl.item.hostname(),
          location: ctrl.item.location().reference }
        window.localStorage.setItem('cards', JSON.stringify(ctrl.cards))
      }

      getCards()
    }

    ctrl.ipWhois = () => {
      m.request({
        background: true,
        dataType: 'jsonp',
        url: 'https://www.iplocate.io/api/lookup/' + ctrl.item.ip()
      }).then(r =>
        alert('IP Whois: \n' +
              'Org: ' + r.org + '\n' +
              'City: ' + r.city + '\n' +
              'Country: ' + r.country)
      )
    }

    ctrl.removeCard = () => {
      getCards()

      delete ctrl.cards[ctrl.item._id()]

      window.localStorage.setItem('cards', JSON.stringify(ctrl.cards))
    }

    ctrl.cardKept = () => {
      if (ctrl.item.id) {
        const card = ctrl.item.id()

        for (const val in ctrl.cards) {
          if (val === card)
            return true
        }
        return false
      }
    }

    ctrl.startvnc = () => {
      if (ctrl.item.connected())
        return startVnc()

      dialog.prompt({
        title: (ctrl.item.serialNumber() || ctrl.item.hostname()) + ' is not online',
        description: 'Do you want to start a connection even though the device is not online?',
        confirmText: 'Connect & wait',
        confirm: startVnc
      })
    }

    ctrl.disableEFMGR = () => {
      dialog.prompt({
        title: 'Disable ewfmgr on ' + (ctrl.item.serialNumber() || ctrl.item.hostname()),
        description: 'This will disable ewfmgr c: -disable -commit on this box, are you sure?',
        confirmText: 'Disable',
        confirm: () => {
          Device.request({
            url: 'devices/' + ctrl.item._id() + '/execute',
            method: 'POST',
            background: true,
            config: xhr => ctrl.xhr = xhr,
            data: {
              arguments: 'ewfmgr c: -disable -commit',
              path: 'https://pub.beat.dk/files/run.exe'
            }
          }).then(data => {
            let notification = ''

            Object.keys(data).map(val => {
              notification = notification + val + ': ' + data[val]
            })

            Notification.info(notification)
          }).then(m.redraw)
        }
      })
    }

    function startVnc() {
      let guac = guacs().find(g => g.device === id)

      if (guac)
        return guac.show = true

      guac = Guac.create({ show: true, device: ctrl.item.key })
      guac.save()
    }

    return ctrl
  },

  view: function(ctrl) {
    const item = ctrl.item
      , isNew = m.route.param('id') === 'new'
      , section = m.route.param('section')
      , content = sections[section]
      , nolocation = 'No location is attached to this device'

    let location
      , color
      , referenceTrim

    if (item.location()) {
      if (item.location().reference)
        referenceTrim = item.location().reference.trim()
    }

    if (item.amuseicLocation) {
      const amuseicLoc = item.amuseicLocation().trim()

      if (amuseicLoc.startsWith(referenceTrim)) {
        location = amuseicLoc
      } else {
        location = `${referenceTrim || nolocation} / ${amuseicLoc}`
        color = '#ff8282'
      }
    } else if (referenceTrim) {
      location = referenceTrim
      color = 'yellow'
    }

    if (location)
      window.document.title = location

    const atBeat = item.ip() === '85.204.133.170' ? ' at Beat/Vanløse' : ''

    return m('#device.rows', item.key || isNew ? [
      m('.pageBar.raised', [
        Object.keys(ctrl.cards).length > 0 ?
          m('div', { style: { width: 'calc(90vw - 260px)' } }, 'Pinned devices: ', [
            Object.keys(ctrl.cards).map((key, i) => {
              return m('a', { href: '/devices/' + key, config: m.route }, [
                m('button', { style: { float: 'initial', marginRight: '5px' } }, [
                  m('div', { style: { marginTop: '-8px' } }, ctrl.cards[key].label),
                  m('div', { style: { fontSize: '10px', marginTop: '-20px' } }, ctrl.cards[key].location)
                ])
              ])
            })
          ]) : null,
        m('h1' + b.position('relative').verticalAlign('baseline'), [
          m('span' + b.userSelect('none').mr(8).cursor('pointer').pt(4).fw('bold').bc('white').c('#005a64').br(2),
            m('span' + b.p(5), {
              onclick: (e) => {
                e.stopPropagation()
                item.selectType = !item.selectType
                item.selectType && window.addEventListener('click', function Click(e) {
                  window.removeEventListener('click', Click)
                  item.selectType = false
                  m.redraw()
                })
              }
            }, Device.types[item.type()] || item.type() || '?'),
            item.selectType ? m('div' + b.fs(16).fw('normal').zi(20).bc('white').position('absolute'),
              Object.keys(Device.types).map(t =>
                m('div' + b.p(0, 8).$hover(b.bc('#ccc')), {
                  onclick: () => item.type(t)
                }, t)
              )
            ) : null
          ),
          item.key ? (item.serialNumber() || item.hostname()) : 'Create new Device',
          item.lastConnected() && m('small', item.connected()
            ? ' online' + atBeat
            : ' Last seen at ' + utils.prettyDate(item.lastConnected()) + atBeat)
        ]),
        m('p', color ? { style: { color: color } } : '', location || nolocation),
        m('.toolbar', [
          m('i', { onclick: ctrl.ipWhois }, 'IP'),
          ctrl.cardKept() ?
            m('i', { onclick: ctrl.removeCard }, '-', m('span', 'un-pin')) :
            m('i.icon-target', { onclick: ctrl.addCard }, m('span', 'pin')),
          m('i.icon-cog', { onclick: ctrl.disableEFMGR }, m('span', 'ewfmgr')),
          m('i.icon-desktop', { onclick: ctrl.startvnc }, m('span', 'remote')),
          m('i.icon-cw', { onclick: ctrl.reboot }, m('span', 'reboot')),
          ctrl.shutdowns
        ]),
        isNew
          ? null
          : m('.tabs', Object.keys(sections).map(s => tab(section, s, ctrl)),
            m('span',
              m('input',
                { type: 'checkbox', onchange: ctrl.rememberTab, checked: ctrl.defaultTab ? 'checked' : null } ),
              'remember tab'))
      ]),
      m(content, item)
    ] : m('h1.content', m.route.param('id') + ' not found'))
  }

}

function tab(value, name, ctrl) {
  return m('a', {
    href: '/devices/' + m.route.param('id') + '/' + name,
    config: m.route,
    class: value === name ? 'selected' : ''
  }, name)
}

