import m from 'mithril'
import math from '../helper/math'

const options = {
  name: ['Year / month / week / day / hms', 'Week number',
    'Month date', 'Specific month', 'Year day number'],
  range: [['year', 'month', 'week', 'day', 'hour', 'minute', 'second'], '53', '31', '12', '366']
}

const updateKeys = ['frequency', 'weeks', 'dates', 'months', 'yeardays', 'offset']
const updateFreq = ['', 'year', 'month', 'month', 'year', '']

const selectedType = m.prop()
const step1 = []
const step2 = []
const selectedValues = []
const selectedFreq = []
const repeatTimes = []
const offset = 'Last day of the month'
const frequency = ['Every', 'Every other', 'Every third', 'Every fourth', 'Every fifth', 'Every sixth']
const freqAlert = '"At" option will be ignored, when this option is selected.'
const daysAlert = 'Make sure you\'ve selected at least one day in "days" selector, for this to work.'

function build() {
  return selectOptions()
}

function resolveType(obj) {
  let typ
  let key

  removeEmpty(obj)

  selectedValues.length = 0
  selectedFreq.length = 0
  repeatTimes.length = 0

  selectedFreq[0] = frequency[obj.interval - 1]

  if (obj.times)
    repeatTimes[0] = obj.times
  else repeatTimes[0] = ''

  Object.keys(obj).map(el => {
    updateKeys.map((element, idx) => {
      if (el === element && idx !== 0) {
        key = element
        typ = idx
      }
    })
  })

  if (key) {
    if (obj[key].length > -1) {
      obj[key].map(el => {
        selectedValues.push(el)
      })
    }

    if (key === updateKeys[2] || key === updateKeys[5]) {
      typ = 2
      if (obj.offset)
        selectedValues.push(offset)
    }
  } else {
    key = updateKeys[0]
    selectedValues[0] = obj[updateKeys[0]]
    typ = 0
  }

  selectedType(typ)
  selectType(typ)

  return typ
}

function resolveTitle(obj) {
  let title

  removeEmpty(obj)

  if (obj.weeks) {
    title = `${obj.weeks.join(', ')} week of each year`
  } else if (obj.dates) {
    title = `${obj.dates.join(', ')} date of the month`
    if (obj.offset)
      title = `${title} and the last day`
  } else if (obj.months) {
    title = `${obj.months.join(', ')} month of the year`
  } else if (obj.yeardays) {
    title = `${obj.yeardays.join(', ')} day of the year`
  } else if (obj.offset) {
    title = 'last day of the month'
  } else {
    title = obj.frequency
  }

  if (obj.times)
    title = `${frequency[obj.interval - 1]} ${title} for ${obj.times} times`
  else title = `${frequency[obj.interval - 1]} ${title}`

  return title
}

function removeEmpty(obj) {
  Object.keys(obj).map(key => {
    if (obj[key].length < 1)
      delete obj[key]
  })
}

function selectOptions() {
  return [m('div#optionsStep1', { style: { display: 'none' } }, [
    Object.keys(options.name).map((el, i) => {
      let title = `${options.name[el]} (1 - ${options.range[i]})`

      if (i === 0)
        title = options.name[el]

      return m('button.primeTime.repeat', { onclick: () => {
        selectedType(i)
        selectType(i)
        hideSteps(2)
      } }, title)
    })
  ]),
    m('div#optionsStep2', { style: { display: 'flex' } }, [
      m('div', [
        m('button.primeTime.repeat', { onclick: () => { hideSteps(1) } }, `← ${options.name[step1[0]]}`),
        buildRepeatTimes(),
        buildFrequency()
      ]),
      m('div', [
        buildStep2(step1[0])
      ])
    ])
  ]
}

function hideSteps(step) {
  selectedValues.length = 0

  for (let i = 1; i < 3; i++) {
    if (i === step)
      document.getElementById(`optionsStep${i}`).style.display = 'flex'
    else document.getElementById(`optionsStep${i}`).style.display = 'none'
  }
}

function selectType(type) {
  step2.length = 0
  step1[0] = type

  if (type === 0) {
    options.range[type].map(el => {
      step2.push(el)
    })
  } else {
    for (let i = 1; i <= options.range[type]; i++)
      step2.push(i)
  }

  if (type === 2)
    step2.push(offset)
}

function buildStep2(type) {
  if (type === 0) {
    return choiceBox()
  } else if (type === 4) {
    math.sort(selectedValues)
    math.pushEmpty(selectedValues)

    return choiceInput()
  }

  return choiceButtons()
}

function buildFrequency() {
  return m('select', { onchange: function() {
    selectedFreq[0] = this.value
  } }, [
    frequency.map(element => {
      let selected = ''

      if (selectedFreq[0] === element.toString())
        selected = 'selected'

      return m('option', { selected: selected, value: element }, element)
    })
  ])
}

function buildRepeatTimes() {
  return m('span', [
    m('span', 'Repeat'),
    m('input.repeatTimes', { value: repeatTimes[0], onchange: function() {
      repeatTimes[0] = this.value
    } }),
    m('span', 'times')
  ])
}

function checkConflicts(conflict, element, option) {
  if (element) {
    if (conflict)
      element.parentNode.lastChild.innerHTML = freqAlert
    else element.parentNode.lastChild.innerHTML = ''
  }

  if (option === 1)
    return (conflict) ? freqAlert : ''

  return (conflict) ? daysAlert : ''
}

function choiceBox() {
  if (selectedValues.length < 1)
    selectedValues[0] = step2[0]

  return [
    m('select', { onchange: function() {
      selectedValues[0] = this.value
      checkConflicts([step2[4], step2[5], step2[6]].includes(this.value), this, 1)
    } }, [
      step2.map(element => {
        if (selectedValues[0] === element)
          return m('option', { selected: 'selected', value: element }, element)

        return m('option', { value: element }, element)
      })
    ]),
    m('span', checkConflicts([step2[4], step2[5], step2[6]].includes(selectedValues[0]), null, 1))
  ]
}

function choiceInput() {
  return selectedValues.map((el, idx) => {
    return m('select', { name: idx, onchange: function() {
      const checkValue = Number(this.name) + 1

      if (this.value === selectedValues[0])
        selectedValues.splice(checkValue, 1)
      else selectedValues[checkValue] = Number(this.value)

    } }, [
      m('option', { value: selectedValues[0] }, selectedValues[0]),
      step2.map(element => {
        const valueSelected = selectedValues.indexOf(element)

        if (valueSelected > -1 && idx !== valueSelected - 1)
          return null

        let selected = ''

        if (selectedValues[idx + 1] === element)
          selected = 'selected'

        return m('option', { selected: selected, value: element }, element)
      })
    ])
  })
}

function choiceButtons() {
  const padding = (selectedType() === 2) ? '205px' : 0

  return [
    step2.map(element => {
      let selected = ''

      selectedValues.map(el => {
        if (selectedType() === 2) {
          if (el === -1)
            el = offset
        }

        if (el === element)
          selected = 'selected'
      })
      return m(`button.days.${selected}`, { onclick: function() {
        selectItems(this)
      } }, element)
    }),
    m('div', { style: { 'padding-top': padding } }, checkConflicts(selectedValues.indexOf(offset) > -1, null, 2))
  ]
}

function selectItems(item) {
  checkConflicts(selectedValues.indexOf(offset) > -1, item, 2)

  item.classList.toggle('selected')
  selectedValues.length = 0

  for (const clicked in item.parentNode.childNodes) {
    if (!item.parentNode.childNodes.hasOwnProperty(clicked))
      return null

    if (item.parentNode.childNodes[clicked].classList.contains('selected')) {
      if (clicked.innerHTML === offset)
        selectedValues.push(offset)
      else selectedValues.push(Number(item.parentNode.childNodes[clicked].innerHTML))
    }
  }
}

function update(data) {
  if (selectedValues.length === 0)
    return null

  for (const key in updateKeys) {
    if (updateKeys.hasOwnProperty(key))
      delete data[key]
  }

  let value = []
  const typ = selectedType()
  const type = updateKeys[typ]

  if (typ === 2) {
    const index = selectedValues.indexOf(offset)

    if (index > -1) {
      selectedValues.splice(index, 1)
      data.offset = -1
    }
  }

  if (typ === 0)
    value = selectedValues[0]
  else value = selectedValues.filter(value => value !== '')

  data[type] = value

  if (typ > 0 && typ < 5)
    data.frequency = updateFreq[typ]

  data.interval = frequency.indexOf(selectedFreq[0]) + 1
  data.times = repeatTimes[0]

}

export default {
  build,
  frequency,
  resolveType,
  resolveTitle,
  update
}
