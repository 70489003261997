import m from 'mithril'

import textfield from '../components/input'
import toggleButton from '../components/toggleButton'
import inputText from '../components/textInput'
import tagField from '../components/tagfield'

function getContent(item, i, ix) {
  if (item.syntax && item.syntax[i])
    return Object.values(item.syntax[i])[ix]
}

export default ctrl => {
  const item = ctrl.item
    , newPartner = m.route.param('id') === 'new';

  (item._id || newPartner) && !item.syntax && ctrl.createUI()

  const editAllowed = newPartner || (item.accountingId() === '' && !item.accountingRow())

  return item._id || newPartner ? [
    m('h1',
      item._id && editAllowed ? m('button.raised.danger', { onclick: ctrl.remove.bind(null, item) }, 'delete')
        : newPartner ? m('button.raised', { onclick: item.create }, 'create') : null,

      m('button.raised', { style: { 'margin-right': '10px', 'margin-left': '5px' }, onclick: ctrl.route }, 'cancel'),

      item._id && editAllowed ? m('button.raised', {
        onclick: () => {
          item.update()
        } }, 'save') : null),

    m('fieldset', [
      ['name', 'accountingId', 'searchName', 'address', 'address2', 'zipCity', 'country', 'vatNumber', 'invoiceAccount', 'group']
        .filter(x => x === 'name' || (item[x] && item[x]()))
        .map(x =>
          editAllowed ?
            m(textfield, {
              name: x,
              value: item[x]
            })
            : m('div',
              m('h4', x + ':'),
              m('h4', item[x]()),
              m('br')
            )
        ),

      editAllowed ?
        [m('h4', 'Syntax: '),
          [0, 1, 2].map(i =>
            [
              m(toggleButton, {
                text: ['text', 'number', ''],
                value: [1, 3, 0],

                getValue: Object.keys(item.syntax[i]).length,

                onclick: () => {
                  item.syntax[i] =
                Object.keys(item.syntax[i]).length === 1 ?
                  {
                    length: '',
                    min: '',
                    max: ''
                  } :
                  Object.keys(item.syntax[i]).length === 3 ?
                    {

                    } : {
                      text: ''
                    }
                }
              }),

              Object.keys(item.syntax[i]).length === 3 ?
                [0, 1, 2].map(ix => [
                  m('span', {
                    style: {
                      marginLeft: '10px'
                    }
                  }, Object.keys(item.syntax[i])[ix]),

                  m(inputText, {
                    style: { width: '50px' },
                    type: 'number',
                    selected: String(getContent(item, i, ix)),

                    onchange: m.withAttr('value', val =>
                      item.syntax[i][Object.keys(item.syntax[i])[ix]] = Number(val)
                    )
                  })]
                )
                :
                Object.keys(item.syntax[i]).length === 0 ?
                  '' :
                  m(inputText, {
                    selected: getContent(item, i, 0),

                    onchange: m.withAttr('value', val =>
                      item.syntax[i].text = val
                    )
                  }),

              m('br')
            ]
          ),

          m(tagField, {
            name: 'Group',
            value: m.prop(item.incl.group)
          })

        ] : null,

      newPartner ? null :
        [m('div.input.tags.filled', { style: { 'border-bottom': 'none' } },
          item.locations().length === 0 ? 'No locations found for partner ' :
            'Locations: ',

          m('ul', [
            item.locations().map(location => {
              return m('div',
                m('li.raised', {
                  style: {
                    color: location.accounts && location.accounts[0].group.toLowerCase().startsWith('ophørt') && 'grey',
                    cursor: 'pointer', 'max-width': '500px'
                  },

                  onclick: () => {
                    m.route(`/locations/${location._id}`)
                  }

                }, location.name + ', ' + location.reference))
            })
          ])
        )],
      m('div', { style: { 'padding-top': '15px' } }, ctrl.changed())
    ])

  ] : m('h1', 'Partner ' + m.route.param('id') + ' not found')
}
