import m from 'mithril'
import b from 'bss'

import tinymce from 'tinymce'
import dialog from '../models/dialog'
import Notification from '../models/notification'
import login from '../models/login'
import Location from '../models/location'
import Device from '../models/device'
import User from '../models/user'
import Organization from '../models/organization'
import Account from '../models/account'
import math from '../helper/math'
import attachmentIcon from '../helper/attachmentIcon'
import search from '../header/search'
import textfield from '../components/input'
import tagfield from '../components/tagfield'

let auth

const DataUrl = {
  const: {
    base: null,
    device: null,
    config: function(xhr) {
      xhr.setRequestHeader('Authorization', auth)
    }
  },
  areas: {
    deviceconnections: null,
    locations: null,
    devices: [],
    organizations: [],
    accounts: []
  },
  template: {
    location: ['name', 'reference', 'street', 'street2', 'zip', 'city', 'state', 'country', 'contact', 'notes',
      'email', 'phone', 'mobile', 'reference', 'outlookInfo', 'folder', 'openings', 'reminders']
  }
}

const iconSize = 17
  , restrictMovement = m.prop(false)

let oldData
  , edit
  , create
  , saving = false
  , showAttach = false
  , orgAdd = false
  , accAdd = false
  , deviceAdd = false
  , updateHistoryData = []
  , documentVersion = null

function failUpdate() {
  Notification.error('Failed!', 'Your changes have not been saved')
  m.redraw()
}

function initialize() {
  auth = `Bearer ${login.user().token}`

  DataUrl.const.base = window.apiUrl
  DataUrl.areas.locations = null
  DataUrl.areas.devices.length = 0
  DataUrl.areas.organizations.length = 0
  DataUrl.areas.accounts.length = 0
  updateHistoryData = []
  edit = false
  create = false
  showAttach = false
  orgAdd = false
  accAdd = false
  deviceAdd = false
  documentVersion = null
  restrictMovement(false)

  if (tinymce.activeEditor) {
    const editor = document.getElementById(tinymce.activeEditor.editorContainer.id)

    if (editor)
      editor.remove()

    tinymce.activeEditor = null
    tinymce.remove()
  }
}

function getLocation() {
  initialize()

  m.request({
    method: 'GET', url: `${DataUrl.const.base}locations/${DataUrl.areas.deviceconnections.location}`
    , config: DataUrl.const.config
  }).then(data => {
    DataUrl.areas.locations = data

    if (data.reference)
      window.document.title = data.reference

    documentVersion = data.__v

    DataUrl.areas.locations.email.map((val, i) => {
      const start = val.indexOf('SMTP:') + 5

      if (start > 4) {
        const end = val.indexOf('>', start)

        DataUrl.areas.locations.email[i] = val.substring(start, end)
      }
    })

    DataUrl.areas.locations.notes = removeFormat(DataUrl.areas.locations.notes || '')

    getUpdateHistory()
    loadEditor()
    conflict()

  }).then(() => {
    const searchData = {
      conditions: {
        location: DataUrl.areas.deviceconnections.location,
        includeXXX: window.includeXXX || undefined
      }
    }

    m.request({
      method: 'GET',
      url: `${DataUrl.const.base}devices?select=lifelineId hardwareId hostname type&sort=hostname`,
      data: searchData,
      config: DataUrl.const.config
    }).then(data => {
      DataUrl.areas.devices = data
    })
  }).then(() => {
    DataUrl.areas.locations.organizations.map(organization => {
      m.request({
        method: 'GET', url: `${DataUrl.const.base}organizations/${organization}`, config: DataUrl.const.config
      }).then(data => {
        DataUrl.areas.organizations.push(data)
      })
    })
  }).then(() => {
    if (!DataUrl.areas.locations.accounts[0])
      return DataUrl.areas.accounts = []

    DataUrl.areas.locations.accounts.map(account => {
      m.request({
        method: 'GET', url: `${DataUrl.const.base}c5accounts/${account}`, config: DataUrl.const.config
      }).then(data => {
        delete data.accountingRow
        data.subscription = ['None', 'Music', 'Video', 'Music & Video'][data.subscription]
        DataUrl.areas.accounts.push(data)
      })
    })
  })
}

function getUpdateHistory() {
  const history = DataUrl.areas.locations.updates

  if (history.length < 1)
    return null

  const ids = []

  DataUrl.areas.locations.updates = []

  history.reverse().map((val, i) => {
      ids.push(val.user)
      DataUrl.areas.locations.updates.push(val)
  })

  updateHistoryData = User.query({ conditions: { _id: { $in: ids.filter((item, i, ar) => {
    return ar.indexOf(item) === i
  }) } } })
}

function loadLocation(location, device) {
  if (location) {
    DataUrl.areas.deviceconnections = { location: location }
    getLocation()
  } else if (m.route.param('id') === 'new') {
    initialize()
    create = true
    edit = true
    DataUrl.areas.locations = createLocation()
  } else {
    DataUrl.const.device = device
    initialize()
  }
}

function savedLocation(area) {
  Notification.info('Saved', `${area} updated at: ${new Date().toLocaleString()}`, 8000)
  documentVersion += 1
}

function populateLocation(data) {
  return new Promise((resolve, reject) => {
    Location.push(DataUrl.areas.deviceconnections.location, data).then(() => {
      resolve()
    }, () => {
      reject(failUpdate())
    })
  })
}

function saveNotes(data) {
  restrictMovement(false)

  populateLocation({ notes: data, __v: documentVersion }).then(() => {
    savedLocation('Text')
    m.redraw()

    localStorage.removeItem(m.route())

    Location.getThen(DataUrl.areas.deviceconnections.location).then(res => {
      DataUrl.areas.locations.notes = res.notes
    })
  })
    .catch(e => {
      tinymce.activeEditor.setDirty(true)

      Notification.error('FAILED!', 'Possible conflict with a previous save. Try again or reload the page.', 10000)
    })
}

function removeFormat(str) {
  const start = str.search(/<body>/i)

  if (start > -1)
    str = str.replace(str.substring(0, start + 6), '')

  str = str.replace(/Arial/g, 'Verdana')

  return str
}

function printpage() {
  const notes = document.createElement('div')
  const content = document.getElementById('page')
  const devices = document.getElementById('devicesAttached').innerHTML.replace(/white/g, 'black')
  const table = content.getElementsByTagName('table')[0]
  const notesText = tinymce.activeEditor.getContent().replace(/10pt/g, '9pt')
  const pageTitle = document.title
  const history = document.getElementById('updateHistory')

  history && history.remove()

  document.title = `${new Date().toLocaleTimeString().replace(/\./g, ':')} - Printed by: ${math.capitalize(login.user().username)}`
  notes.innerHTML = '<br>Devices: ' + devices + '<br><h1>Notes: </h1>' + notesText

  table.parentNode.insertBefore(notes, table.nextSibling)

  window.print()

  table.parentNode.removeChild(notes)
  document.title = pageTitle
}

function loadEditor() {
  tinymce.EditorManager.editors = []

  if (tinymce.activeEditor) {
    if (document.getElementById(tinymce.activeEditor.editorContainer.id))
      return tinymce.activeEditor.setContent(DataUrl.areas.locations.notes)

    const editor = document.getElementById(tinymce.activeEditor.editorContainer.id)

    if (editor)
      document.getElementById(tinymce.activeEditor.editorContainer.id).remove()

    tinymce.activeEditor = null
    tinymce.remove()
    return loadEditor()
  }

  tinymce.init({
    selector: 'div#notes',
    theme: 'modern',
    menubar: false,
    statusbar: false,
    height: '65vh',
    allow_conditional_comments: false,
    paste_as_text: true,
    toolbar: [
      'undo redo save pastetext fullscreen | insert insertdatetime | table | styleselect | fontselect  fontsizeselect | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
    ],
    plugins: [
      'advlist autolink lists link image charmap preview anchor',
      'searchreplace visualblocks code fullscreen save paste',
      'insertdatetime media table contextmenu code textcolor'
    ],
    contextmenu: 'copy | link image inserttable | cell row column deletetable | insertdatetime',
    insertdatetime_formats: ['%d-%m-%Y', '%d/%m/%Y', '%H:%M:%S', '%I:%M:%S %p'],
    save_onsavecallback: () => {
      saveNotes(tinyMCE.activeEditor.getContent())
    },
    setup:function(ed) {
      ed.on('init', () => {
        tinymce.activeEditor.setContent(DataUrl.areas.locations.notes)
      })
      ed.on('change', () => {
        window.localStorage.setItem(m.route(), tinymce.activeEditor.getContent())

        restrictMovement(true)
      })
    }
  })

  if (tinymce.EditorManager.editors.length < 1)
    setTimeout(loadEditor, 50)

}

function iconNameRender(width, src) {
  return m('img', { style: { cursor: 'pointer', marginRight: '5px' }, width: `${width}px`, src: src })
}

function input(obj, key, label) {
  if (!obj)
    obj = { }

  function prop(c, key) {
    if (!c)
      c = { }

    c = m.prop(c)

    return function(value) {
      if (c()[key] == null)
        c()[key] = ''

      if (arguments.length > 0) {
        c()[key] = value
        c(c())
      }

      return c()[key]
    }
  }

  return m(textfield, { name: key, label: label, value: prop(obj, key) })
}

function tagfieldRender(obj, name) {
  return edit ? m(tagfield, { name: name, value: m.prop(obj ? obj : []) }) :
    obj ? obj.map(val => m('tr', iconNameRender(iconSize, '../../../images/phone.svg'), val)) : null
}

function contactCardRender(contact) {
  return m('tr', m('td', { colspan: 2, style: { 'font-weight': 'bold', 'padding-bottom': '20px' } },
    edit ? input(contact, 'name') :
      m('tr', contact.name),
    edit ? input(contact, 'contact', 'att') :
      contact.contact ? m('tr', `Att: ${contact.contact}`) : null,
    edit ? input(contact, 'street') :
      m('tr', contact.street),
    edit ? input(contact, 'street2', 'street 2') :
      m('tr', contact.street2),
    edit ? [input(contact, 'zip', 'zipcode'), input(contact, 'city'), input(contact, 'state')] :
      m('tr', `${contact.zip || ''} ${contact.city || ''}${contact.state ? `, ${contact.state}` : ''}`),
    edit ? null : contact.country ? m('tr', contact.country.name) : null,

    tagfieldRender(contact.phone, 'Phone'),
    tagfieldRender(contact.mobile, 'Mobile'),

    edit ? m(tagfield, { name: 'Email', value: m.prop(contact.email ? contact.email : []) }) :
      contact.email ? contact.email.map(val => m('tr', m('a', { href: `mailto:${val}` },
        iconNameRender(iconSize, '../../../images/email.svg'), val))) : null,

    edit ? m(search, { search: 'countries', label: 'Country',
      styleInput: { margin: '20px 0 0', position: 'relative', left: 0 },
      maxHeight: 900, resultsTop: '40px', resultsLeft: '0px',
      defaultValue: contact.country ? contact.country.name || '' : null, onclick: item => {
        delete item._id
        contact.country = item
      } }) : null
  ))
}

function blockCardRender(card, type) {
  const name = type === 'account' ? `${card.name}, ${card.accountingId.replace(/\s+/g, '')}` : card.name

  return [Object.entries(card).map(([k, v]) => {
    if (['modified', 'created', 'lastModified', 'lastChanged'].includes(k))
      return null

    if (k === 'name' && type === 'organization')
      return m('a', { href: `/organizations/${card._id}`, config: m.route }, keyPairRender(k, v))
    if (type === 'account' && k === 'invoiceAccount' && card.partner)
      return m('a', { href: `/partners/${card.partner._id}`, config: m.route }, keyPairRender(k, v))
    if (k === 'partner')
      return

    /*     if (type === 'account' && k === 'partner' && card.partner) {
      return m('div', {
        style: { marginTop: '10px' }
      }, 'Partner:',
      Object.entries(card.partner).map(([key, value]) => {
        if (['name'].includes(key))
          return keyPairRender(key, value)
      })
      )
    } */

    return keyPairRender(k, v)
  }),
  type === 'partner' ? null :
    m('span', { style: { 'font-size': '10px', cursor: 'hand' }, onclick: () => {
      dialog.prompt({
        title: 'REMOVE',
        description: `Are you sure you want to remove <b>${name}</b> ${type} from <b>${DataUrl.areas.locations.reference}</b> location?`,
        confirmText: 'remove',
        confirm: () => {
          removeAttachment(type, card._id)
        } })
    } }, iconNameRender(iconSize, '../../../images/remove.svg')),
  (type === 'organization') ? metadataRender(card) : null
  ]
}

function metadataRender(metadata) {
  return m('tr', m('td', { colspan: 2 }, m('p', { style: { 'font-style': 'italic', 'font-size': '12px' } },
    `Created: ${math.convertDateTime(metadata.created)} | Modified: ${math.convertDateTime(metadata.lastModified ?
      metadata.lastModified : metadata.modified)}`)))
}

function keyPairRender(key, value) {
  if (key === '_id' || key === '__v' || value.length === 0)
    return null

  return m('tr', [
    key ? m('td', `${math.capitalize(key)}:`) : null,

    m('td', value)
  ])
}

function linkRender(link, i, type) {
  let href = `fs.html?file=${DataUrl.areas.locations._id}/${type}/${i}`
    , icon
    , title

  document.cookie = `token=${login.user().token}; path=/`

  if (type === 'attachment') {
    icon = `../../../images/${attachmentIcon(link.name)}.svg`
    title = link.name.replace(/(.msg$)|(.xls$)|(.xlsx$)|(.xltx$)|(.mht$)/i, '')
  } else if (type === 'email') {
    icon = `../../../images/${attachmentIcon('.eml')}.svg`
    title = link.subject.replace('.eml', '')
  } else {
    icon = '../../../images/attachment.svg'
    title = link.subject
  }

  href = (type === 'attachment') ? `${href}/${link.name}` : href
  href = `${href}&type=${type}&name=${title}`

  return m('tr',
    m('a', { href: href, target: '_blank' },
      iconNameRender(30, icon), title),
    m('span', { style: { 'font-size': '10px', cursor: 'hand' }, onclick: () => {
      dialog.prompt({
        title: 'REMOVE',
        description: `Are you sure you want to remove <b>${title}</b> attachment from <b>${DataUrl.areas.locations.reference}</b> location?`,
        confirmText: 'remove',
        confirm: () => {
          removeAttachment(type, link._id)
        } })
    } }, '  ', iconNameRender(iconSize, '../../../images/remove.svg'))
  )
}

function devicesAttached() {
  return [m('#devicesAttached.input.tags.filled', { style: { margin: 0, padding: 0, borderBottom: 'none' } },
    DataUrl.areas.devices.length === 0 ? 'No devices attached to this location ' : '',
    m('ul', [
      DataUrl.areas.devices.map(val => {
        const connected = val.connected ? 'green' : 'red'
          , connectedType = Device.types[val.type] === 'BP' ? 'black' : val.connectedMediabutler ? 'green' : 'red'

        return m('li.raised',
          { style: { padding:0, cursor: 'pointer', color: 'white', background: connected, width: edit ? '175px' : '152px', textOverflow: 'clip' },
            onclick: () => {
              edit ? dialog.prompt({
                title: 'DETACH',
                description: `Are you sure you want to detach <b>${val.hostname}</b> from \n<b>${DataUrl.areas.locations.reference}</b> location?`,
                confirmText: 'detach',
                confirm: () => {
                  attachLocation(null, val.id)
                }
              }) : m.route(`/devices/${val.id}`)
            } }, m(`span${val.amuseicLocation ? val.amuseicLocation.indexOf('XXX') > -1 ? '.crossed-out' : '' : ''}`,
            { style: { zIndex: 2 } }, m('span' + b.bc('white').mr(4).br(2).p(4).c(connectedType), Device.types[val.type]), val.hostname),
          edit ? m('i.icon-cancel', { style: { opacity: 1 } }) : null)
      })
    ]),
    edit ? null :
      [m('span', { style: { display: deviceAdd ? '' : 'none' } },
        m(search, { search: 'devices/lonely', label: 'Homeless device', onclick: item => {
          dialog.prompt({
            title: 'Attach device',
            description: 'Are you sure you want to attach <b>' + (item.hostname || item.serialNumber || item.hardwareId) +
            '</b> to ' + DataUrl.areas.locations.reference + ' location?',
            confirmText: 'attach',
            confirm: () => {
              Device.push(item._id, { location: DataUrl.areas.locations._id }).then((res) => {
                m.route('/devices/' + item._id)
              }, () => {
                failUpdate()
              })
            }
          })
        },
        styleInput: { maxWidth: '362px', position: 'relative' }, visible: value => deviceAdd = value })),
      m('li.raised', { style: { width: '37px', display: deviceAdd ? 'none' : '' } },
        m('label', { for: 'searchInputdevices/lonely',
          style: { display: deviceAdd ? 'none' : '', cursor: 'hand', position: 'initial', opacity: 1 }, onclick: () => {
            deviceAdd = true
          } }, iconNameRender(iconSize, '../../../images/add.svg')))]
  )]
}

function getAttachments(type, i) {
  const data = []

  if (!DataUrl.areas.locations[`${type}s`])
    return data

  DataUrl.areas.locations[`${type}s`].map(val => {
    if (val === i)
      return null

    data.push(val)
  })
  return data
}

function removeAttachment(type, id) {
  save()

  const remove = { }

  if (type === 'organization' || type === 'account') {
    remove[type + 's'] = getAttachments(type, id)
    populate(remove)
  } else {
    remove[type + 's'] = { _id: id }
    fileServer(remove, 'delete')
  }
}

function save() {
  saving = true
  m.redraw()
}

function uploadFiles(files) {
  if (saving)
    return m.redraw(Notification.error('Failed!', 'Wait until all files are uploaded before uploading new files'))

  if (files.length === 0) {
    return m.redraw(Notification.error('Error!',
      'Could not upload, make sure you\'re dragging files from the file system and not e-mail client.'))
  }

  save()

  const uploadQuee = {
    attachments: 0,
    attachmentsLoaded: 0,
    emls: 0,
    emlsLoaded: 0,
    quee: [],
    add: type => {
      uploadQuee[type] += 1
    },
    upload: (type, files) => {
      uploadQuee.add(type + 'Loaded')

      if (uploadQuee[type] === uploadQuee[type + 'Loaded'])
        uploadQuee.populate(files)
    },
    populate: files => {
      uploadQuee.quee.push(files)

      if (uploadQuee.attachments + uploadQuee.emls === uploadQuee.attachmentsLoaded + uploadQuee.emlsLoaded) {
        const upload = {}

        uploadQuee.quee.map((val, i) => {
          Object.keys(uploadQuee.quee[i]).map((k) => {
            upload[k] = uploadQuee.quee[i][k]
          })
        })

        fileServer(upload, 'put')
      }
    }
  }

  const pdfFiles = []
    , emlFiles = []

  for (let i = 0; i < files.length; i++) {
    if (files[i].name.match(/(.eml$)/i))
      uploadQuee.add('emls')
    else uploadQuee.add('attachments')
  }

  for (let i = 0; i < files.length; i++) {
    const fileReader = new FileReader()

    fileReader.readAsBinaryString(files[i])
    if (files[i].name.match(/(.eml$)/i)) {
      fileReader.onload = fileLoadedEvent => {
        emlFiles.push({ subject: files[i].name, content: fileLoadedEvent.target.result })
        uploadQuee.upload('emls', { emails: emlFiles })
      }
    } else {
      fileReader.onload = fileLoadedEvent => {
        pdfFiles.push({ name: files[i].name, content: math.covertToByteArray(fileLoadedEvent.target.result) })
        uploadQuee.upload('attachments', { attachments: pdfFiles })
      }
    }
  }
}

function fileServer(data, method) {
  m.request({
    method: method, url: `${DataUrl.const.base}fs/${DataUrl.areas.deviceconnections.location}`,
    config: DataUrl.const.config, data: data
  }).then((data) => {
    if (data.nModified > 0)
      getFiles()
    else Notification.error('Failed!', 'Attachments have not been updated!')
  }, () => {
    Notification.error('Failed!', 'Attachments have not been updated!')
  }).then(() => {
    saving = false
  })
}

function getFiles() {
  Location.getThen(DataUrl.areas.deviceconnections.location).then(res => {
    DataUrl.areas.locations.attachments = res.attachments
    DataUrl.areas.locations.posts = res.posts
    DataUrl.areas.locations.emails = res.emails
    m.redraw()
  })
}

function populate(data) {
  populateLocation(data).then(() => {
    saving = false

    Location.getThen(DataUrl.areas.deviceconnections.location).then(res => {
      const updating = Object.keys(data)
        , updateArea = updating[0] === 'organizations' ? Organization : Account

      DataUrl.areas[updating] = []
      DataUrl.areas.locations[updating] = res[updating]

      res[updating].map(updatedId => {
        updateArea.getThen(updatedId).then(org => {
          DataUrl.areas[updating].push(org)

          m.redraw()
        })
      })

      m.redraw()
    })
  })
}

function populateCB(data) {
  populateLocation(data).then(() => {
    savedLocation('Location')
  })
}

function editLocationBtn() {
  const style = edit ? { top: m.route.param('section') === 'location' ? '206px' : '82px',
    position: 'fixed', backgroundColor: '#ededed', zIndex: 1, padding: '10px', width: '470px' }
    : { padding: '0 0 46px' }

  return m('p', { style: style },
    edit ? [m('button', { onclick: () => {
      if (m.route.param('id') === 'new')
        return m.route('/locations/')

      edit = false
      DataUrl.areas.locations = oldData
      tinymce.EditorManager.editors[0].show()
      create = false
      oldData = null
    } }, 'Cancel'),
    m('button.online', { onclick: () => {
      const update = { }

      Object.keys(oldData).map(key => {
        if (DataUrl.template.location.includes(key) && oldData[key]) {
          if (['email', 'phone', 'mobile'].includes(key)) {
            if (DataUrl.areas.locations[key][0] || oldData[key][0])
              update[key] = DataUrl.areas.locations[key]
          } else {
            update[key] = DataUrl.areas.locations[key]
          }
        }
      })

      Object.keys(DataUrl.areas.locations).map(key => {
        if (DataUrl.template.location.includes(key) && DataUrl.areas.locations[key] && !update[key]) {
          if (['email', 'phone', 'mobile'].includes(key)) {
            if (DataUrl.areas.locations[key][0])
              update[key] = DataUrl.areas.locations[key]
          } else {
            update[key] = DataUrl.areas.locations[key]
          }
        }
      })

      if (create) {
        if (!update.reference)
          return Notification.error('Required field', 'You must specify reference')

        createNewLocation(update)
      } else {
        populateCB(update)
        edit = false
        oldData = null
      }
    }, style: { 'margin-right': '10px' } }, create ? 'create' : 'Save'),
    create ? '' : [m('button', { style: { float: 'left', 'margin-left': '5px' },
      onclick: function() {
        create = true
        this.remove()
        DataUrl.areas.locations.reference = ''
      } }, 'Copy'),
    m('button', { style: { float: 'left', 'margin-left': '5px' }, onclick: function() {
      dialog.prompt({
        title: 'DELETE',
        description: `Are you sure you want to delete <b>${DataUrl.areas.locations.reference}</b> location?`,
        confirmText: 'delete',
        confirm: () => {
          Location.remove(DataUrl.areas.deviceconnections.location).then(data => {
            m.route('/locations/')
          }, () => {
            failUpdate()
          })
        }
      })
    } }, 'DELETE')]] :
      m('button', { style: { float: edit ? '' : 'left' }, onclick: () => {
        edit = true
        orgAdd = false
        accAdd = false
        deviceAdd = false
        oldData = JSON.parse(JSON.stringify(DataUrl.areas.locations))
      } }, 'Edit location'),
    saving ? null : edit ? null
      : [m('input.addAtachment#file', { style: { 'padding-bottom': '5px' },
        type: 'file', name: 'file', multiple: 'multiple',
        onchange: m.withAttr('files', uploadFiles) }),
      m('button', m('label', { style: { cursor: 'pointer' }, for: 'file' }, 'Add files'))])
}

function createLocation() {
  const newLocation = { }

  if (m.route.param('id') === 'new')
    oldData = { openings: [null] }

  DataUrl.template.location.map(val => {
    if (['email', 'phone', 'mobile'].includes(val))
      newLocation[val] = []
    else if (val === 'outlookInfo')
      newLocation[val] = { categories: [] }
    else if (val === 'country')
      newLocation[val] = { name: '', code: '' }
    else if (['reminders', 'openings'].includes(val))
      newLocation[val] = []
  })

  return newLocation
}

function createNewLocation(data) {
  m.request({
    method: 'POST', url: `${DataUrl.const.base}locations`,
    config: DataUrl.const.config, data: data
  }).then(data => {
    m.route(`/locations/${data._id}`)
  }, () => {
    failUpdate()
  })
}

function attachLocation(location, device) {
  device ? device : device = DataUrl.const.device

  const update = { location: location }

  if (location !== null) {
    const origDevice = Device.get(device)
      , locations = origDevice.locations()

    locations.push({ created: Date.now(), location: location })

    update.locations = locations
  }

  Device.push(device, update)
    .then(() => {
      window.location.reload()
    }, () => {
      failUpdate()
    })
}

function noLocation() {
  const newLocation = createLocation()
  const iconSize = 30

  if (newLocation.name) {
    return [m('table', [
      m('tr', [
        m('td', { style: { 'vertical-align': 'initial', 'min-width': '400px' } }, [
          keyPairRender('Reference', newLocation.reference),
          contactCardRender(newLocation),

          Object.entries(newLocation).map(([key, value]) => {
            if (['notes', 'modified', 'accounts', 'created', 'outlookInfo', 'emails', 'lastModified', 'organizations',
              'email', 'phone', 'attachments', 'name', 'street', 'street2', 'zip', 'city', 'country', 'state', 'reference', 'mobile'].includes(key))
              return null

            return keyPairRender(key, value)
          }),

          m('td', m('div#notes', m.trust(newLocation.notes)))
        ])
      ])
    ]),
    loadEditor()]
  }

  return [
    m('div', 'No location is attached to this device'),
    m('table.monitors', { style: { 'margin-top': '10px' } },
      m('label', { for: 'searchInputlocations', onclick: () => {
        showAttach ? showAttach = false : showAttach = true
      } }, iconNameRender(iconSize, '../../../images/attach.svg')),
      showAttach ? m(search, { search: 'locations', styleInput: { margin: '-38px -25px' } }) : null
    )
  ]
}

function openingsRender(location) {

  function showOpening(index) {
    return oldData ? oldData.openings[index] != null : location.openings[index] != null
  }

  function getIndex(index) {
    let remIndex = null

    location.reminders.map((val, i) => {
      if (location.reminders[i]) {
        if (location.reminders[i].type === 'opening' && location.reminders[i].index === index)
          return remIndex = i
      }
    })

    return remIndex
  }

  function getValue(index, field) {
    const obj = location.reminders[getIndex(index)]

    return obj ? obj[field] || '' : ''
  }

  function setValue(val, index, field) {
    const remIndex = getIndex(index) || index
    const obj = location.reminders[index]

    if (obj) {
      location.reminders[remIndex][field] = val
    } else {
      location.reminders[remIndex] = { type: 'opening',
        index: index, date: math.addWorkDays(val, -8), reminder: math.addWorkDays(val, -8), status: 'open' }
    }
  }

  function render(name, index) {
    const primdate = location.openings[index] ? new Date(location.openings[index]) : null
    const alignRight = { style: { textAlign: 'right' } }
      , date = getValue(index, 'date')
      , reminder = getValue(index, 'reminder')
      , notesText = getValue(index, 'text')
      , status = getValue(index, 'status')
      , user = JSON.parse(atob(login.user().token.split('.')[1])).user

    return edit ?
      [m('tr', [
        m('td', { style: { textAlign: 'left' } }, `${name}: `),
        m('input', { type: 'date', valueAsDate: primdate,
          onchange: m.withAttr('valueAsDate', valueAsDate => {
            location.openings[index] = valueAsDate

            if (valueAsDate === null)
              setValue('closed', index, 'status')
            else setValue('open', index, 'status')

            setValue(math.addWorkDays(valueAsDate, -8), index, 'date')
            setValue(math.addWorkDays(valueAsDate, -8), index, 'reminder')
          }) })]),
      location.openings[index] ? [
        status !== 'closed' && location.openings[index] ? [m('tr',
          m('td', alignRight, 'Delivery date: '),
          m('input', { type: 'date', valueAsDate: new Date(date),
            onchange: m.withAttr('valueAsDate', valueAsDate => {
              setValue(valueAsDate, index, 'date')
            }) })),
        m('tr',
          m('td', alignRight, 'Remind date: '),
          m('input', { type: 'date', valueAsDate: new Date(reminder),
            onchange: m.withAttr('valueAsDate', valueAsDate => {
              setValue(valueAsDate, index, 'reminder')
            }) })),
        m('tr', [
          m('td', alignRight, 'Order note: '),
          m('input', { type: 'text', value: notesText,
            onchange: m.withAttr('value', value => {
              setValue(value, index, 'text')
            }) })])] : '',
        showOpening(index) ?
          [m('tr', [
            m('td', alignRight, 'Order status: '),
            m('button', { style: { float: 'initial' }, onclick: () => {
              if (status === 'open') {
                setValue(new Date(), index, 'closed')
                setValue(user, index, 'closedBy')
              } else {
                setValue(null, index, 'closed')
                setValue(null, index, 'closedBy')
              }

              setValue(status === 'open' ? 'closed' : 'open', index, 'status')
            } }, status === 'open' ? 'open' : 'closed')
          ])
          ] : null
      ] : null] : [
        m('p', { style: { margin: '15px' } }),
        m('td', `${name}: `),
        m('td', primdate.toLocaleDateString()),
        date && status !== 'closed' ? m('tr', m('p', { style: { paddingLeft: '40px' } },
          `Delivery: ${new Date(date).toLocaleDateString()}`)) : null,
        reminder && status !== 'closed' ? m('tr', m('p', { style: { paddingLeft: '40px' } },
          `Reminder: ${new Date(reminder).toLocaleDateString()}`)) : null,
        notesText && status !== 'closed' ? m('tr', m('p', { style: { paddingLeft: '40px' } },
          `Order note: ${notesText}`)) : null,
        status ? m('tr', m('p', { style: { paddingLeft: '40px' } },
          `Order status: ${status}`)) : null
      ]
  }

  return [(edit || showOpening(0)) ? render('Opening date', 0) : null,
    location.openings.map((val, i) => {
      if (edit && showOpening(i) || showOpening(i + 1))
        return [render('Re-opening date', i + 1)]
    })]
}

function updateHistory(history) {
  if (history.length < 1)
    return null

  history.map(val => {
    for (const history in updateHistoryData()) {
      if (updateHistoryData()[history]._id === val.user)
        return val.username = updateHistoryData()[history].username
    }
  })

  return m('p#updateHistory', { style: { marginTop: '20px' } }, 'Update history', [
    history.map(val => {
      return m('tr', [
        m('td', new Date(val.date).toLocaleString()),
        m('td', '- ' + val.username)
      ])
    })
  ])
}

function conflict() {
  const conflict = window.localStorage[m.route()]

  if (conflict) {
    dialog.prompt({
      title: 'Unsaved changes detected',
      description: '<table class="conflict"><tr><th>Saved content</th><th>Your changes</th></tr><tr><td>'
        + DataUrl.areas.locations.notes + '</td><td>' + conflict + '</td></tr></table>',
      dialogStyle: { width: 'initial', maxWidth: '98vw', overflowY: 'auto', maxHeight: '98vh' },
      confirmText: 'Keep changes',
      cancelText: 'Discard changes',
      cancel: () => {
        localStorage.removeItem(m.route())
      },
      confirm: () => {
        tinymce.activeEditor.setContent(conflict)
        tinymce.activeEditor.setDirty(true)
      } })
  }
}

function deviceData() {
  if (!DataUrl.areas.locations)
    return noLocation()

  return [m('table', [
    m('tr', [
      m('td', { style: { verticalAlign: 'initial', minWidth: '470px', paddingTop: edit ? '46px' : '' } }, [
        editLocationBtn(),

        edit ? input(DataUrl.areas.locations, 'reference') :
          DataUrl.areas.locations.reference ? keyPairRender(null, DataUrl.areas.locations.reference) : null,

        contactCardRender(DataUrl.areas.locations),

        edit ? null : [
          m('td', { colspan: 2 }, [
            saving ? m('span', { style: { 'padding-bottom': '5px' } }, 'Saving... please wait...')
              : null,

            DataUrl.areas.locations.emails.map((email, i) => {
              return linkRender(email, i, 'email')
            }),

            DataUrl.areas.locations.attachments.map((attachment, i) => {
              return linkRender(attachment, i, 'attachment')
            }),

            DataUrl.areas.locations.posts.map((post, i) => {
              return linkRender(post, i, 'post')
            })
          ]),

          (DataUrl.areas.organizations) ? [
            m('p', { style: { 'margin-top': '15px', 'margin-left': '-5px' } }, 'Organizations: ',
              m('span', { style: { display: orgAdd ? '' : 'none' } },
                m(search, { search: 'organizations', styleInput: { margin: '-35px 36px', maxWidth: '362px' },
                  data: getAttachments('organization'), visible: value => orgAdd = value })),
              m('label', { for: 'searchInputorganizations',
                style: { display: orgAdd ? 'none' : '', 'font-size': '10px', cursor: 'hand' }, onclick: () => {
                  orgAdd = true
                } }, iconNameRender(iconSize, '../../../images/add.svg'))),
            DataUrl.areas.organizations.map((organization, i) => {
              return blockCardRender(organization, 'organization')
            })] : null,

          (DataUrl.areas.accounts) ? [
            m('td', { colspan: 2, style: { 'padding-bottom': '15px' } },
              m('p', { style: { 'margin-top': '10px', 'margin-left': '-5px' } }, 'Accounts: ',
                m('span', { style: { display: accAdd ? '' : 'none' } },
                  m(search, { search: 'c5accounts', styleInput: { margin: '-35px 36px', maxWidth: '362px' },
                    data: getAttachments('account'), visible: value => accAdd = value })),
                m('label', { for: 'searchInputc5accounts',
                  style: { display: accAdd ? 'none' : '', 'font-size': '10px', cursor: 'hand' }, onclick: () => {
                    accAdd = true
                  } }, iconNameRender(iconSize, '../../../images/add.svg'))),
              DataUrl.areas.accounts.map(account => {
                const sorting = ['subscription', 'accountingId', 'name', 'address', 'address2', 'zipCity', 'state', 'country', 'group', 'invoiceAccount']
                  , sorted = {}

                for (const s in sorting) {
                  if (account[sorting[s]])
                    sorted[sorting[s]] = account[sorting[s]]
                }

                if (sorted.accountingId && account.searchName)
                  sorted.accountingId = sorted.accountingId + ' - ' + account.searchName

                for (const s in account) {
                  if (!sorted[s] && !['searchName', 'children', 'syntax'].includes(s))
                    sorted[s] = account[s]
                }

                return blockCardRender(sorted, 'account')
              })
            )] : null
        ],
        (DataUrl.areas.locations.folder && !edit) ?
          m('a', { href: `/locations?folder=${DataUrl.areas.locations.folder}`, config: m.route },
            keyPairRender('Folder', DataUrl.areas.locations.folder)) : null,
        edit ? m(search, { search: 'lists/folders', label: 'Folder',
          styleInput: { margin: 0, position: 'relative', left: 0 }, styleSearch: { zIndex: 1, position: 'relative' },
          resultsTop: '40px', resultsLeft: '0px',
          defaultValue: DataUrl.areas.locations.folder ? DataUrl.areas.locations.folder || '' : null,
          onclick: folder => {
            DataUrl.areas.locations.folder = folder
          } }) : null,

        Object.entries(DataUrl.areas.locations).map(([key, value]) => {
          if (['notes', 'modified', 'accounts', 'created', 'outlookInfo', 'emails', 'lastModified', 'organizations',
            'email', 'phone', 'attachments', 'name', 'street', 'street2', 'zip', 'city', 'country', 'state', 'reference',
            'mobile', 'contact', 'folder', 'notesText', 'reminders', 'openings', 'geo', 'lastUpdated', 'updates'].includes(key))
            return null

          return keyPairRender(key, value)
        }),

        edit ? m(tagfield, { name: 'categories', suggest: 'categories',
          value: m.prop(DataUrl.areas.locations.outlookInfo ? DataUrl.areas.locations.outlookInfo.categories : []) }) :
          DataUrl.areas.locations.outlookInfo ?
            keyPairRender('Categories', DataUrl.areas.locations.outlookInfo.categories.join('; ')) : null,

        openingsRender(DataUrl.areas.locations),

        (DataUrl.areas.locations.created && !edit) ? metadataRender(DataUrl.areas.locations) : null,

        DataUrl.areas.locations.updates ? updateHistory(DataUrl.areas.locations.updates) : null
      ]),
      m('td', { style: { 'vertical-align': 'top' } }, [
        create ? null : m('tr', [
          m('td', devicesAttached()),
          m('td', m('button', { style: { float: 'right' }, onclick: printpage }, 'print' ) )]),
        m('tr', [
          m('td', { colspan: 2 },
            m('div#notes', { style: { visibility: 'hidden' } }))
        ])
      ])
    ])
  ])]
}

export default {
  deviceData,
  uploadFiles,
  removeAttachment,
  populate,
  restrictMovement,
  loadLocation,
  attachLocation
}
