import m from 'mithril'

const dialog = {

  show: m.prop(false),

  state: {},

  prompt(state) {
    dialog.state = state
    dialog.show(true)
  }

}

export default dialog
