import api from '../api'

const Device = api.resource({
  path: 'devices',
  populate: { path: 'location', select: 'reference' },
  keyName: '_id',
  schema: {
    type              : String,
    serialNumber      : String,
    lifelineVersion   : String,
    lifelineId        : String,
    hostname          : String,
    connected         : Boolean,
    connectedMediabutler: Boolean,
    lastConnected     : null,
    os                : String,
    hardwareModel     : String,
    organizations     : Array,
    location          : String,
    tags              : Array,
    ip                : String,
    networkInterfaces : Array,
    partitions        : Array
  }
})

Device.types = {
  mediabutler: 'MB',
  beatplayer: 'BP',
  beamsign: 'BS'
}

export default Device
