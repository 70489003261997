import m from 'mithril'
import page from '../components/page'
import User from '../models/user'
import itemView from './users.single'

export default m(page, {
  Model       : User,
  keyName     : 'username',
  heading     : 'name',
  description : 'username',
  itemView    : itemView,
  route       : 'users',
  title       : 'Users'
})
