import m from 'mithril'

export default {

  view(ctrl, attrs) {
    const text = attrs.text[attrs.value.indexOf(attrs.getValue)]

    return m('a.round.button.raised',
      { style: { width: 'initial', marginLeft: '10px', float: 'initial' }, onclick: attrs.onclick }, text)
  }
}
