import m from 'mithril'
import b from 'bss'
import login from '../models/login'
import math from '../helper/math'
import dialog from '../models/dialog'
import Notification from '../models/notification'
import modal from '../components/modal'

let auth

const DataUrl = {
  const: {
    config: xhr => {
      xhr.setRequestHeader('Authorization', auth)
    },
    server: null,
    device: null
  },
  areas: {
    results: null,
    filemanager: null
  },
  uploads: [],
  command: 'dir'
}

function getDeviceData(lifelineId) {
  auth = `Bearer ${login.user().token}`
  DataUrl.const.server = `https://${login.user().lifesupport}/`

  DataUrl.const.device = null
  DataUrl.areas.results = null
  DataUrl.areas.filemanager = null
  DataUrl.uploads = []

  m.request({
    method: 'GET',
    url: `${window.apiUrl}stations?sort={"created":-1}&conditions={"lifelineId": "${lifelineId}"}&populate=applications.application`,
    config: DataUrl.const.config
  }).then(data => {
    DataUrl.areas.results = data[0]

    if (!data[0])
      return DataUrl.areas.results = { error: 'LifeSupport not found!' }

    DataUrl.const.device = data[0]._id

    requestDir()
  })
}

function isOnline(status) {
  let style = '.online'

  if (status !== 'connected')
    style = '.offline'

  return m(`button${style}`, status)
}

function checkSize(key, val) {
  if (['FreeSpace', 'Size', 'size'].includes(key))
    return sizeConverter(val)
  else if (['atime', 'mtime', 'ctime', 'birthtime'].includes(key))
    return math.convertDateTime(val)

  return val
}

function sizeConverter(size) {
  if (size === 0)
    return ''

  const kb = (size / 1024).toFixed(2)

  let mb

  if (kb > 1025)
    mb = (kb / 1024).toFixed(2)
  else return kb + ' KB'

  if (mb > 1025)
    return (mb / 1024).toFixed(2) + ' GB'

  return mb + ' MB'
}

function makeRequest(args, then) {
  m.request({
    method: 'POST',
    url: DataUrl.const.server + `stations/${DataUrl.const.device}/command`,
    data: { application: 'FileManager', arguments: args },
    config: DataUrl.const.config
  }).then(then)
}

function requestDir() {
  makeRequest(DataUrl.command, filemanager => {
    DataUrl.areas.filemanager = filemanager
  })
}

function download(file) {
  requestFile(file, url => url, false)
}

function view(file) {
  const ext = file.split('.').pop()

  if (['jpg', 'png', 'gif', 'tif'].includes(ext.toLowerCase())) {
    requestFile(file, url => {
      url = url.split('/')
      url.splice(url.length - 1, 0, 'view')

      return url.join('/')
    }, true)
  } else {
    makeRequest(DataUrl.command.replace('dir', 'download') + file, res => {
      if (res.error)
        return Notification.error(res.error.code, JSON.stringify(res.error))

      if (res.results && res.results.url) {
        m.request({
          method: 'GET',
          url: res.results.url,
          deserialize: data => data
        }).then(data => {
          const head = document.getElementsByTagName('head')[0]
            , type = getMode(res.results.url)

          if (document.getElementsByTagName('head')[0].innerHTML.indexOf('codemirror.css') === -1) {
            const style = document.createElement('link')

            style.href = window.location.origin + '/js/code/lib/codemirror.css'
            style.rel = 'stylesheet'
            style.type = 'text/css'

            head.appendChild(style)
          }

          const code = m('textarea#code', {
            config: (element, isInit, context) => {
              function create() {
                if (typeof CodeMirror === 'undefined')
                  return setTimeout(create, 100)

                CodeMirror.fromTextArea(element, {
                  value: data,
                  mode: type,
                  lineNumbers: true
                })
              }

              create()

              context.onunload = () => document.getElementsByClassName('modal-body')[0].innerHTML = ''
            }
          }, data)

          modal.Modal.data(code)
          modal.Modal.template('code')
          modal.Modal.show()
        })
      }
    })
  }
}

function getMode(file) {
  let type

  file = file.split('/').pop().split('.')

  switch (file[file.length - 1]) {
  case 'html':
    type = 'xml'
    break
  default:
    type = 'javascript'
    break
  }

  const head = document.getElementsByTagName('head')[0]
    , includeCode = '/js/code/lib/codemirror.js'
    , includeJS = '/js/code/mode/' + type + '/' + type + '.js'

  if (head.innerHTML.indexOf(includeCode) === -1) {
    const tag = document.createElement('script')

    tag.src = includeCode
    head.appendChild(tag)

    tag.onload = () => loadType()
  } else {
    loadType()
  }

  function loadType() {
    if (head.innerHTML.indexOf(includeJS) === -1) {
      const tag = document.createElement('script')

      tag.src = includeJS
      head.appendChild(tag)
    }
  }

  return type
}

function requestFile(file, urlFn, newTab) {
  makeRequest(DataUrl.command.replace('dir', 'download') + file, res => {
    if (res.error)
      return Notification.error(res.error.code, JSON.stringify(res.error))

    if (res.results && res.results.url) {
      const link = document.createElement('a')

      link.setAttribute('href', urlFn(res.results.url))

      if (newTab)
        link.setAttribute('target', 'blank')

      document.body.append(link)

      setTimeout(() => {
        link.click()
        document.body.removeChild(link)
      }, 800)

      Notification.info('Download started', 'File: ' + file)
    }
  })
}

function rename(file) {
  modal.Modal.title = 'Rename ' + file
  modal.Modal.newData()[0] = file
  modal.Modal.template('renameFile')

  modal.Modal.data(newName => {
    makeRequest(DataUrl.command.replace('dir', 'rename') + file + ',' + newName, res => {
      if (res.error)
        return Notification.error(res.error.code, JSON.stringify(res.error))

      if (res.results && res.results.renamed) {
        Notification.info('Successfully renamed', file + ' to ' + newName)
        requestDir()
      }
    })
  })

  modal.Modal.show()
}

function uploadFiles(files) {
  if (files.length === 0) {
    return m.redraw(Notification.error('Error!',
      'Could not upload, make sure you\'re dragging files from the file system and not e-mail client.'))
  }

  const queue = []

  for (let i = 0; i < files.length; i++) {
    const formData = new FormData()

    formData.append('file' + i, files[i])

    const file = { name: files[i].name, data: formData, fn: () => upload(i) }

    if (DataUrl.areas.filemanager.results.find(x => x.file === files[i].name)) {
      file.fn = () => {
        dialog.prompt({
          title: 'Replace',
          description: `The destination already has a file named <b>${files[i].name}</b>?`,
          confirmText: 'Replace',
          cancelText: 'Skip',
          confirm: () => setTimeout(() => upload(i), 300),
          cancel: () => setTimeout(() => next(i), 300)
        })
        m.redraw()
      }
    }

    queue.push(file)
  }

  next(-1)

  function next(i) {
    if (queue[i + 1])
      queue[i + 1].fn()
  }

  function upload(i) {
    makeRequest(DataUrl.command.replace('dir', 'upload') + queue[i].name, res => {
      if (res.results && res.results.url) {
        const addIndex = addStatus('uploading...', queue[i].name)

        m.redraw()

        m.request({
          method: 'POST',
          url: res.results.url,
          data: queue[i].data,
          serialize: data => data,
          deserialize: value => value
        }).then(() => {
          setStatus('uploaded', addIndex)
        })
      }
    })

    next(i)
  }
}

function del(file) {
  dialog.prompt({
    title: 'REMOVE',
    description: `Are you sure you want to delete <b>${file}</b>?`,
    confirmText: 'delete',
    confirm: () => {

      const addIndex = addStatus('deleting...', file)

      m.redraw()

      makeRequest(DataUrl.command.replace('dir', 'del') + file, () => {
        setStatus('deleted', addIndex)
      })
    } })
}

function addStatus(status, name) {
  DataUrl.uploads.push({ name, status })

  return DataUrl.uploads.length - 1
}

function setStatus(status, i) {
  DataUrl.uploads[i].status = status

  requestDir()
}

function unzip(file) {
  const addIndex = addStatus('unzipping...', file)

  m.redraw()

  makeRequest(DataUrl.command.replace('dir', 'unzip') + file, () => {
    setStatus('unzipped', addIndex)
  })
}

function getDir(dir) {
  const root = DataUrl.command.split(' ')

  if (root[1])
    DataUrl.command += dir + '\\'
  else DataUrl.command += ' ' + dir + '\\'

  requestDir()
}

function upDir() {
  const dirs = DataUrl.command.split('\\')

  if (dirs.length > 2)
    DataUrl.command = dirs.splice(0, dirs.length - 2).join('\\') + '\\'
  else
    DataUrl.command = 'dir'

  requestDir()
}

function uploadStatus() {
  return DataUrl.uploads.length > 0 ?
    m('div' +
    b
      .position('fixed')
      .r('50px')
      .b('50px')
      .w('500px')
      .bc('#fffcfc')
      .p('0px 20px 20px')
      ,
    [
      m('h3' +
        b
          .textAlign('end')
          .cursor('pointer')
        , {
        onclick: () => {
          DataUrl.uploads = []
        }
      }, 'x'),

      DataUrl.uploads.map(x =>
        m('div',
          m('span', x.name),
          m('span' +
            b
              .f('right')
              .c(['uploaded', 'unzipped', 'deleted'].includes(x.status) ? 'green' : '')
            , x.status)
        )
      )
    ]) : null
}

function renderFile(key, value, val) {
  return m('td', { onclick: () => {
    if (val.dir || (val.DeviceID || val.Caption))
      getDir(val.dir || (val.DeviceID || val.Caption))
    else Notification.error('Not a directory!', 'Cannot browse')
  } }, [
    (key === 'DeviceID' || key === 'Caption') ? iconRender('30px', '../../../images/drive.svg') :
      key === 'dir' ? iconRender('20px', '../../../images/folder.svg') :
        key === 'file' ? iconRender('20px', '../../../images/attachment.svg') : '',
    m('span', { style: { verticalAlign: 'super' } }, checkSize(key, value))
  ])
}

function iconRender(width, src) {
  return m('img', { style: { cursor: 'pointer', marginRight: '5px' }, width: `${width}px`, src: src })
}

function goUp() {
  return m('tr', [
    m(`button${DataUrl.command === 'dir' ? '.disabled' : ''}`, { disabled: DataUrl.command === 'dir',
      style: { margin: '5px', float: 'left', width: '85px' }, onclick: () => {
        upDir()
      } }, 'Go up'),
    m('button', { style: { margin: '5px', position: 'absolute' }, onclick: () => {
      requestDir()
    } }, 'Refresh')
  ])
}

function sortResults(res) {
  return res.filter(val => val.DeviceID || val.Caption).sort()
    .concat(res.filter(val => val.dir)
      .sort((a, b) => a.dir.toLowerCase() < b.dir.toLowerCase() ? -1 : 1))
    .concat(res.filter(val => val.file))
}

function getTitle(key) {
  switch (key) {
  case 'size':
    return 'Size'
  case 'atime':
    return 'Last accessed'
  case 'mtime':
    return 'Last modified'
  case 'ctime':
    return 'Last status changed'
  case 'birthtime':
    return 'Created at'
  case 'DeviceID':
  case 'Caption':
    return 'Drive'
  default:
    return key
  }
}

function filterStats(stat) {
  if (stat.constructor === Array)
    stat = stat[0]

  return ['dir', 'file', 'stats', 'DeviceID', 'Caption', 'FreeSpace', 'ProviderName', 'Size', 'VolumeName',
    'size', 'atime', 'mtime', 'ctime', 'birthtime'].includes(stat)
}

function deviceData() {
  if (DataUrl.areas.results) {
    if (DataUrl.areas.results.error)
      return m('h2', DataUrl.areas.results.error)
  }

  if (!DataUrl.areas.filemanager)
    return m('h2', 'Getting device...')

  if (DataUrl.areas.filemanager) {
    if (DataUrl.areas.filemanager.error && DataUrl.command === 'dir')
      return m('h2', DataUrl.areas.filemanager.error)
    else if (DataUrl.areas.filemanager.error)
      return [goUp(), m('h2', JSON.stringify(DataUrl.areas.filemanager.error))]
  }

  return m('.content', [
    m('.pageBar.raised', [
      isOnline(DataUrl.areas.results.status),
      m('div', `Station ID: ${DataUrl.areas.results._id} | LS Version: ${DataUrl.areas.results.version}`)
    ]),
    m('div', `Current direcory: ${DataUrl.command === 'dir' ? 'root' : DataUrl.command.replace('dir ', '')}`),
    m('div', 'Navigate to:', m('input', { style: { width: '27vw', marginLeft: '7px' }, onkeyup: e => {
      if (e.which === 13) {
        DataUrl.command = 'dir ' + e.target.value
        requestDir()
      }
    } })),
    m('table.filemanager', { style: { cursor: 'pointer' } }, [
      goUp(),
      m('tr', [
        m('th', ''),
        DataUrl.command === 'dir' ?
          Object.keys(DataUrl.areas.filemanager.results[0]).filter(filterStats).map(key => {
            return m('th', getTitle(key))
          }) : [m('th', 'Name'),
            DataUrl.areas.filemanager.results[0] ?
              Object.keys(DataUrl.areas.filemanager.results[0].stats).filter(filterStats).map(key => {
                return m('th', getTitle(key))
              }) : null]
      ]),
      sortResults(DataUrl.areas.filemanager.results).map(val => {
        return m('tr', [
          val.file || val.dir ? m('td', [
            m('span', { onclick: rename.bind(null, val.file || val.dir) },
              iconRender('30px', '../../../images/rename.png')),
            val.file ? m('span', { onclick: view.bind(null, val.file) },
              iconRender('30px', '../../../images/view.svg')) : null,
            m('span', { onclick: download.bind(null, val.file || val.dir) },
              iconRender('30px', '../../../images/download.svg')),
            m('span', { onclick: del.bind(null, val.file || val.dir) },
              iconRender('30px', '../../../images/remove.svg')),
            val.file && val.file.endsWith('.zip') ?
              m('span', { onclick: unzip.bind(null, val.file) },
                iconRender('30px', '../../../images/unzip.svg')) : ''
          ]) : m('td'),
          Object.entries(val).filter(filterStats).map(([key, value]) => {
            return key === 'stats' ?
              Object.entries(val.stats).filter(filterStats).map(([k, v]) => {
                return renderFile(k, v, val)
              }) : renderFile(key, value, val)
          })
        ])
      })
    ]),
    uploadStatus(),
    modal.modalWindow()
  ])
}

export default {
  getDeviceData,
  deviceData,
  uploadFiles
}
