import m from 'mithril'
import App from '../models/app'
import list from '../components/list'

export default {

  controller() {
    const id = m.route.param('id')

    const ctrl = {
      list : App.get(),
      app : id ? (id === 'new' ? App.create() : App.get(id)) : null
    }

    if (ctrl.app) {
      ctrl.app.on('deleted', m.route.bind(null, '/users'))
      ctrl.app.on('created', app => {
        m.route('/users/' + ctrl.app.key)
      })
    }

    return ctrl
  },

  view(ctrl) {
    return m('#apps', [
      m(list, {
        title        : 'Apps',
        createUrl    : 'new',
        route        : '/apps/',
        items        : ctrl.list(),
        itemId       : (p) => p._id,
        itemTitle    : (p) => p.title,
        itemSubtitle : (p) => p.version
      })
    ])
  }
}
