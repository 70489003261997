import api from '../api'

export default api.resource({
  path    : 'partners',
  autosave : false,
  schema  : {
    name           : String,
    includes       : Array
  }
})
