import m from 'mithril'
import Notification from '../models/notification'
import login from '../models/login'

let ws
  , sessionDocumentId
  , deviceId

  , connected = false
  , connectionStatus = 'Establishing connection to LS client...'

  , forceClose = false
  , timeout
  , txtTimeout

const pingInterval = 25000

  , oncloseFNs = new Map()
  , onopenFNs = new Map()
  , decodeFns = new Map()
  , appSpecificFns = new Map()

function getWSSession(stationDocumentId, { onclose, onopen, decode, appSpecific }) {
  if (!sessionDocumentId || sessionDocumentId !== stationDocumentId) {
    sessionDocumentId = stationDocumentId
    deviceId = m.route.param('id')
    clear()
  }

  onclose && oncloseFNs.set(onclose.key, onclose.fn)
  onopen && onopenFNs.set(onopen.key, onopen.fn)
  decode && decodeFns.set(decode.key, decode.fn)
  appSpecific && appSpecificFns.set(appSpecific.key, appSpecific.fn)

  connectWS()
}

function closeWSSession() {
  if (deviceId !== m.route.param('id')) {
    clearTimeout(timeout)
    clearTimeout(txtTimeout)

    if (ws) {
      forceClose = true
      ws.close()
    }
  }
}

function clear() {
  oncloseFNs.clear()
  onopenFNs.clear()
  decodeFns.clear()
  appSpecificFns.clear()

  ws = null
  connected = false
  connectionStatus = 'Establishing connection to LS client...'
  forceClose = false
}

function connectWS() {
  if (ws)
    return onopenFNs.forEach(fn => fn(ws))

  ws = new WebSocket('wss://' + login.user().lifesupport)

  ws.onclose = () => {
    if (forceClose)
      return clear()

    connected = false
    ws = null

    oncloseFNs.forEach(fn => fn())

    reconnect()

    m.redraw()
  }

  ws.onopen = () => {
    ws.send(JSON.stringify({ session: login.user().token, stationDocumentId: sessionDocumentId }))

    setTimeout(ping, pingInterval)

    function ping() {
      if (!connected)
        return

      ws.send('ping')
      setTimeout(ping, pingInterval)
    }
  }

  ws.onmessage = ({ data }) => {
    decode(JSON.parse(data))
  }
}

function reconnect() {
  const timer = 10

  clearTimeout(txtTimeout)
  clearTimeout(timeout)
  text(timer)

  function text(time) {
    connectionStatus = 'Connection closed... reconnecting in ' + time + ' seconds'
    time -= 1
    m.redraw()

    if (time === 0)
      return

    txtTimeout = setTimeout(() => {
      text(time)
    }, 1000)
  }

  timeout = setTimeout(connectWS, timer * 1000)
}

function decode(data) {
  const reply = data.reply
    , replyCode = reply ? reply.code : null
    , replyMsg = reply ? reply.msg : null
    , replyType = reply ? reply.type : null
    , fromLS = data.forward
    , fromLSType = fromLS ? data.forward.type : null
    , fromLSApp = fromLS ? data.forward.application : null

  if (reply) {
    switch (replyType) {
    case 'connect':
      if (replyCode === 1) {
        connected = true
        onopenFNs.forEach(fn => fn(ws))
      } else {
        connected = false
      }

      return m.redraw()
    case 'error':
      if (replyMsg && replyMsg.message === 'invalid token') {
        Notification.error('Wrong authorization', 'authenticate again')
        m.redraw()
      }

      return Notification.error(replyMsg)
    default:
      break
    }
  } else if (fromLS) {
    switch (fromLSType) {
    case 'disconnect':
      Notification.error('LifeSupport client disconnected')
      return ws.close()
    case 'transmit':
      return appSpecificFns.forEach((fn, key) => key === 'transmit' && fn(fromLS))
    case 'command':
      if (!fromLSApp)
        return

      return appSpecificFns.forEach((fn, key) => {
        if (key === fromLSApp)
          fn(fromLS)
      })
    default:
      break
    }
  }

  decodeFns.forEach(fn => fn(data))
}

export default {
  getWSSession,
  closeWSSession,
  send: msg => ws.send(JSON.stringify(msg)),
  connectionStatus: () => connectionStatus,
  connected: () => connected
}
