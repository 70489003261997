import api from '../api'

export default api.resource({
  path    : 'organizations',
  autosave : false,
  schema  : {
    fromAccount : String,
    name        : String,
    phone       : String,
    email       : String
  }
})
