import m from 'mithril'
import login from './models/login'
import header from './header/index'
import sidebar from './sidebar'
import notifications from './components/notifications'
import uploadDrop from './components/uploadDrop'
import dialog from './components/dialog'
import location from './components/location'
import ws from './helper/ws'

function Controller(module) {
  m.redraw.strategy('diff')
  if (!login.user())
    m.route('/login')

  this.controller = module.controller()
  this.view = module.view
  const self = this
  this.onunload = function(e) {
    window.document.title = 'Beat Internal'

    if (location.restrictMovement()) {
      const conf = confirm('Notes are not saved, are you sure you want to exit?')

      if (conf)
        location.restrictMovement(false)
      else e.preventDefault()
    }

    ws.closeWSSession()
    self.controller && self.controller.onunload && self.controller.onunload(self.controller)
  }
}

function view(ctrl) {
  return [
    m('main#main', {
      class: 'show'
    }, [
      m(header),
      m(sidebar),
      m(notifications),
      m('article#page', ctrl.view(ctrl.controller))
    ]),
    m(uploadDrop),
    m(dialog),
    m('#xhrProgressBar')
  ]
}

export default function(routes) {
  return Object.keys(routes).reduce((result, route) => {
    result[route] = {
      controller: function() {
        return new Controller(routes[route])
      },
      view: view
    }
    return result
  }, {})
}
