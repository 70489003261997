import m from 'mithril'
import List from '../models/list'
import Applications from '../models/applications'

function removeDublicate(array, existingValues, enteredValue) {
  existingValues.map(val => {
    array = array.filter(e => e._id ? e._id !== val : e !== val)
  })

  array = array.filter((item, pos) => array.indexOf(item) === pos)

  if (enteredValue) {
    const patt = new RegExp(enteredValue, 'i')

    array = array.filter(val => patt.test(val.name || val))
  }

  return array.sort()
}

export default {

  controller(args) {
    const ctrl = {
      focused: m.prop(),
      showSuggest: m.prop(),
      selected: m.prop(),
      value: m.prop(''),
      suggest: args.suggest ? args.suggest === 'lsApps' ? Applications.get() :
        List.query({ conditions: { type: args.suggest } })
        : null,

      isFocused: suggest => {
        setTimeout(() => {
          ctrl.showSuggest(suggest)
          m.redraw()

          if (suggest && !args.disableScroll)
            document.getElementById('page').scrollTop = document.getElementById('page').scrollHeight
        }, 300)
        ctrl.focused(suggest)
      },

      getSuggestions() {
        return ctrl.suggest ? ctrl.suggest()[0] ?
          removeDublicate(ctrl.suggest()[0].data || ctrl.suggest(), args.value(), ctrl.value()) : null : null
      },

      input(e) {
        if (!e.target.value && e.which === 8) {
          ctrl.remove(ctrl.selected())
          ctrl.showSuggest(true)
        } else if (e.which === 40) { // down
          if (ctrl.showSuggest()) {
            e.preventDefault()
            return selectNext()
          } else if (ctrl.getSuggestions()) {
            ctrl.isFocused(true)
          }

        } else if (e.which === 38) { // up
          e.preventDefault()
          return selectPrev()
        } else if (e.which === 13 && ctrl.selected()) {
          if (ctrl.suggest) {
            ctrl.addSuggested(ctrl.selected())
            e.target.value = ''
            ctrl.isFocused(true)
          }
        } else {
          if (ctrl.showSuggest()) {
            ctrl.value(e.target.value)
            ctrl.isFocused(false)
            ctrl.isFocused(true)
          }
          e.target.style.width = 'auto'
          window.requestAnimationFrame(() => {
            e.target.style.width = Math.min(e.target.scrollWidth, e.target.parentNode.clientWidth) + 'px'
          }, 0)
          m.redraw.strategy('none')
        }
      },

      add(e) {
        ctrl.selected('')
        const value = e.target.value

        if (args.value().indexOf(value) > -1)
          ctrl.selected(value)
        else if (value)
          args.value().push(value)

        e.target.value = ''
        ctrl.save()
      },

      addSuggested(item) {
        args.value().push(item)

        if (args.onchange)
          return args.onchange(args.value().map(v => v._id || v))

        ctrl.suggest()[0].data = ctrl.suggest()[0].data.filter(e => e !== item)
        ctrl.isFocused(false)
        ctrl.selected('')
        ctrl.value('')
        document.getElementById('input_' + args.name).value = ''
      },

      remove(value) {
        const index = args.value().indexOf(value)

        args.value().splice(index, 1)

        if (args.onchange)
          return args.onchange(args.value().map(v => v._id || v))

        if (ctrl.getSuggestions())
          ctrl.suggest()[0].data.push(value)

        ctrl.save()
      },

      save() {
        clearTimeout(ctrl.saveTimer)
        ctrl.saveTimer = setTimeout(() => {
          args.value(args.value())
        }, 200)
      },

      focus(e) {
        this.querySelector('input').focus()
      }
    }

    function flat() {
      return ctrl.getSuggestions()
    }

    function selectNext() {
      const f = flat()
        , index = f.indexOf(ctrl.selected())

      ctrl.selected(index < f.length - 1 ? f[index + 1] : f[f.length - 1])
    }

    function selectPrev() {
      const f = flat()
        , index = f.indexOf(ctrl.selected())

      ctrl.selected(index > 0 ? f[index - 1] : f[0])
    }

    return ctrl
  },

  view(ctrl, args) {
    return m('.input.tags', {
      onclick: ctrl.focus,
      class: args.suggest && args.suggest === 'lsApps' ? 'focus' :
        (ctrl.focused() ? 'focus' : '') +
             (args.value().length > 0 ? ' filled' : ''),
      style: { maxWidth: '1000px' }
    }, [
      m('ul', args.value().map(tag => {
        if (args.suggest && args.suggest === 'lsApps' && ctrl.suggest().length > 0) {
          const found = ctrl.suggest().find(x => x._id === tag)

          if (found)
            tag = found.name
        }

        return m('li.raised', {
          class: ctrl.selected() === tag ? 'selected' : ''
        }, [
          m('span', { style: { color: 'black' } }, tag),
          m('i.icon-cancel', {
            style: { color: 'black' },
            onclick: ctrl.remove.bind(null, tag)
          })
        ])
      })),
      m('input', {
        required: true,
        size: 1,
        id: 'input_' + args.name,
        onblur: ctrl.isFocused.bind(null, false),
        onfocus: ctrl.isFocused.bind(null, true),
        onkeydown: ctrl.input,
        onchange: ctrl.suggest ? null : ctrl.add
      }),
      m('label', { for: 'input_' + args.name, style: args.labelStyle }, args.label || args.name),
      ctrl.getSuggestions() && ctrl.showSuggest() ?
        m('#search', {
          style:  { position: 'relative' },
          class: 'focus'
        }, [
          m('.results', {
            style: { maxHeight: '300px', left: 0, top: '4px', color: 'black' }
          }, [
            m('.area', ctrl.getSuggestions().map(item => m(`a${ctrl.selected() === item ? '.selected' : ''}`,
              { style: { cursor: 'pointer', fontSize: '15px', padding: '3px' }, onclick: () => ctrl.addSuggested(item)
              }, [
                m('div', item.name || item)
              ])))
          ])
        ])
        : null
    ])
  }

}
