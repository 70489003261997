import m from 'mithril'
import u from '../utils'

export default {

  controller(args) {
    return {
      focused: m.prop(false)
    }
  },

  view(ctrl, args) {
    return m('.input', {
      class: (ctrl.focused() ? 'focus' : '') +
             ((args.value() || args.value() === 0) ? ' filled' : '') +
             (args.disabled ? ' disabled' : '')
    }, [
      m('input', {
        type: args.type || 'textfield',
        id: 'input_' + args.name,
        disabled: args.disabled,
        value: args.value(),
        onblur: ctrl.focused.bind(null, false),
        onfocus: ctrl.focused.bind(null, true),
        onkeydown: u.ifkey(27, u.setValue.bind(null, args.value())),
        onkeypress: args.onkeydown,
        onkeyup: u.ifkey(27, blur),
        onchange: m.withAttr('value', args.value)
      }),
      m('label', { for: 'input_' + args.name }, args.label || args.name)
    ])
  }

}
