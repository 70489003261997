import api from '../api'

export default window.guac = api.resource({
  path    : 'guacamoles',
  keyName : '_id',
  schema: {
    show      : Boolean,
    device    : String,
    user      : String,
    jwt       : String
  }
})
