import m from 'mithril'
import b from 'bss'
import { request } from '../api'

export default {
  controller: () => {
    const devices = m.prop()

    request({
      background: true,
      method: 'POST',
      url: 'devices/ghost'
    })
      .then(dev => devices(dev))
      .then(m.redraw)

    return {
      devices
    }
  },

  view: (ctrl) =>
    m('main' + b.p(20),
      m('h1', 'Online MIB\'s in ceased accounts'),
      m('table' + b
        .w('100%')
        .$nest(' td', b.lh('2em').p(2).pr(10).overflow('hidden').textOverflow('ellipsis').maxWidth(200).whiteSpace('nowrap'))
        .$nest(' tr:nth-child(odd)', b.bc('#ddd'))
        .$nest(' tr:hover', b.bc('#aaccee')),
      {
        cellpadding: 0,
        cellspacing: 0
      },
      m('tr' + b.tt('capitalize').fw('bold'), {
        onclick: (e) => ctrl.setSort(e.target.textContent)
      },
      m('td', '#'),
      m('td', 'hostname'),
      m('td', 'Reference')
      ),
      ctrl.devices()
        ? ctrl.devices().map((d, i) =>
          m('tr',
            m('td', i + 1),
            m('td', m('a' + b.td('underline !important'), { href: '/devices/' + d._id, config: m.route }, d.hostname)),
            m('td', d.reference)
          )
        )
        : m('div' + b.mt(50).w('100%').ta('center'), 'Getting devices...')
      )
    )
}
