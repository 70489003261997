import m from 'mithril'

export default {

  view(ctrl, attrs) {
    return m('input', {
      style: Object.assign({ marginLeft: '10px' }, attrs.style),
      type: attrs.type,
      onchange: attrs.onchange, value: attrs.selected || ''
    })
  }
}
