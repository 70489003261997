import m from 'mithril'
import monitors from '../components/monitors'
import repeatbuilder from '../components/repeatbuilder'
import modal from '../components/modal'

const Template = {
  days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  cmd: {
    title: ['Set: ', 'Force: '],
    key: ['power', 'inputSource', 'volume'],
    val: ['on', 'off', 'HDMI1', 'DTV', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '18', '19', '20']
  },
  period: '0000-00-00T00:00:00.000Z',
  duration: ['hours', 'minutes', 'seconds']
}

function getSchedule(device) {
  if (device)
    monitors.getDeviceData(device)

  const newSchedule = m('img', { style: { cursor: 'pointer', 'align-self': 'flex-start' },
    width: '30px', src: '../../../images/add.svg', onclick: () => {
      addSchedule(device)
    } })

  if (!monitors.DataUrl.const.end || !monitors.DataUrl.areas.monitorschedules)
    return m('table.monitors', newSchedule)

  return m('.editSheduleHolder', [
    m('button.online', { onclick: () => {
      monitors.postDeviceData(monitors.DataUrl.areas.monitorschedules)
    } }, 'save schedules'),
    m('.monitors', [
      newSchedule,
      monitors.monitorSchedulesView(true)
    ])
  ])
}

function addSchedule(device) {
  if (monitors.DataUrl.areas.monitorschedules) {
    if (monitors.DataUrl.areas.monitorschedules.events)
      monitors.DataUrl.areas.monitorschedules.events.push(createSchedule())
  } else {
    monitors.DataUrl.areas.monitorschedules = {
      deviceId: device,
      events: [createSchedule()]
    }
  }

  function createSchedule() {
    return {
      title: 'New schedule',
      unit: 'monitor1',
      sets: [createCommand()],
      periods: [createPeriod()]
    }
  }
}

function removeSchedule(remove, key, val) {
  if (!remove)
    return null

  return m('button.remove', { onclick: () => {
    newModal('Are you sure you want to remove this schedule?', [key, val], 'rSchedule')
  } }, 'remove')
}

function removeCommand(remove, key, val) {
  if (!remove || key === '0')
    return null

  return m('td', [
    m('button.remove.com', { onclick: () => {
      newModal('Are you sure you want to remove this command?', [key, val], 'rSchedule')
    } }, 'remove')
  ])
}

function removeDuration(remove, period, ev) {
  let afterValue

  if (!remove)
    return null

  Object.keys(ev.sets).map(val => {
    if (ev.sets[val].afterValue)
      afterValue = true
  })

  if (afterValue) {
    return m('td', [
      m('button.remove.com', { onclick: () => {
        newModal('Not possible to remove duration, remove all "then" values of the scheduled commands to proceed',
          [], 'alert')
      } }, 'remove')
    ])
  }

  return m('td', [
    m('button.remove.com', { onclick: () => {
      newModal('Are you sure you want to remove duration from the schedule?', period, 'rDuration')
    } }, 'remove')
  ])
}

function removeDays(remove, days) {
  if (!remove)
    return null

  return m('td', [
    m('button.remove.com', { onclick: () => {
      newModal('Are you sure you want to remove days from the schedule?', days, 'rDays')
    } }, 'remove')
  ])
}

function editTitle(title) {
  newModal('Rename schedule', title, 'title', [title.title])
}

function editMonitor(monitor, monitors) {
  if (monitors.length === 1)
    newModal(`Only ${monitors[0].toUpperCase()} exist`, [], 'alert', [])
  else
    newModal('Choose which monitor', [monitor, monitors], 'monitor')
}

function addCMD(edit, key) {
  if (!edit)
    return null

  return m('tr', [
    m('td', { colspan: 2 }, [
      m('button.primeTime.online', { onclick: () => {
        const val = Object.keys(key.sets).length

        key.sets[val] = createCommand()
        const obj = parse(key.sets[val])

        newModal('Add new command', [key.sets[val]], 'com', [obj])
      } }, 'Add new command')
    ])
  ])
}

function addSCHT(key, val, edit) {
  if (!edit)
    return null

  const lastPeriod = Object.keys(key.periods).length

  return m('tr', [
    m('td', { colspan: 2 }, [
      m('button.primeTime.online.xtra', { onclick: () => {
        key.periods[lastPeriod] = createPeriod()
        monitors.scheduleForCommand('scheduledPeriod', key, lastPeriod, edit, '.edit')
      } }, 'Add an extra period'),
      m('button.primeTime.remove.extra', { onclick: () => {
        newModal('Are you sure you want to remove this period?', [val, key.periods], 'rSchedule')
      } }, 'remove')
    ])
  ])
}

function createCommand() {
  return {
    key: 'power',
    value: 'on',
    forcedValue: 'false',
    restoreValue: 'false'
  }
}

function createPeriod(val) {
  const currentdate = new Date()
  const month = `${((currentdate.getMonth() + 1) < 10) ? 0 : ''}${currentdate.getMonth() + 1}`
  const date = `${(currentdate.getDate() < 10) ? 0 : ''}${currentdate.getDate()}`
  const h = `${(currentdate.getHours() < 10) ? 0 : ''}${currentdate.getHours()}`
  const m = `${(currentdate.getMinutes() < 10) ? 0 : ''}${currentdate.getMinutes()}`
  const s = `${(currentdate.getSeconds() < 10) ? 0 : ''}${currentdate.getSeconds()}`
  const datetime = `${(currentdate.getFullYear())}-${month}-${date}T${h}:${m}:${s}`

  return {
    start: datetime,
    duration: 0,
    frequency: 'week',
    interval: 1,
    days: [],
    weeks: [],
    dates: [],
    yeardays: [],
    months: [],
    hours: [0],
    minutes: [0],
    seconds: [0],
    weekstart: 'mon'
  }
}

function editCMD(cmd) {
  const obj = parse(cmd)

  newModal('Edit command', [cmd], 'com', [obj])
}

function editPeriod(period) {
  const obj = parse(period)

  if (!obj.start)
    obj.start = Template.period

  if (!obj.end)
    obj.end = Template.period

  newModal('Edit period', period, 'period', obj)
}

function editScheduledDays(obj) {
  const newDays = []

  if (obj.days) {
    obj.days.map(el => {
      newDays.push(el)
    })
  }

  newModal('Choose days', obj, 'days', newDays)
}

function editPeriodDuration(period) {
  newModal('Choose a new duration', period, 'periodDuration', [period.duration])
}

function editAtTime(obj) {
  const times = parse(obj)

  newModal('Choose at which time it should start', obj, 'startAtTime', times)
}

function editRepeat(repeat) {
  const obj = parse(repeat)

  repeatbuilder.resolveType(obj)

  newModal('Choose when this period will repeat', repeat, 'repeat', [])
}

function newModal(title, data, template, newData) {
  if (newData)
    modal.Modal.newData(newData)

  modal.Modal.title = title
  modal.Modal.data(data)
  modal.Modal.template(template)
  modal.Modal.show()
}

function parse(obj) {
  return JSON.parse(JSON.stringify(obj))
}


function create(device) {
  return m('#suggest.focus', [
    getSchedule(device),
    modal.modalWindow()
  ])
}

export default {
  create,
  editTitle,
  editMonitor,
  addCMD,
  editCMD,
  editPeriod,
  editRepeat,
  addSCHT,
  editScheduledDays,
  editAtTime,
  editPeriodDuration,
  removeSchedule,
  removeCommand,
  removeDuration,
  removeDays,
  newModal,
  Template
}
