import m from 'mithril'
import login from '../models/login'
import Notification from '../models/notification'
import handleWs from '../helper/ws'

let auth

const DataUrl = {
  const: {
    config: xhr => {
      xhr.setRequestHeader('Authorization', auth)
    }
  },
  error: null,
  cmd: {
    content: '',
    type: null
  },
  autoscroll: true,
  textarea: null
}

function getSession(lifelineId) {
  auth = `Bearer ${login.user().token}`
  DataUrl.error = null
  DataUrl.autoscroll = true

  m.request({
    method: 'GET',
    url: `${window.apiUrl}stations?sort={"created":-1}&conditions={"lifelineId": "${lifelineId}"}&limit=1`,
    config: DataUrl.const.config
  }).then(data => {
    if (!data[0])
      return DataUrl.error = 'LifeSupport not found!'

    handleWs.getWSSession(data[0]._id, { appSpecific: { key: 'cmd', fn: appSpecific } })
  })
}

function isOnline(status) {
  const conStatus = status ? 'online' : 'offline'

  return m(`button.${conStatus}`, conStatus)
}

function deviceData() {
  if (DataUrl.error)
    return m('h2', DataUrl.error)

  if (!handleWs.connected())
    return m('h2', handleWs.connectionStatus())

  return m('.content', [
    m('.pageBar.raised', [
      isOnline(handleWs.connected())
    ]),
    m('table', [
      m('tr', [
        m('textarea#cmdArea', {
          config: (element, isInit) => {
            if (!isInit)
              DataUrl.textarea = element
          },
          cols: 40, rows: 5, disabled: true,
          style: { background:'#FFFF', width: '78vw', height: '70vh' },
          value: DataUrl.cmd.content
        })
      ]),
      m('tr', [
        m('input', { type: 'text', style: { width: '78vw' },
          config: e => e.focus(),
          onkeydown: function(e) {
            if (e.which === 13) {
              DataUrl.cmd.type = this.value
              this.value = ''

              handleWs.send({ type: 'command', application: 'cmd', arguments: {
                type: 'input',
                data: DataUrl.cmd.type
              } })
            }
          } })
      ]),
      m('tr',
        m('button', {
          style: { float: 'left', marginRight: '10px' },
          onclick: () => {
            handleWs.send({ type: 'command', application: 'cmd', arguments: {
              type: 'close'
            } })
          } }, 'Close cmd'),
        m('button', {
          onclick: scrollToBottom,
          style: { float: 'left' }
        }, 'Scroll to bottom'),
        m('input', {
          type: 'checkbox',
          checked: DataUrl.autoscroll,
          onclick: () => {
            DataUrl.autoscroll = !DataUrl.autoscroll
          }
        }, 'Auto scroll'),
        m('label', 'Auto scroll')
      )
    ])
  ])
}

function appSpecific(data) {
  const fromLSData = data.results

  if (!fromLSData || fromLSData.error) {
    Notification.error(data.error || fromLSData.error)
  } else if (fromLSData.output) {
    DataUrl.cmd.content += fromLSData.output

    if (DataUrl.autoscroll && DataUrl.textarea.selectionStart === DataUrl.textarea.selectionEnd)
      scrollToBottom()
  }

  m.redraw()
}

function scrollToBottom() {
  DataUrl.textarea.scrollTop = DataUrl.textarea.scrollHeight
}

export default {
  getSession,
  deviceData
}
