import m from 'mithril'
import textfield from '../components/input'
import tagfield from '../components/tagfield'

export default function view(item) {
  if (item.key) {
    return [
      m('h1', item.key ? (item.title() || item.key) : 'New media'),

      m('fieldset', [
        m(textfield, { key: item.key, name: 'title', value: item.title }),
        m(textfield, { key: item.key, name: 'filename', value: item.filename, disabled: true }),
        m(tagfield, { key: item.key, name: 'tags', value: item.tags })
      ]),

      item.type().indexOf('image') > -1 ?
      m('img', {
        src: item.thumb() || '/images/encoding.png'
      })
      :
      item.type().indexOf('image') > -1 ?
      m('video', {
        poster: item.thumb() || '/images/encoding.png',
        src: item.file().url || '',
        controls: true
      })
      :
      item.status() === 'REQUEST_ABORTED' ?
      m('h1', 'upload')
      :
      m('h2', item.status()),

      m('button.raised.full.danger', {
        onclick: item.remove
      }, 'delete')
    ]
  } else if (m.route.param('id') === 'new') {
    return [
      m('h1', 'New media'),
      m('fieldset', [
        m(textfield, { name: 'title', value: item.title })
      ]),
      m('button.raised.full', {
        onclick: item.save
      }, 'create')
    ]
  }

  return m('h1', 'Location ' + m.route.param('id') + ' not found')
}
