import m from 'mithril'

const notifications = []
    , defaultTimeout = 5000

let count = 0

const Notification = {

  list: notifications,

  error(title, description, timeout) {
    Notification.add(title, description, 'error', timeout)
  },

  warning(title, description, timeout) {
    Notification.add(title, description, 'error', timeout)
  },

  info(title, description, timeout) {
    Notification.add(title, description, 'error', timeout)
  },

  add(title, description, type, timeout) {
    if(typeof description === 'number') {
      title = 'notification'
      description = title
      timeout = description
    }

    const notification = {
      id: count++,
      title: title,
      type: type,
      description: description
    }
    notifications.push(notification)
    if(timeout !== 0) {
      setTimeout(() => {
        Notification.remove(notification)
        m.redraw()
      }, timeout || defaultTimeout)
    }
  },

  remove(notification) {
    if(notification.removed)
      return

    notification.removed = true
    setTimeout(() => {
      const index = notifications.indexOf(notification)
      if(index > -1)
        notifications.splice(index, 1)
      m.redraw()
    }, 400)
  }

}

export default Notification
