import m from 'mithril'

let logoutTimeout
  , returnTo = '/'
  , currentUser

const login = {
  credentials : {
    username    : m.prop(''),
    password    : m.prop(''),
    rememberMe  : m.prop(false)
  },
  error       : m.prop(),
  loading     : m.prop(false),

  user: function(user){
    if (arguments.length > 0) {
      currentUser = user
      if (!user) {
        localStorage.removeItem('user')
        sessionStorage.removeItem('user')
      } else if (login.credentials.rememberMe()) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        sessionStorage.setItem('user', JSON.stringify(user))
      }
    }

    if (!currentUser) {
      try {
        currentUser = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'))
      } catch (err) {
        console.log(err)
      }
    }


    if (!currentUser) {
      returnTo = window.location.pathname
      return null
    }

    clearTimeout(logoutTimeout)
    logoutTimeout = setTimeout(() => {
      login.clearToken()
      m.redraw()
    }, currentUser.expires * 60 * 1000)

    return currentUser
  },

  getToken: function() {
    m.request({
      method: 'POST',
      url: window.apiUrl + 'tokens',
      data: login.credentials,
      config: xhr => xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    }).then(loginSuccess, loginError)
  },

  clearToken: function() {
    login.user(null)
    m.route('/login')
  }

}

function loginSuccess(user) {
  reset()
  user.username = login.credentials.username()
  login.user(user)
  m.route(returnTo.indexOf('/login') === 0 ? '/' : returnTo, true)
}

function loginError(err) {
  reset()
  login.error(err ? (err.message || err) : 'unkown error')
}

function reset() {
  login.loading(false)
  login.error('')
  login.credentials.password('')
}

export default login
