import m from 'mithril'
import Notification from '../models/notification'
import math from '../helper/math'
import login from '../models/login'
import modal from '../components/modal'
import handleWs from '../helper/ws'

let auth
let updateLSButton = 'disabled'
let updateLSTitle = '...'

const DataUrl = {
  const: {
    config: xhr => {
      xhr.setRequestHeader('Authorization', auth)
    },
    lifelineId: null,
    device: null
  },
  areas: {
    results: null,
    allApps: null,
    illegalApps: null,
    transactions: null
  },
  dwnloadLS: true
}

function getDeviceData(lifelineId) {
  auth = `Bearer ${login.user().token}`

  DataUrl.const.device = null
  DataUrl.const.lifelineId = lifelineId
  DataUrl.areas.results = null
  DataUrl.areas.dwnloadLS = true
  DataUrl.areas.illegalApps = null
  updateLSButton = 'disabled'
  updateLSTitle = '...'

  m.request({
    method: 'GET',
    url: `${window.apiUrl}stations?sort={"created":-1}&conditions={"lifelineId": "${lifelineId}"}&populate=applications.application`,
    config: DataUrl.const.config
  }).then(data => {
    DataUrl.areas.results = data[0]

    if (!data[0])
      return DataUrl.areas.results = { error: 'LifeSupport not found!' }

    DataUrl.const.device = data[0]._id

    handleWs.getWSSession(data[0]._id, {
      onclose: { key: m.route(), fn: wsonclose },
      onopen: { key: m.route(), fn: wsonopen },
      decode: { key: m.route(), fn: decode },
      appSpecific: { key: 'transmit', fn: appSpecific } })

    getTransactions(data[0]._id)
  })
}

function wsonclose() {
  if (DataUrl.areas.results && DataUrl.areas.results.applications)
    DataUrl.areas.results.applications.forEach(app => app.activeApp = false)

  DataUrl.areas.illegalApps = null
}

function wsonopen(ws) {
  ws.send(JSON.stringify({ type: 'status' }))
}

function getTransactions(stationDocumentId) {
  m.request({
    method: 'GET',
    url: `${window.apiUrl}transactions?conditions={"station": "${stationDocumentId}"}&limit=100&sort= -collected`,
    config: DataUrl.const.config
  }).then(transactions => {
    DataUrl.areas.transactions = transactions
  })
}

function keyPairRender(key, value) {
  if (['station', 'activeApp', 'path', '_id', '__v'].includes(key) || !value || value.length === 0)
    return null

  if (['modified', 'collected', 'created'].includes(key))
    value = math.convertDateTime(value)

  return m('tr', [
    key ? m('td', `${math.capitalize(key)}:`) : null,

    m('td', JSON.stringify(value))
  ])
}

function promtAction(btn, action) {
  if (updateLSButton === 'disabled') {
    updateLSTitle = 'Re-Install LifeSupport'
    updateLSButton = ''
    setTimeout(disable, 3000)
  } else {
    action()
    disable()
  }

  function disable() {
    updateLSTitle = '...'
    updateLSButton = 'disabled'
  }
}

function renderBlock(block, name, arr, actions) {
  return [actions ? [m('img', {
    style: { cursor: 'pointer' },
    width: '30px', src: '../../../images/add.svg', onclick: () => {
      getApps(newModal.bind(null, 'Add application', block, 'apps'))
    }
  }), m('button', { onclick: () => {
    updateApps(DataUrl.areas.results._id)
  } }, 'Update apps'),
  m('button.online', { onclick: () => {
    postDeviceData(DataUrl.areas.results.applications)
  }, style: { marginRight: '5px' } }, 'save changes'),
  m('button.' + updateLSButton, { style: { marginRight: '5px', backgroundColor: 'darkgrey' },
    onclick: function() {
      promtAction(this, installLifeSupport.bind(null, window.location.pathname.split('/')[2]))
    } }, updateLSTitle)] : null,
  block && block.length > 0 ? [m('p', { style: { 'margin-top': '15px', 'margin-left': '-5px' } }, `${name}: `),
    arr ? block.map((val, i) => {
      return [actions ?
        m('img', { style: { cursor: 'pointer' }, width: '20px', src: '../../../images/remove.svg',
          onclick: () => {
            DataUrl.areas.results.applications = DataUrl.areas.results.applications.filter(val => {
              if (val._id !== block[i]._id)
                return val
            })
          }
        }) : null,
      Object.entries(block[i])
        .sort((a, b) => a[0] > b[0])
        .map(([key, value]) => {
          return name.indexOf('Apps') > -1 ?
            m('tr', { style: { borderLeft: `7px solid ${block[i].activeApp ? '#529c33' : '#e42020'}` } },
              name === 'Additional Apps' ?
                additionalAppsBlock(value) :
                keyPairRender(key, value)
            ) : keyPairRender(key, value)
        }), m('tr', '-')]
    }) : Object.entries(block).map(([key, value]) => {
      return keyPairRender(key, value)
    })] : m('h2', 'No Apps found')]
}

function additionalAppsBlock(app) {
  return Object.entries(app)
    .sort((a, b) => a[0] > b[0])
    .map(([key, value]) =>
      keyPairRender(key, value)
    )
}

function newModal(title, data, template, newData) {
  if (newData)
    modal.Modal.newData(newData)
  else return getApps(newModal.bind(null, title, data, template))

  modal.Modal.title = title
  modal.Modal.data(data)
  modal.Modal.template(template)
  modal.Modal.show()
}

function postDeviceData(data) {
  m.request({
    method: 'PUT', url: `${window.apiUrl}stations/${DataUrl.const.device}?populate`,
    config: DataUrl.const.config,
    data: { applications: data }
  }).then(data => {
    if (data) {
      Notification.info('Success!', 'Your changes have been saved')
      getDeviceData(data.lifelineId)
    }
  }, () => { Notification.error('Failed!', 'Your changes have not been saved') })
}

function updateApps(id) {
  m.request({
    method: 'GET', url: `https://${login.user().lifesupport}/stations/${id}/applications`,
    config: DataUrl.const.config
  }).then(data => {
    if (data)
      Notification.info('Success!', JSON.stringify(data))
  }, () => { Notification.error('Failed!', 'Your changes have not been saved') })
}

function getApps(cb) {
  if (DataUrl.areas.allApps === null)
    requestApps(cb, 'allApps')
  else
    cb(DataUrl.areas.allApps)
}

function requestApps(cb, setData, conditions) {
  m.request({
    method: 'GET',
    url: `${window.apiUrl}applications`,
    data: conditions,
    config: DataUrl.const.config
  }).then(data => {
    data = data.filter(x => !x.hidden)

    if (setData)
      DataUrl.areas[setData] = data
    if (cb)
      cb(data)
  })
}

function isOnline(status) {
  const conStatus = status ? 'online' : 'offline'

  return m(`button.${conStatus}`, conStatus)
}

function deviceData() {
  if (!DataUrl.areas.results)
    return m('h2', handleWs.connectionStatus())

  if (DataUrl.areas.results) {
    if (DataUrl.areas.results.error) {
      return m('div', [m(`a.round.button.raised${DataUrl.areas.dwnloadLS ? '' : ' disabled'}`, {
        onclick: () => {
          if (DataUrl.areas.dwnloadLS) {
            DataUrl.areas.dwnloadLS = false
            installLifeSupport(window.location.pathname.split('/')[2])
          }
        }, style: { minWidth: '250px' }
      }, 'Install LifeSupport...'), m('h2', DataUrl.areas.results.error)])
    }
  }

  return m('.content', [
    m('.pageBar.raised', [
      isOnline(handleWs.connected()),
      m('div', `Station ID: ${DataUrl.areas.results._id} | LS Version: ${DataUrl.areas.results.version}${
        handleWs.connected() ? '' : ` | ${handleWs.connectionStatus()}`
      }`)
    ]),
    m('table', { style: { borderCollapse: 'collapse' } }, [
      DataUrl.areas.results.applications ? m('tr', [
        m('td', { style: { 'vertical-align': 'initial' } }, [
          renderBlock(DataUrl.areas.results.applications, 'Apps', true, true)
        ])
      ]) : null,
      DataUrl.areas.illegalApps ? m('tr', [
        m('td', { style: { 'vertical-align': 'initial' } }, [
          renderBlock(DataUrl.areas.illegalApps, 'Additional Apps', true)
        ])
      ]) : null,
      m('tr', [
        m('td', { style: { 'vertical-align': 'initial' } }, [
          renderBlock(DataUrl.areas.transactions, 'Transactions', true)
        ])
      ]),
      modal.modalWindow()
    ])
  ])
}

function installLifeSupport(deviceId) {
  m.request({
    method: 'POST', url: `${window.apiUrl}devices/${deviceId}/installLifeSupport`,
    config: DataUrl.const.config
  }).then(data => {
    if (data)
      Notification.info('Install started...Please wait for install to finish.', JSON.stringify(data))
  }, reject => { Notification.error('Failed!', JSON.stringify(reject)) })
    .catch(reason => { Notification.info('Service unavailable', JSON.stringify(reason)) })
}

function decode(data) {
  const fromLS = data.forward
    , fromLSType = fromLS ? data.forward.type : null

  if (fromLS) {
    switch (fromLSType) {
    case 'status':
      if (fromLS.activeApps) {
        DataUrl.areas.results.applications.forEach(app => {
          fromLS.activeApps.some((active, i) => {
            if (active === app._id) {
              app.activeApp = true
              return fromLS.activeApps.splice(i, 1)
            }
          })
        })

        if (fromLS.activeApps.length > 0) {
          requestApps(data =>
            DataUrl.areas.illegalApps = data.map(app => {
              return {
                activeApp: true,
                application: app
              }
            }), null, { conditions: { _id: { $in: fromLS.activeApps } } })
        }

        m.redraw()
      }
      break
    default:
      break
    }
  }
}

function appSpecific(data) {
  const fromLSData = data.data

  DataUrl.areas.transactions.unshift(data)
  Notification.info('New transaction', JSON.stringify(data))

  if (fromLSData && data.dataKey) {
    if (['stopped', 'crashed', 'closed'].some(s => fromLSData.includes(s))) {
      DataUrl.areas.results.applications.concat(DataUrl.areas.illegalApps).some(app => {
        if (app.application.name === data.dataKey)
          return app.activeApp = false
      })
    } else if (fromLSData.indexOf('Starting') > -1) {
      const foundApp =
        DataUrl.areas.results.applications
          .concat(DataUrl.areas.illegalApps)
          .find(app => app.application.name === data.dataKey)

      if (foundApp)
        foundApp.activeApp = true
      else
        handleWs.send({ type: 'status' })
    }
  }

  m.redraw()
}

export default {
  getDeviceData,
  deviceData
}
