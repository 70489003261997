import m from 'mithril'
import schedule from '../components/schedules'
import repeatbuilder from '../components/repeatbuilder'
import math from '../helper/math'

const Modal = {
  title: null,
  display: m.prop('none'),
  data: m.prop([]),
  newData: m.prop([]),
  template: m.prop(null),

  hide: () => {
    Modal.display('none')
  },

  show: () => {
    Modal.display('flex')
  },

  clearData: () => {
    Modal.data().splice(0, Modal.data().length)
  },

  copyData: () => {
    Modal.newData().length = 0
    Modal.data().map(el => {
      Modal.newData().push(el)
    })
  },

  remove: (key, val) => {
    val.splice(key, 1)

    Modal.hide()
  },

  update: () => {

    if (Modal.template() === 'period') {
      if (Modal.newData().start[2] > 0) {
        const converted = math.convertDateBack(Modal.newData().start) + math.convertTimeBack(Modal.newData().start.time)

        Modal.data().start = converted
        Modal.newData().start = converted
      } else {
        delete Modal.data().start
        delete Modal.newData().start
      }

      if (Modal.newData().end[2] > 0) {
        const converted = math.convertDateBack(Modal.newData().end) + math.convertTimeBack(Modal.newData().end.time)

        Modal.data().end = converted
        Modal.newData().end = converted
      } else {
        delete Modal.data().end
        delete Modal.newData().end
      }

    } else if (Modal.template() === 'periodDuration') {
      Modal.data().duration = Modal.newData()[0][0]

    } else if (Modal.template() === 'startAtTime') {
      Modal.data().hours = Modal.newData().hours.filter(value => value !== '')
      Modal.data().minutes = Modal.newData().minutes.filter(value => value !== '')
      Modal.data().seconds = Modal.newData().seconds.filter(value => value !== '')

    } else if (Modal.template() === 'title') {
      Modal.data().title = Modal.newData()[0]
    } else if (Modal.template() === 'com') {
      if (Modal.newData()[0].afterValue)
        Modal.data()[0].afterValue = Modal.newData()[0].afterValue

      Object.keys(Modal.data()[0]).map(key => {
        Modal.data()[0][key] = Modal.newData()[0][key]
      })
    } else if (Modal.template() === 'days') {
      Modal.data().days = Modal.newData()
    } else if (Modal.template() === 'repeat') {
      repeatbuilder.update(Modal.data())
    } else if (Modal.template() === 'apps') {
      const version = document.getElementById('appsVersion')
        , config = document.getElementById('appsAddConfig')
        , app = { application: document.getElementById('appsAdd').value }

      if (config)
        app.config = config.value === '' ? null : JSON.parse(config.value)

      if (version)
        app.version = version.options[version.selectedIndex].value

      Modal.data().push(app)
    } else if (Modal.template() === 'renameFile') {
      Modal.data()(Modal.newData()[0])
    } else {
      Modal.clearData()

      Modal.newData().map((el) => {
        Modal.data().push(el)
      })
    }

    Modal.hide()
  }
}

function setTitle(title) {
  Modal.newData()[0] = title
}

function setCMD(index, value, afterValue) {
  if (index === 0) {
    let forcedValue = 'true'

    if (value === schedule.Template.cmd.title[0])
      forcedValue = 'false'

    Modal.newData()[0].forcedValue = forcedValue
  } else if (index === 1) {
    Modal.newData()[0].key = value
  } else if (index === 2) {
    if (afterValue)
      Modal.newData()[0].afterValue = value
    else Modal.newData()[0].value = value
  }
}

function setPeriod(key, time, pos, value) {
  m.redraw.strategy('none')

  if (time === 0)
    Modal.newData()[key][pos] = value
  else Modal.newData()[key].time[pos] = value

}

function getHMSView() {
  const times = Modal.newData().toString().split(',')

  Modal.newData().length = 0
  Modal.newData()[0] = times

  return times.map((t, i) => {
    const time = math.convertSeconds(t)

    return m('table', [
      m('tr', [
        schedule.Template.duration.map(el => {
          return m('th', math.capitalize(el))
        })
      ]),
      m('tr', [
        schedule.Template.duration.map((el, idx) => {
          return m('td', [
            m('button.time', { name: el + i, onclick: function() {
              addTime(this, true, i)
            } }, '+')])
        })
      ]),
      m('tr', [
        schedule.Template.duration.map((el, idx) => {
          return m('td', [
            m('input', { name: el + i, onchange: function() {
              setTime(this, true, i)
            }, value: time[idx] })
          ])
        })
      ]),
      m('tr', [
        schedule.Template.duration.map((el, idx) => {
          return m('td', [
            m('button.time', { name: el + i, onclick: function() {
              addTime(this, false, i)
            } }, '-')])
        })
      ])
    ])
  })
}

function getStartAtTime() {
  const selectedHMS = [math.sort(Modal.newData().hours), math.sort(Modal.newData().minutes),
    math.sort(Modal.newData().seconds)]

  const hourOption = []
    , minuteOption = []
    , secondOption = []
    , combinations = []

  selectedHMS[0].map(h => {
    if (h === '')
      return null
    selectedHMS[1].map(mm => {
      if (mm === '')
        return null
      selectedHMS[2].map(s => {
        if (s === '')
          return null
        combinations.push(`${math.twoDigit(h)}:${math.twoDigit(mm)}:${math.twoDigit(s)}`)
      })
    })
  })

  math.push(hourOption, 23)
  math.push(minuteOption, 59)
  math.push(secondOption, 59)

  selectedHMS.map((val, i) => {
    math.pushEmpty(selectedHMS[i])
  })

  return [
    m('div', { style: { width: '100%' } }, [
      m('table', [
        m('tr', [
          schedule.Template.duration.map(el => {
            return m('th', math.capitalize(el))
          })
        ]),
        m('tr', [
          m('td', choiceInput(selectedHMS[0], hourOption)),
          m('td', choiceInput(selectedHMS[1], minuteOption)),
          m('td', choiceInput(selectedHMS[2], secondOption))
        ])
      ])
    ]),
    m('div.repeatCombi', `Schedule will start at: ${combinations.join(', ')}`)
  ]
}

function choiceInput(selectedValues, arr) {
  return selectedValues.map((el, idx) => {
    return m('select.atTimes', { name: idx, onchange: function() {
      const checkValue = Number(this.name) + 1

      if (this.value === selectedValues[0] && selectedValues.length > 2)
        selectedValues.splice(checkValue, 1)
      else selectedValues[checkValue] = Number(this.value)

    } }, [
      m('option', { value: selectedValues[0] }, selectedValues[0]),
      arr.map(element => {
        const valueSelected = selectedValues.indexOf(element)

        if (valueSelected > -1 && idx !== valueSelected - 1)
          return null

        let selected = ''

        if (selectedValues[idx + 1] === element)
          selected = 'selected'

        return m('option', { selected: selected, value: element }, element)
      })
    ])
  })
}

function addDay(day) {
  day.classList.toggle('selected')
  Modal.newData().length = 0

  for (let i = 0; i < day.parentNode.childNodes.length; i++) {
    if (day.parentNode.childNodes[i].classList.contains('selected'))
      Modal.newData().push(day.parentNode.childNodes[i].innerHTML)
  }
}

function setTime(time, add, i) {
  const hms = time.name.replace(/[0-9]/g, '')

  if (hms === schedule.Template.duration[0])
    time.value = (time.value > 24) ? 23 : time.value
  else time.value = (time.value > 59) ? 59 : time.value

  time.value -= 1
  addTime(time, add, i)

}

function addTime(time, add, i) {
  let hms = time.name.replace(/[0-9]/g, '')
  let value
  let ms

  if (hms === schedule.Template.duration[0]) {
    hms = document.querySelectorAll(`input[name=${time.name}]`)
    value = Number(hms[0].value)
    ms = false
  } else {
    hms = document.querySelectorAll(`input[name^=${hms}]`)
    value = Number(hms[i].value)
    ms = true
  }

  if (!hms[0]) {
    updateTime()
    return null
  }

  Object.keys(hms).map((val, ind) => {
    const limit = (ms) ? 59 : 23

    if (add)
      hms[ind].value = (value === limit) ? 0 : value + 1
    else
      hms[ind].value = (value === 0) ? limit : value - 1

    const alltimes = Modal.newData().toString().split(',')
    const newTime = convertToSeconds(ind)

    alltimes[ind] = newTime[0].toString()
    Modal.newData()[0] = alltimes
  })

  updateTime()

  function convertToSeconds(i) {
    return math.convertToSeconds([document.getElementsByName(schedule.Template.duration[0] + i)[1].value,
      document.getElementsByName(schedule.Template.duration[1] + i)[1].value,
      document.getElementsByName(schedule.Template.duration[2] + i)[1].value])
  }

  function updateTime() {
    const alltimes = Modal.newData().toString().split(',')
    const newTime = convertToSeconds(i)

    if (newTime.toString() === alltimes[i + 1] || newTime.toString() === alltimes[i - 1]) {
      alltimes.splice(i, 1)
      if (alltimes.length < 2)
        alltimes[0] = `${alltimes[0]}`

      Modal.newData()[0] = alltimes.toString()
      return null
    }

    if (alltimes.length > 1 && time.name.match(/hours/g)) {
      if (add) {
        if (value === 23)
          alltimes[i - 1] = `${alltimes[i - 1]},${alltimes[i]}`
        else if (value === 0)
          alltimes[i - 1] = `${alltimes[i - 1]},${alltimes[i]}`
        else
          alltimes[i - 1] = `${alltimes[i - 1]},${Number(newTime) - 3600}`
      } else {
        alltimes[i + 1] = (value === 0) ? `${alltimes[i]},${alltimes[i + 1]}` :
          `${Number(newTime) + 3600},${alltimes[i + 1]}`
      }
    }

    alltimes[i] = convertToSeconds(i)
    Modal.newData()[0] = alltimes.toString()
  }
}

function comOptions(afterValue) {
  return Object.keys(schedule.Template.cmd).map((key, index) => {
    return m('select', { onchange: function() {
      setCMD(index, this.value, afterValue)
    } }, [
      schedule.Template.cmd[key].map(element => {
        let selected = ''

        if (key === 'title') {
          if (!afterValue) {
            const check = (Modal.newData()[0].forcedValue === 'true') ?
              schedule.Template.cmd.title[1] : schedule.Template.cmd.title[0]

            if (element === check)
              selected = 'selected'
          } else if (element === schedule.Template.cmd.title[0]) {
            selected = 'selected'
          }

        } else if (element === Modal.newData()[0].value && !afterValue ||
                   element === Modal.newData()[0].afterValue && afterValue || element === Modal.newData()[0].key) {
          selected = 'selected'
        }

        if (!afterValue || afterValue && key === 'val')
          return m('option', { selected: selected, value: element }, math.capitalize(element))

        return m('option', { disabled: 'disabled', selected: selected, value: element }, math.capitalize(element))
      })
    ])
  })
}

function thenComOptions() {
  if (Modal.newData()[0].afterValue) {
    return m('div', [
      m('span.then', 'Then →'),
      comOptions(true),
      m('button.then', { onclick:() => {
        delete Modal.newData()[0].afterValue
      } }, 'X')
    ])
  }

  return m('div', [
    m('span.then', 'Then →'),
    m('button.then', { onclick:() => {
      Modal.newData()[0].afterValue = schedule.Template.cmd.val[0]
    } }, '+')
  ])
}

function addRestore(restore) {
  restore.classList.toggle('selected')

  if (restore.classList.contains('selected'))
    Modal.newData()[0].restoreValue = 'true'
  else Modal.newData()[0].restoreValue = 'false'
}

function loadData() {
  let selected

  switch (Modal.template()) {
  case 'title':
  case 'renameFile':
    return m('input', { value: Modal.newData()[0], onkeyup: m.withAttr('value', setTitle) })
  case 'monitor':
    return m('select', [
      Modal.data()[1].map(element => {
        if (Modal.data()[1] === Modal.data()[0])
          selected = 'selected'
        else selected = ''

        return m('option', { selected: selected, value: element }, element.toUpperCase())
      })
    ])
  case 'com':
    selected = ''

    if (Modal.newData()[0].restoreValue === 'true')
      selected = 'selected'

    return m('div', [
      m(`button.restore.${selected}`, { onclick: function() { addRestore(this) } }, 'Restore'),
      comOptions(false),
      thenComOptions()
    ])
  case 'period':
    return m('div', [
      Object.keys(Modal.newData()).map(key => {
        if (key === 'start' || key === 'end') {
          const data = Modal.newData()[key]
          const ident = `${math.capitalize(key)}: `

          if (Modal.newData()[key].length !== 3) {
            Modal.newData()[key] = math.convertDate(data, true)
            Modal.newData()[key].time = math.convertTime(data, false, true)
          }

          return m('tr', [
            m('td.period', ident),
            m('td', [
              Object.keys(Modal.newData()[key]).map((el, i) => {
                if (i < 3) {
                  return m('input.period', { value: Modal.newData()[key][i],
                    onkeyup: m.withAttr('value', value => setPeriod(key, 0, i, value))
                  })
                }
                return m('span', [
                  m('span', ' At: '),
                  Object.keys(Modal.newData()[key].time).map((e, idx) => {
                    return m('input.period', { value: Modal.newData()[key].time[idx],
                      onkeyup: m.withAttr('value', value => setPeriod(key, 1, idx, value))
                    })
                  }
                  )])
              })
            ])
          ])
        }
      })
    ])
  case 'days':
    return schedule.Template.days.map((element) => {
      selected = ''

      if (Modal.newData().includes(element))
        selected = 'selected'
      return m(`button.days.${selected}`, { onclick: function() {
        addDay(this)
      } }, element)
    })
  case 'periodDuration':
    return getHMSView()
  case 'startAtTime':
    return getStartAtTime()
  case 'repeat':
    return repeatbuilder.build()
  case 'apps':
    return [m('label', { for: 'searchApp' }, 'Search'),
      m('input#searchApp', { style: { width: '90%', marginLeft: '10px' },
        autofocus: true,
        onkeyup: m.withAttr('value', val => {
          const select = document.getElementById('appsAdd')
            , find = new RegExp(val, 'i')
            , event = new Event('change')

          let found

          for (let i = 0; i < select.length; i++) {
            if (select.options[i].text.search(find) > -1)
              found = i
          }

          if (found) {
            select.options[found].selected = true
            select.dispatchEvent(event)
          }
        }) }),
      m('select', { id: 'appsAdd', onchange: m.withAttr('value', val => {
        const versions = []
          , configEl = document.getElementById('appsAddConfig')
          , versionsEl = document.getElementById('versionSelect')

        Modal.newData().map(element => {
          if (element._id === val) {
            if (element.versions.length > 0) {
              element.versions.map(el => {
                return versions.push(el.version)
              })
            }

            if (element.config)
              configEl.style.display = 'block'
            else
              configEl.style.display = 'none'

            configEl.value = JSON.stringify(element.config) || ''
          }
        })

        if (versions.length > 0) {
          versionsEl.style.display = 'block'
          versionsEl.innerHTML =
        m('select#versionSelect', { id: 'appsVersion', style: { width: '100%' } }, [
          m('option', { value: '' }),
          versions.map(version => {
            return m('option', { value: version }, version)
          })
        ])
        } else {
          versionsEl.style.display = 'none'
        }
      }), style: { width: '100%' } }, [
        m('option', { value: '' }),
        Modal.newData().sort((a, b) => a.description > b.description ? 1 : -1).map(e => {
          return m('option', { value: e._id },
            math.capitalize(e.description) + ` (${math.capitalize(e.name)})`)
        })
      ]),
      appConfigs()]
  case 'code':
    return Modal.data()
  default:
    break
  }
}

function appConfigs(config, versions) {
  return [m('select#versionSelect', { style: { display: 'none' } }),
    m('textarea', { id: 'appsAddConfig', rows: 10, style: { width: '100%', display: 'none' } })]
}

function footer() {
  switch (Modal.template()) {
  case 'alert':
    return m('button', { onclick: () => {
      Modal.hide()
    } }, 'ok')
  case 'code':
    return m('button', { onclick: () => {
      Modal.data('')
      Modal.hide()
    } }, 'ok')
  case 'rSchedule':
    return m('button', { onclick: () => {
      Modal.remove(Modal.data()[0], Modal.data()[1])
    } }, 'remove')
  case 'rDuration':
    return m('button', { onclick: () => {
      delete Modal.data().duration
      Modal.hide()
    } }, 'remove')
  case 'rDays':
    return m('button', { onclick: () => {
      delete Modal.data().days
      Modal.hide()
    } }, 'remove')
  case 'apps':
    return m('button', { onclick: Modal.update }, 'add')
  case 'renameFile':
    return m('button', { onclick: Modal.update }, 'rename')
  default:
    return m('button', { onclick: Modal.update }, 'Set')
  }
}

function modalWindow() {
  return m('.modal', { style: { display: Modal.display() }, id: 'myModal' }, [
    m('.modal-content', [
      m('.modal-header.pageBar', [
        m('span.close', { onclick: Modal.hide }, 'x'),
        m('h2', Modal.title)
      ]),
      m('.modal-body', [
        loadData()
      ]),
      m('.modal-footer.pageBar', [
        footer()
      ])
    ])
  ])
}

export default {
  Modal,
  modalWindow
}
