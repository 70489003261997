import m from 'mithril'
import { request as api } from '../api'
import s from 'sin/browser/sin.umd.js'
import login from '../models/login'

const app = s(({ life }) => {
  const ips = ['85.204.133.170', '213.150.45.210', '213.150.45.211']
  const user = login.user()
  let stores = []
  let confirmed = true
  let fetchTimer = setInterval(fetch, 20 * 1000)

  const seen = x => !ips.includes(x.ip) || new Date(x.lastConnected) > new Date(2020, 2, 17)
  window.addEventListener('blur', blur)
  window.addEventListener('focus', focus)

  life(() => () => {
    clearInterval(fetchTimer)
    window.removeEventListener('blur', blur)
    window.removeEventListener('focus', focus)
  })

  function blur() {
    clearInterval(fetchTimer)
  }

  function focus() {
    fetch()
    clearInterval(fetchTimer)
    fetchTimer = setInterval(fetch, 20 * 1000)
  }

  function fetch() {
    api({
      background: true,
      method: 'GET',
      url: 'devices',
      data: {
        access: location.pathname.split('-')[1],
        limit: 500,
        populate: JSON.stringify({ path: 'location', select: 'reference' }),
        select: 'fakta hostname location prepareDone ip faktaChecked',
        conditions: JSON.stringify({ fakta: { $exists: true } })
      }
    }).then(xs => {
      stores = xs
      .filter(x => x.location)
      .map(x => (x.fakta = x.fakta || {}, x))
      .sort((a, b) => a.location.reference > b.location.reference
        ? 1
        : a.location.reference < b.location.reference
          ? -1
          : 0)

      s.redraw()
    })
  }

  function confirm(x, value) {
    api({
      background: true,
      method: 'PUT',
      url: 'devices/' + x._id,
      data: {
        prepareDone: value
      }
    })
  }

  function put(x, obj) {
    Object.assign(x.fakta, obj)
    api({
      background: true,
      method: 'PUT',
      url: 'devices/' + x._id,
      data: {
        fakta: x.fakta
      }
    })
  }

  fetch()

  return () => s`div
    p 40
  `(
    s`h1`(
      'Fakta status ',
      s`small`(
        stores.filter(x => x.connected).length,
        ' / ',
        stores.length,
        ' online'
      ),
      user && s`label
        float right
      `(
        s`small`('show done'),
        s`input`({
          type: 'checkbox',
          disabled: !user,
          onclick: () => confirmed = !confirmed
        })
      )
    ),
    s`table
      w 100%
      border-collapse collapse
      td {
        p 5
      }
    `(
      s`thead
        fw bold
      `(
        [
          s`td`(''),
          user && s`td`('Done: ' + stores.reduce((acc, x) => acc + (new Date(x.prepareDone).getTime() === 0 ? 1 : 0), 0)),
          s`td`('hostname'),
          s`td`('Seen: ' + stores.reduce((acc, x) => acc + (seen(x) ? 1 : 0), 0)),
          s`td`('Checked: ' + stores.reduce((acc, x) => acc + (x.faktaChecked ? 1 : 0), 0)),
          s`td`('Location'),
          false && s`td`('Delivered: ' + stores.reduce((acc, x) => acc + (x.fakta.sent ? 1 : 0), 0)),
          s`td`('Max vol too low: ' + stores.reduce((acc, x) => acc + (x.fakta.volumeLow ? 1 : 0), 0)),
          s`td`('Bad network: ' + stores.reduce((acc, x) => acc + (x.fakta.badNetwork ? 1 : 0), 0)),
          s`td`('Volume 0-20'),
          s`td`('Comment')
        ]
      ),
      stores.filter(x =>
        !user || confirmed || new Date(x.prepareDone).getTime() > 0
      ).map((x, i) => s`tr
        :nth-child(odd) {
          bc #ddd
        }
      `(
        s`td
          min-width 40
          mr 10
          ta right
        `(i + 1),
        user && s`td`(
          s`input
          `({
            checked: new Date(x.prepareDone).getTime() === 0,
            disabled: !user,
            type: 'checkbox',
            onclick: (e) => {
              confirm(x, x.prepareDone = new Date(x.prepareDone).getTime() === 0
                ? new Date()
                : new Date(0))
            }
          })
        ),
        s`td
          background ${ x.connected ? 'hsl(200, 100%, 50%)' : 'tomato' }
        `(
          s`a
            max-width 100
            p 5 20
            mr 10
            c white
            td ${ user && 'underline' }
          `({
            href: user && 'https://internal.beat.dk/devices/' + x._id,
            target: user && '_BLANK'
          }, x.hostname)
        ),
        s`td
          c white
          bc ${ seen(x) && 'hsl(200, 100%, 50%)' }
        `(
          seen(x) && 'seen'
        ),
        s`td
          c white
          bc ${ x.faktaChecked && 'hsl(200, 100%, 50%)' }
        `({
          title: x.faktaChecked && (
            x.faktaChecked[0] + ' - ' + new Date(x.faktaChecked[1]).toLocaleString()
          )
        },
          x.faktaChecked && 'checked'
        ),
        s`td`(
          x.location && s`div`(x.location.reference)
        ),
        false && s`td`(
          s`input`({
            type: 'checkbox',
            checked: x.fakta.sent,
            disabled: !user,
            onclick: () => put(x, { sent: !x.fakta.sent })
          })
        ),
        s`td`(
          s`input`({
            type: 'checkbox',
            checked: x.fakta.volumeLow,
            disabled: !user,
            onclick: () => put(x, { volumeLow: !x.fakta.volumeLow })
          })
        ),
        s`td`(
          s`input`({
            type: 'checkbox',
            checked: x.fakta.badNetwork,
            disabled: !user,
            onclick: () => put(x, { badNetwork: !x.fakta.badNetwork })
          })
        ),
        s`td
          bc ${ x.fakta.volume && x.fakta.volume != 12 && 'red'}
        `(
          s`input
            max-width 40
          `({
            value: x.fakta.volume || 12,
            type: 'number',
            max: 20,
            min: 0,
            oninput: (e) => put(x, { volume: e.target.value }),
            disabled: !user
          })
        ),
        s`td`(
          s`input
            min-width 240
          `({
            value: x.fakta.comment,
            oninput: (e) => put(x, { comment: e.target.value }),
            disabled: !user
          })
        )
      ))
    )
  )
})

export default {
  controller: function() {
    this.onunload = (ctrl) => s.mount(ctrl.dom, null)
    return this
  },
  view: (ctrl) => m('div', {
    config: (dom, inited) => {
      ctrl.dom = dom
      !inited && s.mount(dom, app)
    }
  })
}
