const m = require('mithril')
  , dialog = require('../models/dialog')
  , filter = require('../components/filter')

const defaults = {
  page: add => {
    return add ? (Number(m.route.param('page')) || 1) + add : Number(m.route.param('page')) || 1
  },
  filters: null,
  pageControls: { download: false }
}

function switchPage(navigation) {
  navigation.switchPage()
  navigation.getList(navigation.returnList().name)
}

function buildConditions(origConditions, filterConditions) {

  function combine(obj, src) {
    Object.keys(src).forEach(key => obj[key] = src[key])

    return obj
  }

  let conditions = {}

  if (origConditions)
    conditions = origConditions

  if (filterConditions) {
    const filterConds = filter.getConditions(filterConditions)

    if (Object.keys(filterConds).length > 0 && filterConds.constructor === Object)
      conditions = origConditions ? combine(filterConds, origConditions) : filterConds
  }

  return conditions
}

module.exports = function(opts) {
  const navigation = { }

  for (const opt in defaults) {
    if (!opts[opt])
      opts[opt] = defaults[opt]
  }

  for (const opt in opts)
    navigation[opt] = opts[opt]

  navigation.currentPage = navigation.page()
  navigation.filters ? navigation.filters = filter.add(navigation.filters) : null
  navigation.dwnload = true

  function buildQuery(list, limit) {
    const queryParams = { }

    Object.keys(list.opts).map(option => {
      if (option === 'limit') {
        if (limit) {
          return [queryParams.limit = list.opts.limit + 1,
            queryParams.skip = Number(navigation.currentPage - 1) * queryParams.limit]
        }

        return queryParams.limit = 9999
      }

      return queryParams[option] = list.opts[option]
    })

    return queryParams
  }

  navigation.getList = updateList => {
    if (!navigation.id) {
      if (updateList) {
        for (let list in navigation.lists) {
          list = navigation.lists[list]

          if (list.model.urlRoot.includes(updateList))
            return list.data.data = list.model.query(buildQuery(list, true))
        }
      } else {
        navigation.lists.map(list => {
          list.opts.conditions = buildConditions(list.conditions, navigation.filters)

          if (list.model.urlRoot === 'devices/' && window.localStorage.getItem('includeXXX'))
            list.opts.conditions.includeXXX = window.localStorage.getItem('includeXXX') || undefined

          list.data = { name: list.model.urlRoot, data: list.model.query(buildQuery(list, true)) }
        })
        if (navigation.filters) {
          navigation.filters.map(filter => {
            if (filter.props.model)
              filter.data = filter.props.model.query(buildQuery(filter.props, true))
          })
        }
      }
    }
  }

  navigation.getList()

  navigation.returnList = () => {
    return navigation.lists[0].data
  }

  navigation.nextPage = () => {
    if (navigation.moreItems()) {
      navigation.currentPage = navigation.currentPage + 1
      switchPage(navigation)
    }
  }

  navigation.prevPage = () => {
    if (navigation.lessItems()) {
      navigation.currentPage = navigation.currentPage - 1
      switchPage(navigation)
    }
  }

  navigation.switchPage = () => {
    const url = `${navigation.root}?page=${navigation.currentPage}`
      , filters = navigation.filters ? filter.getURL(navigation.filters) : ''

    history.pushState(null, null, url + filters)
  }

  navigation.changeFilter = (val, type) => {
    filter.change(val, type, navigation.filters)
    navigation.lists[0].opts.conditions = buildConditions(navigation.lists[0].conditions, navigation.filters)
    navigation.currentPage = 1
    switchPage(navigation)
  }

  navigation.moreItems = () => {
    return navigation.returnList().data().length - 1 === navigation.lists[0].opts.limit
  }

  navigation.lessItems = () => {
    return navigation.currentPage > 1
  }

  navigation.route = id => {
    m.route(`${navigation.itemRoot ? navigation.itemRoot : navigation.root}${typeof id === 'string' ? `/${id}` : ''}`)
  }

  navigation.download = () => {
    navigation.dwnload = false

    dialog.prompt({
      title: 'Download',
      description: 'This task is intense on our server, therefore it could and probably will make it explode ' +
        'if used without caution! \n\nPlease choose only the fields needed in the generated Excel file, ' +
        'less fields/limit will reduce workload.\n',
      m: m('div', [
        m('button', {
          onclick: () => document.querySelectorAll('input[type=\'checkbox\']').forEach(x => x.checked = true)
        }, 'Check All'),
        m('form', [
          m('br'),
          navigation.downloadConditions.select.split(' ').map(opt => {
            return m('div', [
              m('input', {
                type: 'checkbox',
                id: 'select_' + opt,
                value: opt,
                checked: navigation.downloadConditions.default ?
                  navigation.downloadConditions.default.split(' ').some(x => x === opt) ? 'checked' : '' : 'checked'
              }),
              m('span', opt)
            ])
          }),
          m('br'),
          m('div', [
            m('span', 'Limit: '),
            m('input', {
              type: 'text',
              id: 'limit_limit',
              value: navigation.downloadConditions.limit
            }, 'limit')
          ])
        ])
      ]),
      confirmText: 'download',
      confirm: () => {
        Object.keys(navigation.downloadConditions).map(val => {
          val === 'select' ? navigation.downloadConditions[val].split(' ').map(opt => {

            if (!document.getElementById('select_' + opt).checked) {
              if (opt === 'location')
                delete navigation.downloadConditions.populate

              navigation.downloadConditions[val] = navigation.downloadConditions[val].split(' ').filter(x => x !== opt).join(' ')
            }

          }) : val === 'populate' || val === 'default' ? '' :
            navigation.downloadConditions[val] = document.getElementById('limit_' + val).value
        })

        navigation.downloadConditions.conditions = navigation.lists[0].opts.conditions

        if (navigation.downloadConditions.default)
          delete navigation.downloadConditions.default

        navigation.lists[0].model.download(navigation.downloadConditions)
      } })
  }

  navigation.controls = () => {
    const omit = {}

    if (navigation.pageControls) {
      Object.keys(navigation.pageControls).map(key => {
        omit[key] = navigation.pageControls[key]
      })
    }

    return [omit.create === false ? null :
      m('a.round.button.raised', {
        href: `${navigation.itemRoot ? navigation.itemRoot : navigation.root}/new`, config : m.route
      }, '+'),
    omit.next === false ? null :
      m(`a.round.button.raised${ navigation.moreItems() ? '' : '.disabled'}`, {
        onclick: navigation.nextPage,
        style: { 'margin-left': '5px', 'margin-right': '20px' }
      }, '>'),
    navigation.currentPage > 1 ?
      m('a.round.button.raised', { style: { width: 'initial', marginLeft: '5px' } },
        'Page: ' + navigation.currentPage) : null,
    omit.previous === false ? null :
      m(`a.round.button.raised${ navigation.lessItems() ? '' : '.disabled'}`, {
        onclick: navigation.prevPage
      }, '<'),
    omit.download === false ? null :
      m(`a.round.button.raised${navigation.dwnload ? '' : ' disabled'}`,
        { style: { width: 'initial', marginRight: '20px' },
          onclick: navigation.dwnload ? navigation.download : null
        }, 'download')]
  }

  return navigation
}
