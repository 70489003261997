import m from 'mithril'
import Notification from './models/notification'
import login from './models/login'
import mResource from './mithril-resource'

const $ = window.document.getElementById.bind(document)
  , tokenErrors = ['Token expired', 'jwt malformed', 'Authentication missing']

const api = mResource.defaults({
  request
})

export function request(options) {
  wrap(options, 'config', xhrConfig)
  wrap(options, 'deserialize', deserialize)
  wrap(options, 'unwrapSuccess', finished)
  wrap(options, 'unwrapError', unwrapError)
  wrap(options, 'extract', xhr => {
    const isJson = '"[{'.indexOf(xhr.responseText.charAt(0)) !== -1

    return isJson ? (xhr.responseText || null) : JSON.stringify(xhr.responseText)
  })

  options.url = options.url.startsWith('https://') ? options.url : window.apiUrl + options.url
  return m.request(options)
}

function wrap(object, key, fn) {
  if (object[key]) {
    const firstFn = object[key]

    object[key] = function() {
      firstFn.apply(firstFn, arguments)
      fn.apply(fn, arguments)
    }
  } else {
    object[key] = fn
  }
}

const requests = {}

function updateProgress(event) {
  requests[this] = event.lengthComputable ? (100 * event.loaded / event.total) : 100
  const keys = Object.keys(requests)

  if ($('xhrProgressBar'))
    $('xhrProgressBar').style.width = keys.reduce((r, p) => r += requests[p], 0) / keys.length + '%'
}

function finished(data, xhr) {
  delete requests[xhr]
  setTimeout(() => {
    if ($('xhrProgressBar') && Object.keys(requests).length === 0)
      $('xhrProgressBar').style.opacity = 0
  }, 800)

  return data
}

function deserialize(value) {
  try {
    value = JSON.parse(value)
  } catch (e) {
    console.log(e)
    return value
  }

  return value
}

function unwrapError(err, xhr) {
  finished(null, xhr)

  if (!err)
    return

  if (tokenErrors.some(e => err && err.message && err.message.indexOf(e) === 0)) {
    login.clearToken()
  } else {
    console.log(err)

    if (!Array.isArray(err))
      err = [err]

    err.forEach(err => {
      Notification.error('Error', err ? err.message || err : 'Unknown server error')
    })
  }

  m.redraw()

  return err
}

function xhrConfig(xhr) {
  const token = login.user() ? login.user().token : ''

  if (token)
    xhr.setRequestHeader('Authorization', 'Bearer ' + token)

  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

  xhr.addEventListener('progress', updateProgress.bind(xhr))

  xhr.addEventListener('abort', finished)

  if ($('xhrProgressBar'))
    $('xhrProgressBar').style.opacity = 1
}

export default api
