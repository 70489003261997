import m from 'mithril'
import Notification from '../models/notification'

const notifications = {

  view: function(ctrl) {
    return m('ul#notifications', Notification.list.map(n => {
      return m('li', {
        key: n.id,
        class: n.removed ? 'removed' : '',
        onclick: Notification.remove.bind(null, n)
      }, m('.content', {
        class: n.type
      }, [
        m('h2', n.title),
        m('p', n.description)
      ]))
    }))
  }

}

export default notifications
