import api from '../api'

export default api.resource({
  path    : 'partners',
  autosave : false,
  schema  : {
    accountingId   : String,
    accountingRow  : Number,
    name           : String,
    searchName     : String,
    address        : String,
    address2       : String,
    zipCity        : String,
    country        : String,
    vatNumber      : String,
    group          : String,
    invoiceAccount : String,
    locations      : Array,
    includes       : Array
  }
})
