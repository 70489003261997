import m from 'mithril'
import math from './helper/math'

export default resource

function schemaDefault(value) {
  if (value === null)
    return null
  else if (typeof value === 'function')
    return value()
  else if (typeof value.type === 'function')
    return value.type()

  return value
}

function getDefaults(schema, pre = {}) {
  return Object.keys(schema).reduce((result, key) => {
    result[key] = pre[key] || schemaDefault(schema[key])
    return result
  }, {})
}

function resource(options) {
  let inited      = false
  const cache       = Object.create(null)
  const collection  = m.prop([])
  const request     = options.request || m.request
  const root        = options.root || ''
  const keyName     = options.keyName || '_id'
  const schema      = options.schema || []
  const putChanges  = options.onlyPutChanges || true
  const autosave    = options.hasOwnProperty('autosave') ? options.autosave : true
  const populate    = options.populate || null
  const select      = options.select || null

  function create(data) {
    return Model(null, getDefaults(schema, data))
  }

  function fetch() {
    request({
      method: 'GET',
      url: root + options.path,
      background: true
    }).then(merge)
  }

  function merge(coll) {
    collection(coll)
    collection().forEach(item => {
      if (cache[item[keyName]])
        cache[item[keyName]].merge(item)
    })
    m.redraw()
  }

  function get(id, data) {
    if (id) {
      const initialData = data || getDefaults(schema, collection().filter(item => item[keyName] === id)[0])

      return Model(id, initialData)
    }

    if (!inited) {
      inited = true
      fetch()
    }

    return collection
  }

  function getThen(id) {
    return new Promise((resolve, reject) => {
      request({
        method: 'GET',
        url: root + options.path + '/' + id,
        background: true
      }).then(res => resolve(res))
    })
  }

  function push(id, data) {
    return new Promise((resolve, reject) => {
      if (!id)
        reject()

      return request({
        method: 'PUT',
        url: root + options.path + '/' + id + '?populate',
        background: true,
        data: data
      }).then((res) => {
        resolve(res)
      }, () => {
        reject()
      })
    })
  }

  function list(data, server) {
    if (data.conditions)
      data.conditions = JSON.stringify(data.conditions)

    const result = request({
      method: 'GET',
      url: (server ? 'https://' + server + '/' : '') + root + options.path,
      background: true,
      initialValue: [],
      data: data
    })

    return result.then(results => {
      const A = [[data.select.split(' ')]]

      Object.keys(results).forEach((val, i) => {
        const arrayPos = i + 1

        A[arrayPos] = [new Array(A[0][0].length)]

        Object.keys(results[val]).forEach((res, pos) => {
          A[0][0].forEach((header, location) => {
            if (header.indexOf('.') > 0)
              return A[arrayPos][0][location] = math.deepValue(results[val], header)

            if (header === 'country') {
              if (res === 'location' && results[val][res] && results[val][res].country)
                A[arrayPos][0][location] = results[val][res].country.name
            }

            if (header === 'Multicast' && results[val][res].applications) {
              const ms = results[val][res].applications.find(a => a.application.name.indexOf('Multicast') > -1)

              return A[arrayPos][0][location] = ms ? ms.application.name.replace('Multicast', '') : ''
            }

            if (header === 'LSApps' && res === 'lifeSupport')
              return A[arrayPos][0][location] = results[val][res].applications.map(a => a.application.name)

            if (header === res) {
              if (res === 'windowsUpdates') {
                const updates = []

                results[val][res].forEach(val => {
                  updates.push(val.update)
                })

                A[arrayPos][0][location] = updates.join('; ')
              } else if (res === 'location') {
                if (results[val][res])
                  A[arrayPos][0][location] = results[val][res].reference
              } else if (res === 'volumes') {
                if (results[val][res]) {
                  results[val][res].forEach(info => {
                    if ((!info.free || info.space) || info.description === 'Network Connection')
                      return

                    [info.free, info.size].forEach((space, i) => {
                      const path = (i === 0 ? 'FreeSpace ' : 'TotalSpace ') + info.path

                      if (A[0][0].indexOf(path) === -1)
                        A[0][0].push(path)

                      A[arrayPos][0][A[0][0].indexOf(path)] = (space / 1024 / 1024 / 1024).toFixed(2).replace('.', ',')
                    })
                  })
                }
              } else if (res === 'country') {
                A[arrayPos][0][location] = results[val][res].name
              } else if (res === 'notesText') {
                A[arrayPos][0][location] = JSON.stringify(results[val][res])
              } else if (res === 'lastConnected') {
                A[arrayPos][0][location] = results[val][res] ? results[val][res].replace('T', ' ').split('.')[0] : ''
              } else {
                A[arrayPos][0][location] = results[val][res]
              }
            }
          })
        })
      })

      return A
    })
  }

  function getList(data, server) {
    return list(data, server)
  }

  function download(data, server) {
    list(data, server)
      .then(A => {
        const csvRows = []

        A.forEach(row => {
          let rowValues

          row.forEach(value => {
            rowValues = value.join('\t')
          })

          csvRows.push(rowValues)
        })

        const text = new TextEncoder('windows-1252', { NONSTANDARD_allowLegacyEncoding: true }).encode(csvRows.join('\n'))

        const link = document.createElement('a')
          , blob = new Blob([text], { type: 'attachment/xls' })

          , URL = window.URL || window.webkitURL
          , downloadUrl = URL.createObjectURL(blob)

        link.setAttribute('href', downloadUrl)
        document.body.append(link)

        let name = options.path

        if (data.conditions) {
          Object.keys(JSON.parse(data.conditions)).forEach(val => {
            const condition = JSON.parse(data.conditions)[val].$regex || JSON.parse(data.conditions)[val]

            name = name + ' ' + val + '_' + condition
          })
        }

        link.download = name + '.xls'
        link.click()
        document.body.removeChild(link)
      })
  }

  function remove(id) {
    const index = findIndexById(id)

    if (index > -1)
      collection().splice(index, 1)

    return request({
      method: 'DELETE',
      url: root + options.path + '/' + id,
      background: true
    }).then(data => {
      delete cache[id]
      m.redraw()
      return data
    })
  }

  function query(data, xhr) {
    if (data.conditions)
      data.conditions = JSON.stringify(data.conditions)

    const result = request({
      method: 'GET',
      url: root + options.path,
      background: true,
      initialValue: [],
      config: xhr,
      data: data
    })

    result.then(m.redraw)
    return result
  }

  function findIndexById(id) {
    return collection().map(item => item[keyName]).indexOf(id)
  }

  function buildData() {
    const reqData = { }

    if (populate)
      reqData.populate = populate

    if (select)
      reqData.select = select

    return reqData
  }

  function Model(id, data) {
    let model = id && cache[id]

    if (model)
      return model

    let store = Object.create(null)
    let isNew = !id
    const raw = Object.create(null)
    const events = {
      created: [],
      deleted: [],
      changed: []
    }

    model = Object.create({
      isNew: function() {
        return isNew
      },
      key: id,
      on: function(eventName, fn) {
        events[eventName].push(fn)
      },
      once: function(eventName, fn) {
        events[eventName].push(function once() {
          events.splice(events[eventName].indexOf(once), 1)
          fn.apply(null, arguments)
        })
      },
      off: function(eventName, fn) {
        events.splice(events[eventName].indexOf(fn), 1)
      },
      fetch: function() {
        request({
          method: 'GET',
          url: root + options.path + '/' + model.key,
          background: true,
          data: buildData()
        }).then(model.merge).then(m.redraw)
      },
      changes: function() {
        return Object.keys(store).reduce((result, key) => {
          if (raw[key] !== store[key])
            result[key] = store[key]
          return result
        }, {})
      },
      save: function() {
        if (isNew) {
          return request({
            method: 'POST',
            url: root + options.path,
            background: true,
            data: store
          }).then(result => {
            isNew = false
            model.merge(result)
            id = result[keyName] || id
            cache[id] = model
            collection().push(result)
            events.created.forEach(fn => fn(model))
            m.redraw()
            return result
          })
        }

        return request({
          method: 'PUT',
          url:  root + options.path + '/' + model.key + '?' + m.route.buildQueryString({ populate: options.populate }), // hack in hard
          background: true,
          data: putChanges ? model.changes() : store
        }).then(result => {
          const index = findIndexById(id)

          if (index > -1)
            collection()[index] = result

          events.changed.forEach(fn => fn(model))
          return result
        }).then(model.merge, model.revert)
          .then(object => {
            m.redraw()
            return object
          })
      },
      revert: function() {
        if (model.key)
          store = raw
      },
      remove: function() {
        remove(model.key).then(() => {
          events.deleted.forEach(fn => fn(model))
        })
      },
      merge: function(object) {
        Object.keys(object).forEach(key => {
          if (!model[key])
            model[key] = prop(key)

          store[key] = typeof object[key] === 'undefined' ? store[key] : object[key]

          if (schema[key] === Array && !store[key])
            store[key] = []

          if (schema[key] === Object && !store[key])
            store[key] = {}

          raw[key] = JSON.parse(JSON.stringify(store[key]))
        })

        return object
      },
      raw: function() {
        return store
      }
    })

    function prop(key) {
      return function(value) {
        if (arguments.length) {
          if (options.beforeSave)
            value = options.beforeSave(key, value)
          store[key] = value
          if (autosave && !isNew)
            model.save()
          if (options.afterSave)
            options.afterSave(key, value)
        }

        return store[key]
      }
    }

    if (data)
      model.merge(data)

    if (id) {
      model.fetch()
      cache[id] = model
    }

    return model
  }

  return {
    urlRoot   : root + options.path + '/',
    request   : request,
    schema    : schema,
    create    : create,
    get       : get,
    getThen   : getThen,
    push      : push,
    getList   : getList,
    download  : download,
    remove    : remove,
    query     : query
  }
}

resource.defaults = function(defaultOptions) {
  return {
    resource: function(options) {
      Object.keys(defaultOptions).forEach(key => {
        if (!options[key])
          options[key] = defaultOptions[key]
      })
      return resource(options)
    }
  }
}
