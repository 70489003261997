import m from 'mithril'

function findRoute(obj) {
  if (Array.isArray(obj))
    return typeof obj[0] === 'object' ? findRoute(obj[0]) : obj[0]

  if (typeof obj === 'object')
    return findRoute(obj.children)

  return obj
}

function buildTree(obj) {
  const root = m('ul.jsonTree')

  buildChildren(obj, root)
  return root

  function buildChildren(obj, root) {
    if (!obj)
      return

    Object.keys(obj).forEach((key, i) => {
      if (!root)
        return

      if (Array.isArray(obj[key])) {
        return root.children.push(m('ul', [
          m('li.key', key),
          m('ul', obj[key].map(v => {
            return m('li', v)
          }))
        ]))
      }

      root.children.push(m('ul', [
        m('li.key', key),
        typeof obj[key] === 'object' ? '' :
          m('ul', m('li', obj[key]))
      ]))

      if (typeof obj[key] === 'object') {
        if (typeof root.children[i] !== 'object')
          return buildChildren(obj[key], root.children.find(i => findRoute(i) === key))

        buildChildren(obj[key], root.children.find(i => findRoute(i) === key))
      }
    })
  }
}

export default buildTree
