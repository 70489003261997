module.exports = function(title) {
  switch (title.match(/[^.]([^.]*)$/i)[0].toLowerCase()) {
  case 'jpg':
  case 'jpeg':
  case 'png':
  case 'gif':
  case 'tif':
    return 'image'

  case 'xlsx':
  case 'xls':
  case 'xltx':
    return 'excel'

  case 'csp':
  case 'seq':
  case 'doc':
  case 'docx':
  case 'pdf':
  case 'rtf':
  case 'htm':
  case 'pptx':
  case 'vcf':
  case 'vcd':
  case 'vsd':
  case 'xps':
  case 'mov':
  case 'mp3':
    return 'attachment'

  case 'eml':
    return 'eml'

  default:
    return 'attachment'
  }
}
