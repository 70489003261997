import m from 'mithril'

export default {
  view(ctrl, args) {
    return m('aside', [
      m('h1', [
        m('span', args.title),
        args.createUrl ? m('a.button.round.raised', {
          href: args.route + args.createUrl, config : m.route
        }, '+') : ''
      ]),
      m('ul', [
        (args.items || []).map(item => {
          return m('li', { key: args.itemId(item), class: item._id === m.route.param('id') ? 'selected' : '' }, [
            m('a', { href: args.route + args.itemId(item), config : m.route }, [
              m('h3', args.itemTitle(item)),
              args.itemSubtitle ? m('span', args.itemSubtitle(item)) : ''
            ])
          ])
        })
      ])
    ])
  }
}
