import m from 'mithril'

export default {

  controller(args) {
    const id = m.route.param('id') || ''

    const ctrl = {
      list : args.Model.get()
    }

    if (id === 'new') {
      ctrl.item = args.Model.create()
      ctrl.item.on('created', u => m.route('/' + args.route + '/' + u.key))
    } else if (id) {
      ctrl.item = args.Model.get(id)
      if (ctrl.item)
        ctrl.item.on('deleted', () => m.route('/' + args.route))
    }

    return ctrl
  },

  view(ctrl, args) {
    return m('#' + args.route + '.columns', { class: ctrl.item ? 'three' : '' }, [
      m('', [
        m('h1', [
          m('span', args.title),
          m('a.round.button.raisedLight', {
            href: '/' + args.route + '/new', config : m.route
          }, '+')
        ]),
        m('.list', {
          class: 'cards'
        }, ctrl.list().map(item => {
          return m('a', {
            key: item[args.keyNme],
            class: item[args.keyName] === m.route.param('id') ? 'selected' : '',
            href: '/' + args.route + '/' + item[args.keyName],
            config: m.route
          }, m('div', [
            m('h3', item[args.heading]),
            m('p', item[args.description] ? 'online' : 'offline')
          ]))
        }))
      ])
    ])
  }

}
