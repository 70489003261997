import api from '../api'

export default window.test = api.resource({
  path    : 'locations/reminders',
  schema: {
    reference      : String,
    accountingId   : String,
    organizations  : Array,
    name           : String,
    street         : String,
    zip            : String,
    city           : String,
    county         : String,
    country        : Object,
    email          : String,
    phone          : String,
    tags           : Array,
    geo            : {
      type: 'Point',
      coordinates: [0, 0]
    }
  }
})
