import m from 'mithril'
import layout from '../layout'

import login from '../login'
import home from './home'
import devices from './devices'
import device from './device'
import locations from './locations'
import users from './users'
import media from './media'
import apps from './apps'
import schedules from './schedules'
import organizations from './organizations'
import info from './info'
import shippings from './shippings'
import missingaccounts from './missingaccounts'
import ghostdevices from './ghostdevices'
import restore from './restore'
import prepare from './prepare'
import updater from './updater'
import query from './query'
import partners from './partners'
import fakta from './fakta'

m.route.mode = 'pathname'

// layout wraps pages in the page layout for routing
const routes = layout({
  '/'                     : home,
  '/devices'              : devices,
  '/devices/:id'          : device,
  '/devices/:id/:section' : device,
  '/ghostdevices'         : ghostdevices,
  '/locations'            : locations,
  '/locations/:id'        : locations,
  '/users'                : users,
  '/users/:id'            : users,
  '/media'                : media,
  '/media/:id'            : media,
  '/media/:id/:section'   : media,
  '/apps'                 : apps,
  '/apps/:id'             : apps,
  '/schedules'            : schedules,
  '/schedules/:id'        : schedules,
  '/organizations'        : organizations,
  '/organizations/:id'    : organizations,
  '/info'                 : info,
  '/prepare'              : prepare,
  '/fakta'                : fakta,
  '/restore'              : restore,
  '/missingaccounts'      : missingaccounts,
  '/shippings'            : shippings,
  '/updater'              : updater,
  '/query'                : query,
  '/partners'             : partners,
  '/partners/:id'         : partners
})

// The login page should not be wrapped in the page layout
routes['/login'] = login
routes['/fakta-b6cD4bmkZz2'] = fakta

export default routes
