import m from 'mithril'

export default {

  controller() {
    return
  },

  view(ctrl) {
    return m('#dashboard.content', [
      m('h1', 'Home')
    ])
  }

}
