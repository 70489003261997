import m from 'mithril'
import { request as api } from '../api'
import s from 'sin/browser/sin.umd.js'

const app = s(({ life }) => {
  const query = 'MIB' + (new URLSearchParams(location.search).get('mib') || '151911')
  let stores = {}
  let show
  let fetchTimer = setInterval(fetch, 5 * 1000)

  window.addEventListener('blur', blur)
  window.addEventListener('focus', focus)

  life(() => () => {
    clearInterval(fetchTimer)
    window.removeEventListener('blur', blur)
    window.removeEventListener('focus', focus)
  })

  function blur() {
    clearInterval(fetchTimer)
  }

  function focus() {
    clearInterval(fetchTimer)
    fetchTimer = setInterval(fetch, 5 * 1000)
  }

  function fetch() {
    api({
      background: true,
      method: 'POST',
      url: 'query/device',
      data: {
        type: 'aggregate',
        command: JSON.stringify([{
          $match: {
            prepare: { $exists: true },
            hostname: { $regex: query },
            $or: [
              { prepareDone: { $exists: false } },
              { prepareDone: { $gt: 'ISODate(' + new Date(Date.now() - 1000 * 60 * 2) + ')' } }
            ]
          }
        }, {
          $sort: { 'prepare.store': 1 }
        }, {
          $project: {
            _id: 1,
            hostname: 1,
            store: '$prepare.store',
            error: '$prepare.error',
            prepareDate: '$prepareDate',
            progress: '$prepare.progress',
            prepareDone: '$prepareDone',
            output: '$prepareOutput'
          }
        }])
      }
    }).then(xs =>
      api({
        background: true,
        url: 'devices',
        data: {
          select: 'connected lastConnected',
          limit: 500,
          where: JSON.stringify({ _id: { $in: xs.map(d => d._id) } })
        }
      }).then(online => {
        xs.forEach(x => {
          Object.assign(x, online.find(o => o._id === x._id) || {})
        })
        return xs
      })
    ).then(xs => {
      stores = xs.reduce((acc, x) => {
        acc[x._id] = x
        x.output.reverse()
        return acc
      }, {})
      s.redraw()
    })
  }

  function done(x) {
    api({
      url: 'devices/' + x._id,
      method: 'PUT',
      data: {
        prepareDone: new Date()
      }
    })
    api({
      background: true,
      method: 'post',
      url: 'devices/' + x._id + '/execute',
      data: {
        path: 'https://dist.beat.dk/utilities/run.exe',
        arguments: 'shutdown /f /s /t 0'
      }
    })
  }

  fetch()

  return () => s`div
    p 40
  `(
    s`h1`(
      'Prepare ' + query,
      Object.keys(stores).length > 0
      && Object.values(stores).every(x => x.connected
        && x.output.find(x => x.includes('Great success'))
        && new Date(x.prepareDate) < new Date(Date.now() - 1000 * 60 * 2)
      )
      && s`button`({
        onclick: () => Object.values(stores).forEach(done)
      }, 'SLUK ALLE')
    ),
    s`ul`(
      Object.entries(stores).map(([key, x], i) => [
        s`li
          d flex
          fd column
          m 5
        `({
          key
        },
          s`div
            d flex
            ai center
            br 5
            height 28px
          `(
            s`
              pr 16
              min-width 50
              ta right
            `(i + 1),
            s`
              fw bold
              min-width 80
            `(x.store),
            s`a
              d flex
              jc center
              min-width 100
              text-decoration underline
            `({
              href: 'https://internalapi.beat.dk/hostname/' + x.hostname,
              target: '_BLANK'
            },
              s`
                w 10
                h 10
                br 5
                m 2 5
                bc ${ x.connected ? '#0077ff' : 'tomato' }
              `,
              x.hostname
            ),
            s`
              min-width 80
              p 0 8
            `(x.progress ? '⬇️ ' + x.progress : ''),
            s`
            `({ onclick: x => show = show === key ? null : key }, x.output[0]),
            false
              && x.connected
              && x.output.find(x => x.includes('Great success'))
              && new Date(x.prepareDate) < new Date(Date.now() - 1000 * 60 * 2)
              && s`button
              m 0 12
              height 28px
              line-height 27px
              p 0 4
            `({
                onclick: () => done(x)
              }, 'Sluk')
          ),
          show === key && s`pre
            p 0 60
            fs 12
          `(
            x.output.join('\n')
          )
        )
      ])
    )
  )
})

export default {
  controller: () => {
    return {
      onunload: (ctrl) => s.mount(ctrl.dom, null)
    }
  },
  view: (ctrl) => m('div', {
    config: (dom, inited) => {
      ctrl.dom = dom
      !inited && s.mount(dom, app)
    }
  })
}
